import {_urlRoles, request} from "../api";
import {ROLES_CONSTS} from "../constants";

export const GetRoles = () => {
    let requestData = {
        url: `${_urlRoles}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: ROLES_CONSTS.GET_ROLES,
                    payload: data
                })
            })
    };
};
export const GetReisRoles = () => {
    let requestData = {
        url: `${_urlRoles}/reis_roles`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: ROLES_CONSTS.GET_ROLES_REIS,
                    payload: data
                })
            })
    };
};

export const CreateRole = (data) => {
    let requestData = {
        url: `${_urlRoles}`,
        method: "POST",
        data: JSON.stringify(data),
    };
    // console.log('data', data);
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: ROLES_CONSTS.CREATE_ROLE,
                    payload: res?.data
                })
            })
    };
};

export const DeleteRole = data => {
    const requestData = {
        url: `${_urlRoles}/${data?.id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ROLES_CONSTS.DELETE_ROLES,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const GetRoleById = (id) => {
    let requestData = {
        url: `${_urlRoles}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: ROLES_CONSTS.GET_ROLE_BY_ID,
                    payload: data
                })
                return data
            })
    };
};

export const UpdateRole = (id, data) => {
    let requestData = {
        url: `${_urlRoles}/${id}`,
        method: "PATCH",
        // customHeaders: {
        //     'Content-Type': 'multipart/form-data'
        // },
        data
    };
    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: ROLES_CONSTS.UPDATE_ROLES,
                    payload: {id, data}
                })
            })
    };
};

export const ClearRoles = () => {
    return async dispatch => {
        await dispatch({
            type: ROLES_CONSTS.CLEAR_ROLES
        })
    };
};
export const ClearRoleById = () => {
    return async dispatch => {
        await dispatch({
            type: ROLES_CONSTS.CLEAR_ROLES_BY_ID
        })
    };
};




