import React, {useEffect, useState} from 'react';
import './FilterChannels.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {ArrowLeftIcon, ChainIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {CheckBox} from "../../../../modules/CheckBox";
import {Button} from "../../../../modules/Button";
import {Text} from "../../../../modules";
import {
	GetNotesService,
	updateApiStatusNotesListService,
	updateFiltersNotesListService
} from "../../../../redux/actions/notesActions";
import {ApiStatus} from "../../../../utils/constants";


const FilterChannels = () => {
	const channels = useSelector(state => state.channels?.channels || []);
	const filters = useSelector(state => state.notes.notesList.filters);
	const selectedChannelsState = useSelector(state => state.notes.notesList.filters?.channels_ids || []);
	const selectedChainsState = useSelector(state => state.notes.notesList.filters?.chains_ids || []);
	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const {t, i18n} = useTranslation();
	const [selectedIdsChannel, setSelectedIdsChannel] = useState([...selectedChannelsState]);
	const [selectedIdsChains, setSelectedIdsChains] = useState([...selectedChainsState]);
	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const dispatch = useDispatch();
	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']

	useEffect(() => {
			setSelectedIdsChannel(filters?.channels_ids?.length ? filters.channels_ids : []);
		setSelectedIdsChains(filters?.chains_ids?.length ? filters.chains_ids : []);

	}, [filters]);

	const getChainsToChannel = (channelId) => {
		return chainsAll.filter((chain) => chain.channel?.id === channelId)
	}

	const getLabel = () => {
		let text = '';
		if(selectedChannelsState.length === 1) {
			const findChannel = channels.find((channel) => channel.id === selectedChannelsState[0]);
			text = findChannel.name
		} else if(selectedChannelsState.length > 1) {
			text = `+${selectedChannelsState.length}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = (data) => {
		dispatch(updateFiltersNotesListService({
			...filters,
			...data,
		}))
	}

	const onSelectedChannel = (value) => {
		let arr = [...selectedIdsChannel];
		if(selectedIdsChannel.includes(value)) {
			arr = arr.filter((item) => item !== value);
			const chainsIds = getChainsToChannel(value).map((chain) => chain.id);
			const chains = selectedIdsChains.filter((item) => !chainsIds.includes(item));
			setSelectedIdsChains(chains);
		} else {
			arr.push(value)
		}
		setSelectedIdsChannel(arr);
	}

	const onSelectedChain = (value) => {
		let arr = [...selectedIdsChains];
		if(selectedIdsChains.includes(value)) {
			arr = arr.filter((item) => item !== value);
		} else {
			arr.push(value)
		}
		setSelectedIdsChains(arr);
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		setValueFilter({
			channels_ids: selectedChannelsState,
			chains_ids: selectedChainsState,
		});
	}

	const getNotes = async (data) => {
		try {
			await dispatch(updateApiStatusNotesListService(ApiStatus.LOADING));
			await dispatch(GetNotesService({
				limit: 20,
				offset: 0,
				...filters,
				...data

			}));
			await dispatch(updateApiStatusNotesListService(ApiStatus.SUCCESS));
		} catch (e) {
			await dispatch(updateApiStatusNotesListService(ApiStatus.ERROR));
		}
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			setValueFilter({
				channels_ids: selectedIdsChannel,
				chains_ids: selectedIdsChains,
			});
			await getNotes({
				channels_ids: selectedIdsChannel,
				chains_ids: selectedIdsChains,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const RenderTooltip = (props) => {
		if(selectedChannelsState.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={<RenderTooltipText/>}
				>
					<span className="filter-note-channels__label--text">{`${t('channels')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return (<span className="filter-note-channels__label--text">{`${t('channels')}: ${getLabel()}`}</span>)
	}

	const RenderTooltipText = () => {
		const channelsFiltered = channels.filter((channel) => selectedChannelsState.includes(channel.id));
		return (
			<div className="filter-note-channels__tooltip">
				{channelsFiltered.map((channel) => (
					<div className="filter-note-channels__tooltip--text">{channel?.name}</div>
				))}
			</div>
		)
	}

	const deleteFilter = async () => {
		setValueFilter({
			channels_ids: [],
			chains_ids: [],
		})
		await getNotes({
			channels_ids: [],
			chains_ids: [],
		})
	}

	const handleKeyDown = (event) => {
		if(event.key === "Enter" && selectedIdsChannel.length) {
			handleApplyClick()
		}
	};

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-note-channels'
			onOpenChange={handleOpenChange}
			destroyTooltipOnHide={true}
			open={open}
			content={
				<div className="filter-note-channels__container" onKeyDown={handleKeyDown}>
					<div style={{paddingRight: 16, paddingBottom: 4}}>
						<div className="filter-note-channels__list custom-scroll">
							{channels.map((channel, index) => (
								<div
									key={channel.id}
									className="filter-note-channels__list--item"
									style={{
										'--chain_bg': channel?.is_private ? 'rgba(26, 187, 120, 0.10)'
											: tagColors[index >= 5 ? index % 5 : index] + '14',
										'--chain_color': channel?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
										paddingBottom: channel?.is_private ? '8px' : '0',
										borderBottom: channel?.is_private ? '0.7px solid #E6E6E6' : '0',
									}}
								>
									<CheckBox
										checked={selectedIdsChannel.includes(channel.id)}
										onChange={() => {
											onSelectedChannel(channel.id)
										}}
										label={channel.name}
									/>
									{selectedIdsChannel.includes(channel.id) && getChainsToChannel(channel.id).length ?
										<div className="filter-note-channels__chains">
											{getChainsToChannel(channel.id).map((chain) => (
												<CheckBox
													checked={selectedIdsChains.includes(chain.id)}
													onChange={() => {
														onSelectedChain(chain.id)
													}}
													label={
														<div className="filter-note-channels__chains--item">
															<ChainIcon/>
															<Text size="f12">{chain.name}</Text>
														</div>
													}
												/>
											))}
										</div>

										: null}
								</div>
							))}
						</div>
					</div>
					<div className="filter-note-channels__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-note-channels__label ${selectedChannelsState.length ? 'selected' : ''}`}>
				<div className={`filter-participant__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedChannelsState.length ?
						<ArrowLeftIcon className={`filter-note-channels__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedChannelsState.length ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-note-channels__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterChannels;