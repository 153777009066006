import React, {useEffect, useState} from 'react';
import './ChannelInfoDropDown.scss';
import UserInfoItem from "../../../shared/features/userInfoItem";
import {useDispatch} from "react-redux";
import {GetChannelById} from "../../../redux/actions";
import {useTranslation} from "react-i18next";

const ChannelInfoDropDown = (props) => {
    const {channelId} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [channelInfo, setChannelInfo] = useState();
    const managerIds = (channelInfo?.managers || []).map((item) => item?.user_id)
    const participants = (channelInfo?.participants || []).filter((item) => !managerIds.includes(item.user_id));
    const getChannelInfo = async () => {
        try {
            const response = await dispatch(GetChannelById(channelId));
            setChannelInfo(response)

        }catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getChannelInfo()
    }, []);

    return (
        <div className="channel-info custom-scroll">
            {!!channelInfo?.managers?.length &&
              <div className="channel-info__item">
                <div className="channel-info__item--title">{t('channel_managers')}</div>
                <div className={`channel-info__item_list ${participants?.length < 4 ? '' : 'large'}`}>
                    {
                        channelInfo?.managers?.map(item => {
                            return <UserInfoItem data={item}/>
                        })
                    }
                </div>
            </div>}
            {!!participants?.length &&
              <div className="channel-info__item">
                <div className="channel-info__item--title">{t('channel_members')}</div>
                {
                    !channelInfo?.all_users_are_participants ?
                      <div className={`channel-info__item_list ${participants?.length < 4 ? '' : 'large'}`}>
                          {
                              participants?.map(item => {
                                  return <UserInfoItem data={item}/>
                              })
                          }
                      </div>
                      : <div className="channel-info__item--text">{t('all_users_system')}</div>
                }
            </div>}
        </div>
    );
};

export default ChannelInfoDropDown;