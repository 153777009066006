import {UTIL_CONSTS} from "../constants/utilConsts";

export const initialState = {
    isMobile: false,
    isFullWidth: false,
    headerTitle: '',
    eventsColor: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UTIL_CONSTS.SET_MOBILE:
            return {
                ...state,
                isMobile: action.payload,
            };
        case UTIL_CONSTS.SET_FULL_WIDTH:
            return {
                ...state,
                isFullWidth: action.payload,
            };
        case UTIL_CONSTS.CHANGE_HEADER_TITLE:
            return {
                ...state,
                headerTitle: action.payload,
            };
        case UTIL_CONSTS.EVENT_COLORS:
            return {
                ...state,
                eventsColor: {
                    id: action.payload.id,
                    colors: action.payload.data,
                },
            };

        default:
            return state;
    }
}
