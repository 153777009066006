import React, {useCallback, useContext, useState} from 'react';
import './ChainBlock.scss';
import {hasPermission} from "../../../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../../../utils/constants";
import {Button} from "../../../../../Button";
import {ChainIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusNewIcon, StarsIcon} from "../../../../../../image";
import {AutoCainsSettingsContext, getChainsFromChannel} from "../../helpers";
import {CheckBox} from "../../../../../CheckBox";
import {Popover, Tooltip} from "antd";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useEditChain from "../../useEditChain";
import EditChainInput from "../EditChainInput";

const ChainBlock = (props) => {
	const {channel} = props;
	const {t} = useTranslation();

	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const [isVisibleAddForm, setIsVisibleAddForm] = useState(false);
	const toggleIsVisibleAddForm = useCallback(() => setIsVisibleAddForm(prevState => !prevState), []);
	const [editChainInfo, setEditChainInfo] = useState('');
	const [chainName, setChainName] = useState('');
	const [openChains, setOpenChains] = useState({});
	const {settings, setSettings} = useContext(AutoCainsSettingsContext);
	const [isVisibleEditAiChainForm, setIsVisibleEditAiChainForm] = useState(false);
	const toggleVisibleEditAiChainForm = useCallback(() => setIsVisibleEditAiChainForm(prevState => !prevState), []);
	const [chainAiName, setChainAiName] = useState('');

	const closeAndClearInput = () => {
		toggleIsVisibleAddForm();
		setChainName('');
		setEditChainInfo({});
	}

	const closeAndClearAiInput = () => {
		toggleVisibleEditAiChainForm();
		setChainAiName('');
	}

	const {AddChain, DeleteChain, UpdateChain} = useEditChain({closeAndClearInput, chainName, channel, editChainInfo});

	function handleOpenChange(newOpen, id) {
		setOpenChains({
			...openChains,
			[id]: newOpen
		});
	}

	const onHandleEditChangeChain = (id) => {
		const findChain = chainsAll.find((item) => item?.id === id);
		setEditChainInfo(findChain || {})
		toggleIsVisibleAddForm();
		setChainName(findChain.name);
	}
	const onHandleClickCheckBoxChain = (e, chain) => {
		const isCheckChannel = settings?.channels_ids.includes(channel?.id);
		if(e.target.checked) {
			setSettings({
				...settings,
				chains_ids: [...settings?.chains_ids, chain?.id],
				channels_ids: isCheckChannel ? settings?.channels_ids : [...settings?.channels_ids, channel?.id]
			})
		}else{
			setSettings({
				...settings,
				chains_ids: settings?.chains_ids.filter(item => item !== chain?.id),
				channels_ids: isCheckChannel ? settings?.channels_ids.filter(item => item !== channel?.id) : settings?.channels_ids
			})
		}
	}
	const onHandleClickAiChain = (e, chain) => {
			setSettings({
				...settings,
				need_auto_chain : e.target.checked,
			})
	}

	return (
		<div className="chain-block">
			<div className="chain-block--title">
				{t('chains')}
			</div>
			{(!isVisibleAddForm && (hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || channel?.is_private)) ?
				<Button
					text={t('add_chain')}
					variant="ghost"
					onClick={toggleIsVisibleAddForm}
					leftIcon={<PlusNewIcon/>}
					className="chain-block--add-btn"
				/>
				: null}
			{isVisibleAddForm && !editChainInfo?.id ?
				<EditChainInput
					onSave={() => AddChain(channel)}
					setChainName={setChainName}
					chainName={chainName}
					closeAndClearInput={closeAndClearInput}
				/>
				: null}
			<div className="chain-block__list">
				{settings?.auto_chain_name && channel?.id === settings?.auto_chain_channel_id  ?
					<>
						{isVisibleEditAiChainForm ?
							<EditChainInput
								onSave={()=>{
									setSettings({
										...settings,
										auto_chain_name: chainAiName
									})
									toggleVisibleEditAiChainForm();
								}}
								setChainName={setChainAiName}
								chainName={chainAiName}
								closeAndClearInput={closeAndClearAiInput}
							/>
							:
							<div className="chain-block__item chain-block__item--ai">
								<div className="chain-block__item--ai--wrap">
									<CheckBox
										type={'checkbox'}
										onChange={onHandleClickAiChain}
										checked={settings.need_auto_chain}
									/>
									{(settings?.auto_chain_name || '').length > 10 ?
									<Tooltip title={settings?.auto_chain_name} placement="top">
										<div className="chain-block__item--badge">
											<ChainIcon/>
											<span>{settings?.auto_chain_name}</span>
										</div>
									</Tooltip>
										:
										<div className="chain-block__item--badge">
											<ChainIcon/>
											<span>{settings?.auto_chain_name}</span>
										</div>
									}
									<div className="chain-block__item--ai--text">
										<StarsIcon/>
										<span>{t('generate_ai_chain_text')}</span>
									</div>
								</div>
								<Popover
									arrow={false}
									trigger='click'
									placement="bottomLeft"
									overlayClassName='popover_edit-menu'

									content={
										<>
											<div
												className={'popover_edit-menu--item'}
												onClick={() => {
													setChainAiName(settings?.auto_chain_name);
													toggleVisibleEditAiChainForm()
												}}
											>
												<EditIcon className={''}/> {t('button_edit')}
											</div>
											<div
												className={'popover_edit-menu--item delete'}
												onClick={() => {
													setSettings({
														...settings,
														auto_chain_channel_id: undefined,
														need_auto_chain: false,
														auto_chain_name: undefined

													})
												}}
											>
												<DeletingIcon className={''}/> {t('button_delete')}
											</div>
										</>
									}
								>
									<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
										<EllipsisIcon/>
									</div>
								</Popover>
							</div>
						}
					</>
					: null}
				{getChainsFromChannel(chainsAll, channel)
					.filter(chain => chain?.name !== settings?.auto_chain_name)
					.map((chain, index) => (
					<>
						{isVisibleAddForm && editChainInfo?.id === chain?.id ?
							<EditChainInput
								onSave={() => UpdateChain(channel)}
								setChainName={setChainName}
								chainName={chainName}
								closeAndClearInput={closeAndClearInput}
							/>
							:
							<div className="chain-block__item" key={index}>
								<CheckBox
									type={'checkbox'}
									checked={settings.chains_ids.includes(chain?.id)}
									onChange={(e) => onHandleClickCheckBoxChain(e, chain)}
								/>
								{(chain?.name || '').length > 25 ?
									<Tooltip title={chain?.name} placement="top">
										<div className="chain-block__item--badge">
											<ChainIcon/>
											<span>{chain?.name}</span>
									</div>
									</Tooltip>
									:
									<div className="chain-block__item--badge">
										<ChainIcon/>
										<span>{chain?.name}</span>
									</div>
								}

								<Popover
									arrow={false}
									onOpenChange={(e)=>handleOpenChange(e, chain?.id)}
									open={openChains?.[chain?.id]}
									trigger='click'
									placement="bottomLeft"
									overlayClassName='popover_edit-menu'
									content={
										<>
											<div
												className={'popover_edit-menu--item'}
												onClick={(e) => {
													setOpenChains({
														...openChains,
														[chain?.id]: false
													});
													onHandleEditChangeChain(chain?.id)
												}}
											>
												<EditIcon className={''}/> {t('button_edit')}
											</div>
											<div
												className={'popover_edit-menu--item delete'}
												onClick={(e) => {
													setOpenChains({
														...openChains,
														[chain?.id]: false
													});
													DeleteChain(chain?.id)
												}}
											>
												<DeletingIcon className={''}/> {t('button_delete')}
											</div>
										</>
									}
								>
									<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
										<EllipsisIcon/>
									</div>
								</Popover>
							</div>
						}
					</>
				))}
			</div>
		</div>
	);
};

export default ChainBlock;