import React, {useEffect, useRef, useState} from 'react';
import './FilterChains.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {CheckBox} from "../../../CheckBox";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {ALL_FILTERS, getValueFilters} from "../../helpers";
import {DeleteFilterAction, SetFilterAction} from "../../../../redux/actions";
import {useParams} from "react-router-dom";


const FilterChains = ({pageType, onGetVideos}) => {
	const params = useParams();
	const channelById = useSelector(state => state.channels?.channelById);
	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const chains = params?.channelId ?  (channelById?.chains || []) : chainsAll;
	const selectedChainsFilterState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.CHAINS] || []);
	const {t, i18n} = useTranslation();
	const [selectedIdsChain, setSelectedIdsChain] = useState([...selectedChainsFilterState]);
	const [open, setOpen] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [isAll, setIsAll] = useState(false);
	const [searchText, setSearchText] = useState('');
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters,pageType);
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [sortChains, setSortChains] = useState([]);

	const getLabel = () => {
		let text = '';

		if(selectedChainsFilterState.length === 1) {
			const findChain= chains.find((chain) => chain.id === selectedChainsFilterState[0]);
			text = findChain.name
		} else if(selectedChainsFilterState.length > 1) {
			text = `+${selectedChainsFilterState.length}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = (data) => {
		dispatch(SetFilterAction({
			pageType,
			filterType: ALL_FILTERS.CHAINS,
			value: data
		}))
	}

	const onSelectedFilter = (value) => {
		let arr = [...selectedIdsChain];
		if(selectedIdsChain.includes(value)) {
			arr = arr.filter((item) => item !== value);
		} else {
			arr.push(value)
		}
		setSelectedIdsChain(arr);
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		setValueFilter(selectedChainsFilterState);
	}

	const handleAllClick = (isCheck) => {
		if(isCheck) {
			setSelectedIdsChain(chains.map((item) => item.id))
		} else {
			setSelectedIdsChain([]);
		}
	}

	const getVideos = async (type) => {
		let params = {
			...allFiltersValue,
			chain_ids: selectedIdsChain
		};
		if(!selectedIdsChain?.length || type === 'delete') delete params?.chain_ids;
		await onGetVideos(params)
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			setValueFilter(selectedIdsChain);
			await getVideos()
		} catch (error) {
			console.log(error)
		}
	}

	const RenderTooltip = (props) => {
		if(selectedChainsFilterState.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={<RenderTooltipText/>}
				>
					<span className="filter-chains__label--text">{`${t('chains')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return (<span className="filter-chains__label--text">{`${t('chains')}: ${getLabel()}`}</span>)
	}

	const RenderTooltipText = () => {
		const chainsFiltered = chains.filter((chain) => selectedChainsFilterState.includes(chain.id));
		return (
			<div className="filter-chains__tooltip">
				{chainsFiltered.map((chain) => (
					<div className="filter-chains__tooltip--text">{chain?.name}</div>
				))}
			</div>
		)
	}

	const getFilteredChains = () => {
		if(searchText) {
			return sortChains.filter((chain) => (chain?.name || '').toLowerCase().includes(searchText.toLowerCase()))
		}
		return sortChains
	}

	const deleteFilter = async () => {
		dispatch(DeleteFilterAction({
			pageType,
			filterType: ALL_FILTERS.CHAINS
		}));
		await getVideos('delete')
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && selectedIdsChain.length) {
			handleApplyClick()
		}
	};

	useEffect(() => {
		setSelectedIdsChain(selectedChainsFilterState)
		setSearchText('');
		if (inputRef.current) {
			inputRef.current.focus();
		}

		setSortChains(sortChains.sort((a) => selectedIdsChain.includes(a.id) ? -1 : 1))
	}, [open]);

	useEffect(() => {
		setIsAll(selectedIdsChain.length === chains.length)
	}, [selectedIdsChain]);

	useEffect(() => {
		setSortChains(chains);
	}, [chains]);


	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-chains'
			onOpenChange={handleOpenChange}
			destroyTooltipOnHide={true}
			open={open}
			content={
				<div className="filter-chains__container" onKeyDown={handleKeyDown}>
					{chains?.length >= 7 ?
						<div className="filter-chains__search" tabIndex={0}>
							<div className="filter-chains__search--input-wrap">
								<SearchIcon/>
								<input
									value={searchText}
									onChange={(e) => setSearchText(e?.target?.value || '')}
									type="text" placeholder={t('input_search')}
									ref={inputRef}
								/>
							</div>
						</div>
						: null}
					{getFilteredChains().length ?
						<div style={{paddingRight: 16, paddingBottom: 4}}>
							<div className="filter-chains__list custom-scroll">
								{searchText ? null :
									<div className="filter-chains__list--item">
										<CheckBox
											checked={selectedIdsChain.length === chains.length}
											onChange={() => {
												handleAllClick(!isAll);
											}}
											label={i18n.language === 'ru' ? 'Все' : 'All'}
										/>
									</div>
								}
								{getFilteredChains().map((chain) => (
									<div
										key={chain.id}
										className="filter-chains__list--item">
										<CheckBox
											checked={selectedIdsChain.includes(chain.id)}
											onChange={() => {
												onSelectedFilter(chain.id)
											}}
											label={chain.name}
										/>
									</div>
								))}
							</div>
						</div>
						:
						<>
							{searchText ?
								<div className="filter-chains__empty">
									<span>{t('no_found_search_item')}</span>
								</div>
								:
								null
							}
						</>
					}
					<div className="filter-chains__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							disabled={!selectedIdsChain.length}
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-chains__label ${selectedChainsFilterState.length ? 'selected' : ''}`}>
				<div className={`filter-chains__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedChainsFilterState.length ?
						<ArrowLeftIcon className={`filter-chains__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedChainsFilterState.length ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-chains__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterChains;