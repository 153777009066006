// Import packages
import React from 'react';

// Import assets
import './list-item.scss';
import {DeletingIcon, EditIcon, EllipsisIcon} from "../../../../../image";

// ImportComponents
import DropdownMenu from "../../../../../shared/features/dropdownMenu/DropdownMenu";

// Import Utils
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ROLE_ITEMS, rolePermissions} from "../../../../../utils/constants";
import {hasPermission} from "../../../../../utils/helperFunctions";

function ListItem({data, DeleteRole, setShowAddRoleModal, setEditedRole, isMax}) {
    const {t,i18n} = useTranslation();

    function toggleUpdate() {
        setEditedRole(data)
        setShowAddRoleModal(true)
    }

    return (
        <div className="list-item-roles">
            <div className="list-item-roles__content">
                <div className="item_col">
                    <div className="role_name" onClick={toggleUpdate}>
                        {data?.name}

                        {!!data?.permissions?.length && <div className="role-permissions-view">
                            {
                                Object.entries(rolePermissions(i18n.language)).map(([key, value]) => {
                                    let keys = []
                                    data?.permissions?.forEach(item => {
                                        let key = item.split('_')
                                        key.pop()
                                        key = key.join('_')
                                        !keys?.includes(key) && (keys.push(key))
                                    })
                                    let list = value.filter(i => data?.permissions?.includes(i))
                                    return !!list?.length && <div className="permission_item" key={key}>
                                        <div className="permission_item__title">{key} </div>
                                        <div className="permission_item__length">({list?.length})</div>
                                    </div>
                                })
                            }
                        </div>}
                    </div>

                </div>
                {isMax && <div className="item_col">
                    -
                </div>}
                <div className="item_col">
                    {!!data.users?.length && <Link to={{
                        pathname: '/dashboard/users/persons',
                        state: {
                            role_id: data?.id
                        }
                    }} className="users_count">+{data.users?.length}</Link>}

                </div>
            </div>
            <div className="list-item-roles__actions">
                {(hasPermission(ROLE_ITEMS.ROLES_EDIT) || hasPermission(ROLE_ITEMS.ROLES_DELETE)) &&
                <DropdownMenu button={
                    <EllipsisIcon className={'icon-menu'}/>
                }
                              closeAfterToggle={true}
                              className="actions-menu"
                              contentWidth={'176px'}
                >
                    {hasPermission(ROLE_ITEMS.ROLES_EDIT) &&
                        <div className={'menu-item'} onClick={(e) => {
                            e.stopPropagation()
                            toggleUpdate()
                        }}>
                            <EditIcon className={''}/> {t('button_edit')}
                        </div>
                    }
                    {hasPermission(ROLE_ITEMS.ROLES_DELETE) &&
                        <div className={'menu-item'} onClick={(e) => {
                            e.stopPropagation()
                            DeleteRole(data)
                        }}>
                            <DeletingIcon className={''}/> {t('button_delete')}
                        </div>
                    }
                </DropdownMenu>
                }
            </div>
        </div>
    );
}

export default ListItem;
