import './ManageChannels.scss';
import React, {useContext, useEffect, useState} from 'react';
import {hasPermission} from "../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
	ArrowLeftIcon,
	ChainIcon,
	CloseStrokeIcon, DeletingIcon, EditIcon, EllipsisIcon,
	PlusNewIcon
} from "../../../image";
import {CheckBox} from "../../CheckBox";
import {DeleteChannel, DeleteVideoToChain, GetChannels, UpdateVideoChannel} from "../../../redux/actions";
import {Accordion, useAccordionButton} from "react-bootstrap";
import {Popover, Tooltip} from "antd";
import ChainBlock from "./components/ChainBlock";
import {Button} from "../../Button";
import {ToastContext} from "../../Toast/ToastContext";


const ManageChannelsBlock = (props) => {
	const {t} = useTranslation();
	const userProfile = useSelector(state => state.profile.userProfile);
	const channels = useSelector(state => state.channels?.channels || []);
	const sortChannels = channels.sort((item) => item?.is_private ? -1 : 1);
	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];
	const [activeTabs, setActiveTabs] = React.useState([]);
	const {
		setShowChannelModal, handleCheckedData, video, onHide, setOpenCreate,
		setEditingChannel,
		handleCheckedChainsData
	} = props;
	const [checkedChannels, setCheckedChannels] = useState(null);
	const dispatch = useDispatch();
	const {setToastInfo} = useContext(ToastContext);
	let list = checkedChannels && Object.keys(checkedChannels).filter(i => checkedChannels?.[i]?.is_bound && i !== '84')
	const isCheckOwner = ((video?.owners || []).some((item) => item?.user_id === userProfile?.id)
		|| (video?.owners || []).some((item) => item?.person_id === userProfile?.attached_person?.id));
	const isCheckUpdateChannels = isCheckOwner ? true
		: (hasPermission(ROLE_ITEMS.FOREIGN_VIDEOS_MAIN_PROPERTIES_EDIT) || hasPermission(ROLE_ITEMS.FOREIGN_VIDEOS_FULL_ACCESS));
	const [checkedChain, setCheckedChains] = useState([]);
	const disabledBtn = video?.channels?.sort()?.toString() !== list?.sort()?.toString() || !video?.chains?.length !== checkedChain.length;
	const [open, setOpen] = useState();


	useEffect(() => {
		const arr = (video?.chains_ids || video?.chain_ids || []).map(item => {
			return {
				id: item,
				is_bound: true
			}
		});
		setCheckedChains(arr);
	}, [video])

	useEffect(() => {
		let channels = {
			...checkedChannels,
		}
		!!video?.channels?.length && video?.channels.forEach(id => {
			channels = {
				...channels,
				[id]: {
					id,
					is_bound: true
				}
			}
		})
		setCheckedChannels(channels)
		handleCheckedData && handleCheckedData(channels)
	}, [])

	const selectAllChains = (isChecked, channel) => {
		let channels = {
			...checkedChannels,
			[channel?.id]: {
				id: channel?.id,
				is_bound: isChecked
			}
		}
		setCheckedChannels(channels)
		handleCheckedData && handleCheckedData(channels)
	}

	function onClose() {
		onHide()
		setEditingChannel('')
		setOpenCreate(false)
		setCheckedChannels(null)
	}

	const allChains = useSelector(state => state.chains?.chainsList || []);
	console.log(allChains)

	async function saveChanges() {
		try {
			const data = {
				channels: Object.values(checkedChannels)
			}
			await dispatch(UpdateVideoChannel(video.id, data, checkedChain));
			await updateChains();
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('video_update_success')
			});
			onClose();
		} catch (e) {
			console.log('e', e)
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('video_update_error')
			});
		}
	}

	const deleteSelectedChains = (chainsId) => {
		const arr = checkedChain.map((item) => {
			if(item.id === chainsId) {
				return {
					...item,
					is_bound: false
				}
			}
			return item
		});
		setCheckedChains(arr)
		if(handleCheckedChainsData) handleCheckedChainsData(arr)
	}

	const updateChains = async () => {
		try {
			await dispatch(DeleteVideoToChain(video?.id, checkedChain));
			await dispatch(GetChannels());
		} catch (e) {
			console.warn(e)
		}
	}

	function CustomToggle({children, eventKey}) {
		const handleClickToggle = () => {
			if(activeTabs.includes(eventKey)) {
				setActiveTabs(activeTabs.filter(item => item !== eventKey));
			} else {
				setActiveTabs([...activeTabs, eventKey]);
			}
		}
		const decoratedOnClick = useAccordionButton(eventKey, handleClickToggle);

		return (
			<ArrowLeftIcon
				className="channel-block__container--arrow"
				onClick={decoratedOnClick}
			/>
		);
	}
	const handleOpenChange = (value, id) => {
		setOpen({
			...open,
			[id]: value
		});
	};

	const renderAccordionChannel = ({data, index}) => {
		const checkIsChannel = checkedChannels?.[data?.id]?.is_bound;
		const channelChains = data.chains.map((item) => item.id);

		return (
			<Accordion
				className={`${activeTabs.includes(index) ? 'active' : ''}`}
				activeKey={activeTabs}
				style={{
					'--chain_bg': data?.is_private ? 'rgba(26, 187, 120, 0.10)'
						: tagColors[index >= 5 ? index % 5 : index] + '14',
					'--chain_color': data?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
					paddingBottom: data?.is_private ? '12px' : '0',
				}}
			>
				<Accordion.Item
					eventKey={index}
					style={{
						borderBottom: data?.is_private ? '0.7px solid #E6E6E6' : 'none',
					}}
				>
					<div className={`channel-block`}>
						<div className="channel-block__container">
							<CheckBox
								type={'checkbox'}
								onChange={(e) => selectAllChains(e?.target?.checked, data)}
								checked={checkIsChannel}
							/>
							{(data?.name || '').length > 38 ?
								<Tooltip
									title={data?.name}
									placement="top"
								>
									<div className={`channel-block__container--badge`}>
										{data?.name}
									</div>
								</Tooltip>
								:
								<div className={`channel-block__container--badge`}>
									{data?.name}
								</div>
							}
							{(hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || data?.is_private) &&
								<CustomToggle eventKey={index} className="channel-block__container--arrow"/>}
							{!data?.is_private && hasPermission(ROLE_ITEMS.CHANNELS_EDIT)
								&&
								<Popover
									arrow={false}
									open={open?.[data?.id]}
									onOpenChange={(e)=>handleOpenChange(e, data?.id)}
									trigger='click'
									placement="bottomLeft"
									overlayClassName='popover_edit-menu'
									destroyTooltipOnHide={true}
									overlayStyle={{zIndex: 1300}}
									content={
										<>
											{hasPermission(ROLE_ITEMS.CHANNELS_EDIT) ?
												<div
													className={'popover_edit-menu--item delete'}
													onClick={(e) => {
														handleOpenChange(false,data?.id);
														setEditingChannel(data);
														setShowChannelModal(true)
													}}
												>
													<EditIcon className={''}/> {t('button_edit')}
												</div>
												: null}
											{hasPermission(ROLE_ITEMS.CHANNELS_DELETE) ?
												<div
													className={'popover_edit-menu--item delete'}
													onClick={(e) => {
														handleOpenChange(false,data?.id);
														dispatch(DeleteChannel(data?.id))
													}}
												>
													<DeletingIcon className={''}/> {t('button_delete')}
												</div>
												: null}
										</>
									}
								>
									<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
										<EllipsisIcon/>
									</div>
								</Popover>
							}
						</div>
						{(!activeTabs.includes(index) && checkedChain.filter((chain) => channelChains.includes(chain.id)).length) ?
							<div className="channel-block__chains">
								{checkedChain
									.filter((chain) => channelChains.includes(chain.id))
									.filter((chain) => chain.is_bound)
									.map((item, index) => {
										const chainInfo = data.chains.find((chain) => chain.id === item.id);

										return (
											<>
												{(item?.name || '').length > 34 ?
													<Tooltip
														title={chainInfo?.name}
														placement="top"
													>
														<div className="channel-block__chains--item" key={index}>
															<ChainIcon/>
															<span>{chainInfo?.name}</span>
															{(hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || data?.is_private) &&
																<CloseStrokeIcon onClick={() => deleteSelectedChains(chainInfo?.id)}/>}
														</div>
													</Tooltip>
													:
													<div className="channel-block__chains--item" key={index}>
														<ChainIcon/>
														<span>{chainInfo?.name}</span>
														{(hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || data?.is_private) &&
															<CloseStrokeIcon onClick={() => deleteSelectedChains(chainInfo?.id)}/>}
													</div>
												}
											</>
										)
									})}
							</div>
							: null}
					</div>
					<Accordion.Body>
						<ChainBlock channel={data} checkedChain={checkedChain} setCheckedChains={setCheckedChains} handleCheckedChainsData={handleCheckedChainsData}/>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		)
	}

	return (
		<div className="auto-chains-channels">
			<span className="auto-chains-channels--title">{t('manage_channels')}</span>
			{hasPermission(ROLE_ITEMS.CHANNELS_CREATE) &&
				<Button
					variant="ghost"
					text={t('create_channel')}
					leftIcon={<PlusNewIcon/>}
					size="xs"
					className="auto-chains-channels--btn"
					onClick={() => {
						setShowChannelModal(true)
					}}
				/>
			}
			<div className="custom-scroll" style={{overflow: 'auto', maxHeight: '400px'}}>
				<div className="auto-chains-channels__content">
					{sortChannels?.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
			<div className="buttons-wrapper">
				<Button
					text={t('button_cancel')}
					onClick={onClose}
					variant="secondary"
				/>
				<Button
					text={t('button_save')}
					onClick={saveChanges}
					disabled={!disabledBtn || !isCheckUpdateChannels}
					variant="primary"
				/>
			</div>
		</div>
	);
};

export default ManageChannelsBlock;
