// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {Accordion, Modal, Tab, Tabs} from "react-bootstrap";

// Import assets
import './addUserModal.scss';
import {ArrowDownIcon, CloseIcon, EyeIcon} from "../../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {AddUser, GetRoles, GetUsersPersons} from "../../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import config from "../../../../../../utils/config"

// Import Components
import ParticipationData from "../../../Profile/ParticipationData";
import InfoTooltip from "../../../Profile/tooltip";
import UserRoles from "../../../../../../shared/profile/UserRoles";
import {initialErrors, initialPersonInfoState} from "./const";
import {generateData, validateUserForm} from "./helpers";
import {Loader} from "../../../../../../shared/features/Loader";
import {ProfileContacts} from "../../../../../../modules/UserInfo/components/ProfileContacts";
import {ProfileMedia} from "../../../../../../modules/UserInfo/components/ProfileMedia";
import {ProfileTags} from "../../../../../../modules/UserInfo/components/ProfileTags";
import {UserPersonInfoContext} from "../../../../../../modules/UserInfo/components/contexts";
import {hasPermission} from "../../../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../../../utils/constants";

const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
    person_info: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}


function AddUserModal(props) {
    const {show, personById, rolesList, userProfile} = props;
    const {t} = useTranslation();

    const [userInfo, setUserInfo] = useState(initialState);
    const [contactItems, setContactItems] = useState(initialContactState);
    const [personInfo, setPersonInfo] = useState(initialPersonInfoState);
    const [errors, setErrors] = useState(initialErrors);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userRole, setUserRole] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    let dataForm = generateData(userInfo, personInfo, contactItems, rolesList?.[1] !== userRole ? userRole : null);
    const dispatch = useDispatch();

    useEffect(() => {
        if ((rolesList || 0).length > 1) {
            setUserRole(rolesList?.[1])
        }
    }, [rolesList]);

    useEffect(()=>{
        dispatch(GetRoles());
    },[])


    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)
    }

    async function saveChanges() {
        try {
            const isCheckValidateForm = validateUserForm(dataForm);
            if (!!isCheckValidateForm) {
                setErrors(isCheckValidateForm);
            } else {
                setLoading(true);
                setErrors(isCheckValidateForm || initialState);
                await props.AddUser(dataForm)
                await props.GetUsersPersons();
                setLoading(false);
                onClose();
            }
        } catch (e) {
            console.log('e', e)
            setLoading(false);
            setErrors({
                ...errors,
                addUser: `Ошибка при добавлении пользователя.  ${e?.response?.data?.error || ''}`
            })
        }
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        props.closeModal()
    }

    const onChangePersonInfo = (name, value) => {
        setPersonInfo({
            ...personInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    useEffect(() => {
        if (userProfile) {
            onChangePersonInfo('company', userProfile?.company?.name)
        }
    }, [userProfile])

    const isCheckPermissionEditRoles = hasPermission(ROLE_ITEMS.FOREIGN_USERS_CREATE) && hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT)

    return <Modal
        show={show}
        onHide={onClose}
        size="lg"
        className={'info_modal'}
        centered
    >
        <UserPersonInfoContext.Provider value={{userInfo}}>
            <div className="add_user_modal">
                <div className="user-header">
                    {t('add_user_title')}
                    <CloseIcon onClick={onClose}/>
                </div>
                <div className="add_user_modal__wrapper">
                    <div className="add_user_modal__media">
                        <ProfileMedia/>
                        <ProfileTags onChangeTags={(tags) => onChangePersonInfo('tags', tags)}/>
                    </div>
                    <div className="add_user_modal__content">
                        <div className={'input-form'}>
                            <div className="row-item">
                                <div className="input_group">
                                    <label htmlFor="">{t('input_name')}</label>
                                    <input type="text"
                                           value={userInfo?.first_name}
                                           autoComplete={'off'}
                                           name={'first_name'}
                                           onChange={getInputValue}
                                           placeholder={t('input_name')}
                                    />
                                    {!!errors?.first_name && (
                                        <div className="input_group__error">{errors?.first_name}</div>
                                    )}
                                </div>
                                <div className="input_group">
                                    <label htmlFor="">{t('input_surname')}</label>
                                    <input type="text"
                                           value={userInfo?.last_name}
                                           name={'last_name'}
                                           autoComplete="off"
                                           onChange={getInputValue}
                                           placeholder={t('input_surname')}
                                    />
                                    {!!errors?.last_name && (
                                        <div className="input_group__error">{errors?.last_name}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="input_group">
                                    <label htmlFor="">{t('input_company')}</label>
                                    <input type="text"
                                           value={personInfo?.company}
                                           name={'company'}
                                           disabled={true}
                                           onChange={getInputValue}
                                           placeholder={t('input_company')}
                                    />
                                </div>
                                <div className="input_group">
                                    <label htmlFor="">{t('input_position')}</label>
                                    <input type="text"
                                           value={personInfo?.position}
                                           name={'position'}
                                           onChange={(e) => onChangePersonInfo('position', e?.target?.value || '')}
                                           placeholder={t('input_position')}
                                    />
                                </div>
                            </div>

                            <div className="input_group full">
                                <label htmlFor="">{t('input_notes')}</label>
                                <textarea value={personInfo?.note}
                                          name={'note'}
                                          onChange={(e) => onChangePersonInfo('note', e?.target?.value || '')}
                                          placeholder={t(config.productName)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ProfileContacts
                    contactItems={contactItems}
                    setContactItems={setContactItems}
                    errors={errors}
                    setErrors={setErrors}
                />

                <div className="settings_tab">
                    <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example">
                        <Tab eventKey="0"
                             className={'settings_content'}
                             title={
                                 <span className="tab-title">
                        {t('profile_info_title')}
                                     <InfoTooltip title={t('tooltip_info_title')}
                                                  description={t('tooltip_info_description')}/>
                    </span>
                             }>
                            <ParticipationData avatar={personById?.avatar}
                                               userRoles={props.userRoles}/>
                        </Tab>
                        <Tab eventKey="1"
                             className={'settings_content'}
                             title={
                                 <span className="tab-title">
                        {t('profile_settings_title')}
                                     <InfoTooltip title={t('tooltip_settings_title')}
                                                  description={t('tooltip_settings_description')}/>
                    </span>
                             }>
                            <div className="user-info-settings">
                                <span>{t('profile_settings_desc')}</span>
                                <div className="input_group">
                                    <label htmlFor="">{t('input_login')}</label>
                                    <input type="text"
                                           value={userInfo?.login}
                                           name={'login'}
                                           onChange={getInputValue}
                                           placeholder={t('input_login')}
                                           autoComplete={'off'}
                                    />
                                    {!!errors.login && (
                                        <div className="input_group__error">{errors.login}</div>
                                    )}
                                </div>
                                <div className="input_group">
                                    <label htmlFor="">{t('input_password')}</label>
                                    <input
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        value={userInfo?.password}
                                        name={'password'}
                                        onChange={getInputValue}
                                        placeholder={t('input_password')}
                                        autoComplete="new-password"
                                    />
                                    <EyeIcon className="input-icon"
                                             onClick={() => setPasswordVisible(!passwordVisible)}/>
                                    {!!errors.password && (
                                        <div className="input_group__error">{errors.password}</div>
                                    )}
                                </div>
                            </div>
                            <div className="roles-accordion">
                                <Accordion defaultActiveKey="1" className={'accordion-wrapper'}>
                                    <Accordion.Item eventKey="1" className={'roles-accordion__wrap'}>
                                        <Accordion.Header
                                            className={`roles-accordion__wrap--header `}>
                                            <ArrowDownIcon className={`arrow-icon`}/>
                                            {t('profile_roles')}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {isCheckPermissionEditRoles ?
                                                <UserRoles rolesList={rolesList}
                                                           toggleRole={toggleRole}
                                                           userRole={userRole}
                                                />
                                                :
                                                <div className="roles-accordion__list">
                                                    <div className="roles-accordion__list--item">
                                                        {userRole?.name}
                                                    </div>
                                                </div>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                {!!errors?.addUser && (
                    <div className="input_group__error error_main">{errors?.addUser}</div>
                )}
                <div className="buttons-wrapper">
                    <button className={`close_btn`} onClick={onClose}
                    >
                        {t('button_cancel')}
                    </button>

                    <button
                        className={`save_btn`}
                        onClick={saveChanges}
                    >
                        {isLoading ? <Loader size={20} color="white"/> : ''} {t('button_save_changes')}
                    </button>
                </div>
            </div>
        </UserPersonInfoContext.Provider>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['videosList', 'personById', 'rolesList', 'userRoles', 'userProfile'])
};

const mapDispatchToProps = {
    AddUser,
    GetUsersPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
