import React from 'react';
import {Button} from "../../../../Button";
import {useTranslation} from "react-i18next";

const EditChainInput = (props) => {
	const {setChainName, chainName, closeAndClearInput, onSave} = props;
	const {t} = useTranslation();

	return (
		<div className="chain-block__input-wrap" >
			<input
				onChange={(e) => {
					const value = e?.target?.value;
					e.stopPropagation();
					if(value.length <= 20) {
						setChainName(e.target.value);
					}
				}}
				value={chainName}
				name="chain-name"
				className="chain-block__input-wrap--input"
				placeholder={t('input_placeholder_chain')}
				type="text"
			/>
			<div className="chain-block__input-wrap--buttons">
				<Button
					onClick={closeAndClearInput}
					text={t('button_cancel')}
					variant="ghost"
				/>
				<Button
					text={t('button_save')}
					disabled={!chainName}
					onClick={onSave}
					variant="ghost"
				/>
			</div>
		</div>
	);
};

export default EditChainInput;