import React, {useEffect} from "react";
import {ArrowDownIcon, SearchIcon} from "../../../../../image";
import './search-texts.scss'
import {useTranslation} from "react-i18next";
import {Loader} from "../../../../../shared/features/Loader";

function SearchTexts(props) {
    const {searchText, setSearchText, foundedTexts, activeTextIndex, toggleActiveTextIndex} = props;

    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [test,setTest] = React.useState(searchText);

    function getInputValue(e) {
        setTest(e.target.value);
        setIsLoading(true);
        setTimeout(() => {
            setSearchText(e.target.value)
            setIsLoading(false);
        },600);
        toggleActiveTextIndex(0);
    }

    useEffect(() => {
        setTest(searchText)
    }, [searchText]);

    return <div className="texts-search-wrapper">
        <SearchIcon/>
        <input
            onChange={getInputValue}
            value={test}
            name="description"
            placeholder={t('input_search')}
            type="text"
        />
        <>
            {isLoading ? <Loader size={15}/> :
              <>
            <div className="arrows">
                <ArrowDownIcon onClick={()=> activeTextIndex > 0 && toggleActiveTextIndex(activeTextIndex - 1)}/>
                <ArrowDownIcon onClick={()=> activeTextIndex < foundedTexts?.length && toggleActiveTextIndex(activeTextIndex + 1)}/>
            </div>
            <div className="count-wrapper">
                {activeTextIndex}/{foundedTexts?.length}
            </div>
                  </>
            }
        </>
    </div>
}

export default SearchTexts
