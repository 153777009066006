import React from 'react';
import './RecapTitleBlock.scss'
import {getTextItemTime} from "../../helpers";
import {MarkDownText} from "../../../../shared/features/MarkDownText";
import i18n from "i18next";

const RecapTitleBlock = ({index,data,changeVideoTime,searchText, foundedTexts,activeTextIndex}) => {

	return (
		<div className="recap-title-block">
			<MarkDownText
				text={`${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index+1}. ${data?.text}`}
				isSearch={true}
				searchText={searchText}
				className={'text-markdown'}
				item={data}
				activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
				activeTextIndex={activeTextIndex}
			/>
			<span
				onClick={() => changeVideoTime(data?.start_time,data?.start_time)}
				className="recap-title-block--text"
			>
			     {getTextItemTime(data?.start_time || 0,)}
			  </span>
		</div>
	);
};

export default RecapTitleBlock;