import React, {useState} from 'react';
import './SettingsAutoChainsShareLink.scss';
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import CheckBox from "../../../../../CheckBox/CheckBox";
import dayjs from "dayjs";
import {
	getDateOptions,
	getMonths,
	getMonthsOptions,
	getWeekDays, getYearOptions
} from "../../../../../Filters/components/FilterDate/helper";
import {Calendar, DateObject} from "react-multi-date-picker";
import {Select} from "antd";
import {ArrowLeftIcon} from "../../../../../../image";

const SettingsAutoChainsShareLink = (props) => {
	const {settings, setSettings} = props;
	const {t, i18n} = useTranslation();
	const [dateCalendar, setDateCalendar] = useState();

	const [dateInputs, setDateInputs] = useState({
		day: dayjs().date(),
		month: dayjs().month() + 1,
		year: dayjs().year()
	});

	const getYear = (value) => {
		const year = value || dayjs().year();
		const years = getYearOptions();
		return years.find((item) => item.value === year);
	}

	const getDay = (day) => {
		const date = day || dayjs().date();
		const dates = getDateOptions();
		return dates.find((item) => item.value === date);
	}

	const getMonth = (value) => {
		const month = value || dayjs().month() + 1;
		const months = getMonthsOptions(i18n.language);
		return months.find((item) => item.value === month);
	}

	const onChangeDateCalendar = (dates) => {
		const date = new DateObject(dates).format('YYYY-MM-DD');
		setDateCalendar(dates)
		setDateInputs({
			day: dayjs(date).date(),
			month: dayjs(date).month() + 1,
			year: dayjs(date).year()
		});
		setSettings({
			...settings,
			share_link_expiration_time: dayjs(date).format('YYYY-MM-DD'),
		})
	}

	const changeDateInputs = (typeValue, value) => {
		const obj = {
			...dateInputs,
			[typeValue]: value
		}
		setDateInputs(obj);
		const date = 	new DateObject({
			day: obj.day,
			month: obj.month,
			year: obj.year
		})

		setDateCalendar(date);
		setSettings({
			...settings,
			share_link_expiration_time: dayjs(date).format('YYYY-MM-DD'),
		})
	}

	return (
		<div className="auto-chains-share-link">
			<span className="auto-chains-share-link--title">{t('profile_link')}</span>
			<div className="auto-chains-share-link__content">
				<Form.Check
					type="switch"
					id="switch-one"
					className="auto-chains-share-link__content--switch"
					label={settings?.share_link_is_public ? t('public') : t('non_public')}
					checked={settings?.share_link_is_public}
					onChange={(e) => {
						setSettings({
							...settings,
							share_link_is_public: e?.target?.checked
						})
					}}
				/>
				{settings?.share_link_is_public ?
					<>
						<CheckBox
							inline
							name="type"
							label={t('indefinitely')}
							className="auto-chains-share-link__content--toggle"
							type={'radio'}
							checked={!settings?.share_link_expiration_time}
							onChange={() => setSettings({
								...settings,
								share_link_expiration_time: null
							})}
						/>
						<CheckBox
							inline
							name="type"
							className="auto-chains-share-link__content--toggle"
							label={`${t('by_date')} (${t('by')} ${settings?.share_link_expiration_time  ? dayjs(settings?.share_link_expiration_time).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')})`}
							type={'radio'}
							checked={settings?.share_link_expiration_time}
							onChange={() => setSettings({
								...settings,
								share_link_expiration_time: dayjs(),
							})}
						/>
						{settings?.share_link_expiration_time ?
						<div className="auto-chains-share-link__content--calendar">
							<div className="auto-chains-share-link__content--inputs">
								<Select
									suffixIcon={<ArrowLeftIcon/>}
									style={{width: '60px'}}
									options={getDateOptions()}
									value={getDay(dateInputs.day)}
									onChange={(value) => changeDateInputs('day', value)}
								/>
								<Select
									suffixIcon={<ArrowLeftIcon/>}
									style={{width: '110px'}}
									options={getMonthsOptions(i18n.language)}
									value={getMonth(dateInputs.month)}
									onChange={(value) => changeDateInputs('month', value)}
								/>
								<Select
									suffixIcon={<ArrowLeftIcon/>}
									style={{width: '73px'}}
									options={getYearOptions()}
									value={getYear(dateInputs.year)}
									onChange={(value) => changeDateInputs('year', value)}
								/>
							</div>
							<Calendar
								className="auto-chains-share-link__content--calendar"
								weekStartDayIndex={1}
								weekDays={getWeekDays(i18n.language)}
								months={getMonths(i18n.language)}
								calendarPosition="bottom-left"
								value={dateCalendar}
								// minDate={new Date()}
								showOtherDays
								onChange={onChangeDateCalendar}
							/>
						</div>
							: null}
					</>
					: null}
			</div>
		</div>
	);
};

export default SettingsAutoChainsShareLink;