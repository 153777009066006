import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {GetStatusVideo, GetVideoById} from "../../../../../redux/actions";
import {checkVideoSuccessStatus} from "../const";

const VideoStatus = () => {
	const videoInfo = useSelector(state => state.videos.videoById);
	const [status, setStatus] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		let timerId = null;

		// Проверяем статус видео, если попадает в массив checkVideoSuccessStatus, обновляем видос. Пропадают лоадеры на странице
		const fetchStatus = async () => {
			try {
				const response = await dispatch(GetStatusVideo(videoInfo?.id));
				const statusResponse = response?.video_status;
				setStatus(statusResponse);

				if (checkVideoSuccessStatus(statusResponse)) {
					clearInterval(timerId);  // Останавливаем таймер
					timerId = null;
					if(videoInfo?.video_status !== statusResponse){
						window.location.reload();
					}
				}
			} catch (error) {
				console.error('Ошибка при запросе статуса', error);
			}
		};
			timerId = setInterval(fetchStatus, 30000);

		// Очищаем таймер при размонтировании компонента
		return () => {
			if (timerId) {
				clearInterval(timerId);
			}
		};
	}, []);

	return (
		<div style={{display: 'none'}}/>
	);
};

export default VideoStatus;
