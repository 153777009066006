// Import packages
import React, {useState} from 'react';

// Import assets
import './list-item.scss';
import {DeletingIcon, EditIcon, EllipsisIcon, EnterIcon, VideoIcon} from "../../../../../../image";

// Import Utils
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import {history} from "../../../../../../utils/history";
import {useTranslation} from "react-i18next";
import {getAuthorName, hasPermission} from "../../../../../../utils/helperFunctions";
import {Popover} from "antd";
import UserDeleteModal from "../../../../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";
import {ROLE_ITEMS} from "../../../../../../utils/constants";

function ListItem({data, openModal, setCurrentUser, onDeleteUser}) {
    const {t, i18n} = useTranslation();
    const [isVisibleDeleteAccountModal, setVisibleDeleteAccountModal] = useState(false);

    function toggleUpdate() {
        setCurrentUser(data)
        openModal()
    }
    const onConfirmDeleteProfileButton = () => {
        setVisibleDeleteAccountModal(false)
        onDeleteUser(data);
    }
    function navigateTo() {
        !!data?.videos_count && history.push({
            pathname: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
            state: {
                personId: data.person_id,
                routs: [
                    {
                        path: '/dashboard/users/new',
                        name: t('members_title')
                    },
                    {
                        id: data.person_id ? data.person_id : data.user_id,
                        name: getAuthorName(data, null, i18n.language),
                    },
                    {
                        path: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
                        name: 'Видео с ' + getAuthorName(data, null, i18n.language),
                        active: true
                    }
                ]
            }
        })
    }

    return (
        <div className="list-item new">
            <div className="list-item__content">
                <div className="user_info item_col">
                    <img
                        src={generateMediaUrl(data?.avatar)}
                        alt=""
                        className="user_info__img"
                    />
                </div>
                <div className="item_col">
                    <div className="user_info__content">
                        <div className="name" onClick={toggleUpdate}>
                            #{data?.person_id}
                        </div>
                    </div>
                </div>
                <div className="item_col">
                    {data?.date ? data?.date : '-'}
                </div>
                <div className="video_count item_col" onClick={navigateTo}>
                    <VideoIcon/> {data?.videos_count}
                </div>
            </div>
            <div className="list-item__actions">
                    {(hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT)
                      || hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT)
                      || hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_DELETE)) ?
                <Popover
                    arrow={false}
                    trigger='click'
                    placement="rightTop"
                    overlayClassName='popover_edit-menu'
                    content={
                        <>
                        {(hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT)
                          || hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT)) ?
                            <div
                                className={'popover_edit-menu--item'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleUpdate()
                                }}
                            >
                                <EditIcon onClick={toggleUpdate}/> {t('button_edit')}
                            </div>
                          : null}
                            {hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_DELETE) ?
                            <div
                                className={'popover_edit-menu--item delete'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVisibleDeleteAccountModal(true)
                                }}
                            >
                                <DeletingIcon className={''}/> {t('button_delete')}
                            </div>
                              : null}
                        </>
                    }
                >
                    <div className="popover_edit-menu--settings">
                        <EllipsisIcon/>
                    </div>
                </Popover>
                      :
                      <EnterIcon
                        className={"list-item--enter"}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleUpdate()
                        }}
                      />
                    }
            </div>
            {isVisibleDeleteAccountModal &&
              <UserDeleteModal
                show={isVisibleDeleteAccountModal}
                onClose={() => setVisibleDeleteAccountModal(false)}
                onConfirm={onConfirmDeleteProfileButton}
                isDeleteAccount={true}
              />}
        </div>
    );
}

export default ListItem;
