export const replaceProfileContacts = (e) => {
	const {name, value} = e.target
	let val = value

	if (name.includes('phone')) {
		val = val?.replaceAll('+', '')
		val = '+' + val
	}

	if (name.includes('linkedin')) {
		val = val?.replaceAll('https://www.', '')
		val = val.startsWith('https://www') ? 'https://www.' : 'https://www.' + val
		// val = (!val || val?.startsWith("https://www.")) ? val : ("https://www." + val)
	}
	if (name.includes('facebook')) {
		val = val?.replaceAll('https://', '')
		val = val.startsWith('https:/') ? 'https://' : 'https://' + val
	}

	return val;
}