import React, {useEffect, useRef, useState} from 'react';
import './FilterChannels.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {CheckBox} from "../../../CheckBox";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {ALL_FILTERS, getValueFilters} from "../../helpers";
import {DeleteFilterAction, SetFilterAction} from "../../../../redux/actions";

const FilterChannels = ({pageType, onGetVideos}) => {
    const channels = useSelector(state => state.channels?.channels || []);
    const selectedChannelsFilterState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.CHANNELS] || []);
    const {t, i18n} = useTranslation();
    const [selectedIdsChannel, setSelectedIdsChannel] = useState([...selectedChannelsFilterState]);
    const [open, setOpen] = useState(true);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [isAll, setIsAll] = useState(false);
    const [searchText, setSearchText] = useState('');
    const allFilters = useSelector(state => state.filtersOrSort);
    const allFiltersValue = getValueFilters(allFilters, pageType);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [sortChannels, setSortChannels] = useState([]);

    const getLabel = () => {
        let text = '';

        if (selectedChannelsFilterState.length === 1) {
            const findChannel = channels.find((channel) => channel.id === selectedChannelsFilterState[0]);
            text = findChannel.name
        } else if (selectedChannelsFilterState.length > 1) {
            text = `+${selectedChannelsFilterState.length}`
        } else {
            text = 'All'
        }
        return text
    }

    function handleOpenChange(newOpen) {
        setOpen(newOpen);
        setOpenTooltip(false);
    }


    function handleOpenTooltipChange(newOpen) {
        setOpenTooltip(newOpen);
    }

    const setValueFilter = (data) => {
        dispatch(SetFilterAction({
            pageType,
            filterType: ALL_FILTERS.CHANNELS,
            value: data
        }))
    }

    const onSelectedFilter = (value) => {
        let arr = [...selectedIdsChannel];
        if (selectedIdsChannel.includes(value)) {
            arr = arr.filter((item) => item !== value);
        } else {
            arr.push(value)
        }
        setSelectedIdsChannel(arr);
    }

    const handleCancelClick = () => {
        setOpen(false);
        setOpenTooltip(false);
        setValueFilter(selectedChannelsFilterState);
    }

    const handleAllClick = (isCheck) => {
        if (isCheck) {
            setSelectedIdsChannel(channels.map((item) => item.id))
        } else {
            setSelectedIdsChannel([]);
        }
    }

    const getVideos = async (type) => {
        let params = {
            ...allFiltersValue,
            channel_ids: selectedIdsChannel
        };
        if (!selectedIdsChannel?.length || type === 'delete') delete params?.channel_ids;
        await onGetVideos(params);
    }

    const handleApplyClick = async () => {
        try {
            setOpen(false);
            setOpenTooltip(false);
            setValueFilter(selectedIdsChannel);
            await getVideos()
        } catch (error) {
            console.log(error)
        }
    }

    const RenderTooltip = (props) => {
        if (selectedChannelsFilterState.length > 1) {
            return (
                <Tooltip
                    open={open ? false : openTooltip}
                    onOpenChange={handleOpenTooltipChange}
                    placement="top"
                    title={<RenderTooltipText/>}
                >
                    <span className="filter-channels__label--text">{`${t('channels')}: ${getLabel()}`}</span>
                </Tooltip>
            )
        }
        return (<span className="filter-channels__label--text">{`${t('channels')}: ${getLabel()}`}</span>)
    }

    const RenderTooltipText = () => {
        const channelsFiltered = channels.filter((channel) => selectedChannelsFilterState.includes(channel.id));
        return (
            <div className="filter-channels__tooltip">
                {channelsFiltered.map((channel) => (
                    <div className="filter-channels__tooltip--text">{channel?.name}</div>
                ))}
            </div>
        )
    }

    const getFilteredChannels = () => {
        if (searchText) {
            return channels.filter((channel) => (channel?.name || '').toLowerCase().includes(searchText.toLowerCase()))
        }
        return channels
    }

    const deleteFilter = async () => {
        dispatch(DeleteFilterAction({
            pageType,
            filterType: ALL_FILTERS.CHANNELS
        }));
        await getVideos('delete')
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && selectedIdsChannel.length) {
            handleApplyClick()
        }
    };

    useEffect(() => {
        setSelectedIdsChannel(selectedChannelsFilterState)
        setSearchText('');
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setSortChannels(sortChannels.sort((a) => selectedIdsChannel.includes(a.id) ? -1 : 1))

    }, [open]);

    useEffect(() => {
        setIsAll(selectedIdsChannel.length === channels.length)
    }, [selectedIdsChannel]);

    useEffect(() => {
        setSortChannels(channels);
    }, [channels]);

    return (
        <Popover
            arrow={false}
            trigger='click'
            placement="bottomLeft"
            overlayClassName='filter-channels'
            onOpenChange={handleOpenChange}
            destroyTooltipOnHide={true}
            open={open}
            content={
                <div className="filter-channels__container" onKeyDown={handleKeyDown}>
                    {channels?.length >= 7 ?
                        <div className="filter-channels__search" tabIndex={0}>
                            <div className="filter-channels__search--input-wrap">
                                <SearchIcon/>
                                <input
                                    value={searchText}
                                    onChange={(e) => setSearchText(e?.target?.value || '')}
                                    type="text" placeholder={t('input_search')}
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                        : null}
                    {getFilteredChannels().length ?
                        <div style={{paddingRight: 16, paddingBottom: 4}}>
                            <div className="filter-channels__list custom-scroll">
                                {searchText ? null :
                                    <div className="filter-channels__list--item">
                                        <CheckBox
                                            checked={selectedIdsChannel.length === channels.length}
                                            onChange={() => {
                                                handleAllClick(!isAll);
                                            }}
                                            label={i18n.language === 'ru' ? 'Все' : 'All'}
                                        />
                                    </div>
                                }
                                {getFilteredChannels().map((channel) => (
                                    <div
                                        key={channel.id}
                                        className="filter-channels__list--item">
                                        <CheckBox
                                            checked={selectedIdsChannel.includes(channel.id)}
                                            onChange={() => {
                                                onSelectedFilter(channel.id)
                                            }}
                                            label={channel.name}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                       <>
                           {searchText ?
                               <div className="filter-channels__empty">
                                   <span>{t('no_found_search_item')}</span>
                               </div>
                               :
                               null
                           }
                       </>
                    }
                    <div className="filter-channels__footer">
                        <Button
                            text={t('button_cancel')}
                            variant="secondary"
                            size="xs"
                            onClick={handleCancelClick}
                        />
                        <Button
                            text={t('apply')}
                            variant="primary"
                            size="xs"
                            disabled={!selectedIdsChannel?.length}
                            onClick={handleApplyClick}
                        />
                    </div>
                </div>
            }
        >
            <div className={`filter-participant__label ${selectedChannelsFilterState.length ? 'selected' : ''}`}>
                <div className={`filter-participant__wrap`}>
                    <FilterIcon/>
                    <RenderTooltip/>
                    {!selectedChannelsFilterState.length ?
                        <ArrowLeftIcon className={`filter-participant__label--arrow ${open ? 'open' : ''}`}/> : null}
                </div>
                {selectedChannelsFilterState.length ?
                    <CloseStrokeIcon
                        onClick={deleteFilter}
                        className={`filter-participant__label--arrow`}
                    /> : null}
            </div>
        </Popover>
    );
};

export default FilterChannels;