import React, {useRef, useState} from 'react';
import './ProfileMedia.scss';
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";

function ProfileMedia(props) {
	const {personById} = props;
	const [file, setFile] = useState(null)
	const uploadImg = useRef();

	function readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function(e) {
				let dataURL = e.target.result;
				resolve(dataURL);
			};
			reader.onerror = function(e) {
				reject(e);
			};
			reader.readAsDataURL(file);
		});
	}

	function handleImageChange(e) {
		let file = e?.target?.files[0];
		readFile(file)
			.then(dataURL => {
				setFile(dataURL)
			});
	}

	return (
		<div className="profile-media">
			<img
				src={file ? file : generateMediaUrl(personById?.avatar)}
				alt=""
			/>
			<input
				type="file"
				accept="image/*"
				ref={uploadImg}
				style={{
					display: 'none'
				}}
				onChange={(e) => handleImageChange(e)}
			/>
		</div>
	)
}

export default ProfileMedia;
