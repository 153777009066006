
export const initialPersonInfoState = {
    company: undefined,
    position: undefined,
    note: undefined,
    signatures: [],
    tags:[],
    linkedin: [''],
    facebook: [''],
    email:[''],
    phone_number: [''],
}

export const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}