import React from 'react';
import Menu from "../../shared/features/Menu";
import Searcher from "../../shared/features/Searcher/Searcher";
import './Layout.scss';

const LayoutContainer = (props) => {
    const headerStyle = {
        textAlign: 'center',
        color: '#fff',
        height: 64,
        paddingInline: 48,
        lineHeight: '64px',
        backgroundColor: '#4096ff',
    };

    const contentStyle = {
        textAlign: 'center',
        minHeight: 120,
        lineHeight: '120px',
        color: '#fff',
        backgroundColor: '#0958d9',
    };

    const siderStyle = {
        textAlign: 'center',
        lineHeight: '120px',
        color: '#fff',
        backgroundColor: '#1677ff',
    };

    const footerStyle = {
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#4096ff',
    };

    const layoutStyle = {
        overflow: 'hidden',
        width: '100vw',
        height: '100vh'
    };


    // return (
        // <Layout style={layoutStyle}>
        //     <Layout.Sider width="25%" style={siderStyle}>
        //         <Menu/>
        //     </Layout.Sider>
        //     <Layout>
        //         <Layout.Header style={headerStyle}>Header</Layout.Header>
        //         <Layout.Content style={contentStyle}>{props.children}</Layout.Content>
        //         <Layout.Footer style={footerStyle}>Footer</Layout.Footer>
        //     </Layout>
        // </Layout>
    // )
    const {children} = props;
    return (
        <div className="layout">
            <Menu/>
            <Searcher name={''}/>
            {children}
        </div>
    );
};

export default LayoutContainer;