// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Accordion, Tab, Tabs, Modal, Toast} from "react-bootstrap";
import {useParams} from "react-router-dom";
import './userInfoModal.scss';
import {
    ArrowDownIcon, CloseIcon, DeletingIcon, ErrorIcon,
    EyeIcon,
    InfoCheckIcon, InfoIcon, SuccessIcon,
} from "../../../../../../image";
import UserRoles from "../../../../../../shared/profile/UserRoles";
import ParticipationData from "../../../Profile/ParticipationData";
import InfoTooltip from "../../../Profile/tooltip";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {
    GetVideos,
    GetPersonById,
    UpdatePersons,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById,
    GetUsersPersons,
    GetVideosService,
    ClearHighlights,
    DeleteUserService, GetRoles, GetVideosServiceReturnList, GetVideoById, GetProtocol
} from "../../../../../../redux/actions";
import {profileErrorMessages, ROLE_ITEMS} from "../../../../../../utils/constants";
import {useTranslation} from "react-i18next";
import config from "../../../../../../utils/config"
import {validateData} from "../../../Profile/helpers/helpers";
import {hasPermission} from "../../../../../../utils/helperFunctions";
import {isCheckContactInfo, isCheckUserInfo} from "./helper";
import {ProfileContacts} from "../../../../../../modules/UserInfo/components/ProfileContacts";
import {ProfileMedia} from "../../../../../../modules/UserInfo/components/ProfileMedia";
import {ProfileTags} from "../../../../../../modules/UserInfo/components/ProfileTags";
import {UserPersonInfoContext} from "../../../../../../modules/UserInfo/components/contexts";
import {Loader} from "../../../../../../shared/features/Loader";
import UserDeleteModal from "../../../../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";


const initialState = {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    position: undefined,
    login: undefined,
    password: undefined,
    note: undefined,
}
const initialContactState = {
    email: [''],
    phone_number: [''],
    linkedin: [''],
    facebook: [''],
}
const initialErrors = {
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: [],
    email: [],
    linkedin: [],
    facebook: [],
}

function UserInfoModal(props) {
    const {show, personById, userById, highlights, rolesList} = props;

    const [userInfo, setUserInfo] = useState(initialState);
    const [types, setTypes] = useState({
        user: true,
        participant: false,
    });
    const [contactItems, setContactItems] = useState(initialContactState);
    const [errors, setErrors] = useState(initialErrors);
    const [videoList, setVideoList] = useState(null);
    const [user, setUser] = useState(null);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [isAdmin, setIsAdmin] = useState(false);
    const [userRole, setUserRole] = useState(undefined);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [findUserInfo, setFindUserInfo] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingVideos, setIsLoadingVideos] = useState(false);
    const dispatch = useDispatch();
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const params = useParams();
    const isCheckPermissionEditUserInfoInputs = (userInfo?.status === 'new' || !userInfo?.user_id)
        ? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT)
        : hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT);

    const isCheckPermissionEditUserSettings = (userInfo?.status === 'new' || !userInfo?.user_id)
      ? hasPermission(ROLE_ITEMS.FOREIGN_USERS_CREATE)
      : hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT);

    const isCheckPermissionEditRoles = hasPermission(ROLE_ITEMS.FOREIGN_USERS_CREATE) && hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT)

    const isCheckPermissionEditViewSettings = (userInfo?.status === 'new' || !userInfo?.user_id)
      ? hasPermission(ROLE_ITEMS.FOREIGN_USERS_CREATE)
      : hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ);

    const isCheckPermissionViewRoleSettings = (userInfo?.status === 'new' || !userInfo?.user_id)
      ? hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT)
      : hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ);


    const {t} = useTranslation();
    const [isVisibleDeleteAccountModal, setVisibleDeleteAccountModal] = useState(false);

    useEffect(() => {
        let paramsUser = null
        params?.personId && (paramsUser = {
            ...paramsUser,
            person_id: params?.personId,
        })
        params?.userId && (paramsUser = {
            ...paramsUser,
            user_id: params?.userId,
        })
        setUser(paramsUser ? paramsUser : props.user)
    }, [props?.user])

    const updateUserInfo = async (data) => {
        let personObj = {}
        let userObj = {}
        if (data?.person_id) {
            const response = await props.GetPersonById(data?.person_id);
           personObj = response;
            setContactItems(isCheckContactInfo(contactItems, response))
        }
        if (data?.user_id) {
            userObj = await props.GetUserById(data?.user_id)
        }
        setUserInfo(isCheckUserInfo(userInfo, personObj,  userObj));
    }

    const findTheUser = async (personId) => {
        try {
            const response = await dispatch(GetUsersPersons({filter: 'all', limit: 1000}, true));
            const users = response?.results || [];
            return users.find((item) => item?.person_id === personId);
        } catch (error) {
            console.error(error)
        }
    }
    const getModalInfo = async (profile) => {
        try {
            if (show) {
                dispatch(ClearHighlights());
                setIsLoading(true);
                const userId = profile?.user_id;
                const personId = profile?.person_id || profile?.id;
                try {
                    if (profile && !userId) {
                        const findUser = await findTheUser(personId);
                        setFindUserInfo(findUser || {});
                        if (findUser?.user_id) {
                            await updateUserInfo({user_id: findUser?.user_id, person_id: null})
                        }
                    } else if (show && userId) {
                        setFindUserInfo(profile || {});
                        await updateUserInfo({user_id: userId, person_id: null})
                    }
                } catch (error) {
                    console.error(error)
                }
                if (personId) {
                    await updateUserInfo({user_id: userId, person_id: personId})
                    getVideos(personId);

                    let reqData = {}
                    userId && (reqData.user_id = userId)
                    personId && (reqData.person_id = personId)
                    setTypes({
                        user: !!userId,
                        participant: !!personId,
                    })
                    dispatch(GetHighlights(reqData));
                }

                setIsLoading(false);
            }
        } catch (e) {
            setIsLoading(false);
            console.log(e)
        }
    }


    const getVideos = async (personId) => {
        try {
            setIsLoadingVideos(true);
           await dispatch(GetVideosServiceReturnList({
                person_id: personId,
                limit: 1000
            })).then((res) => setVideoList(res));

            setIsLoadingVideos(false);

        }catch (error) {
            setIsLoadingVideos(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getModalInfo(user);
    }, [user, show]);


    useEffect(() => {
        if (user?.person_id || user?.user_id || user?.id) {
            setIsAdmin(userById?.is_admin)
            setUserRole(userById?.roles?.[0])
        }
    }, [userById, user?.person_id, user?.id, show])

    useLayoutEffect(() => {
        props.ClearPersonById()
        props.ClearUserById()
        dispatch(GetRoles());
    }, [])

    function checkValidation(data) {
        setErrors(initialErrors)

        const result = validateData({
            data,
            initialErrors,
            t,
            id: personById?.associated_user?.id
        })
        setErrors(result.errors)
        return result.isValid
    }

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    async function saveChanges() {
        let recData = {};
        setErrors(initialErrors)
        userInfo?.first_name && (recData.first_name = userInfo.first_name)
        userInfo?.last_name && (recData.last_name = userInfo.last_name)
        if (user?.person_id || user?.id) {
            if (userInfo?.position !== undefined && userInfo?.position !== null) {
                recData.position = userInfo.position;
            }
            if (userInfo?.note !== undefined && userInfo?.note !== null) {
                recData.note = userInfo.note
            }
            if (userInfo?.organization !== undefined && userInfo?.organization !== null) {
                recData.organization = userInfo.organization
            }

            Object.entries(contactItems).forEach(([key, value]) => {
                // let item = value.filter(i => !!i)
                // item.length && (recData[key] = item)
                recData[key] = value.filter(i => !!i)
            })

            if ((types.user || types.participant) && !personById?.associated_with_user) {

                userInfo?.login && (recData.user = {
                    ...recData?.user,
                    email: userInfo?.login
                })
                userInfo?.password && (recData.user = {
                    ...recData?.user,
                    password: userInfo?.password
                })
            }
            if (checkValidation(recData)) {

                await props.UpdatePersons(user.person_id || user?.id, recData, userInfo)
                    .then(() => {
                        onClose()
                        clearHaveChanges()
                    })
                    .catch(() => {
                        setErrors({
                            ...errors,
                            login: profileErrorMessages?.login
                        })
                    })
                if(params.videoId) {
                    await props.GetVideoById(Number(params.videoId));
                    await props.GetProtocol(Number(params.videoId));
                }
                onClose()
                clearHaveChanges()
            }
        }

        if (findUserInfo?.user_id || isAdmin !== userById?.isAdmin) {
            let recData = {
                is_admin: isAdmin,
                role_ids: (userRole?.id && userRole?.id !== findUserInfo?.role_id) ? [userRole?.id] : undefined,
                last_name: userInfo?.last_name && userInfo?.last_name !== findUserInfo?.last_name ? userInfo?.last_name : undefined,
                first_name: (userInfo?.first_name && userInfo?.first_name !== findUserInfo?.first_name) ? userInfo?.first_name : undefined,
                login: (userInfo?.login && userInfo?.login !== findUserInfo?.login) ? userInfo?.login : undefined,
                password: (userInfo?.password) ? userInfo?.password : undefined,
            }
            props.UpdateUser(findUserInfo?.user_id, recData)
            clearHaveChanges()
            onClose()
        }
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
        const changes = new Map(haveChanges);
        if ((personById?.[name] || userById?.[name] || '') !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function onClose() {
        setUserInfo(initialState)
        setErrors(initialErrors)
        setContactItems(initialContactState)
        setUser(null)
        props.ClearPersonById()
        props.ClearUserById()
        props.closeModal()
    }

    function toggleRole(e, role) {
        e.stopPropagation()
        setUserRole(role)

        const changes = new Map(haveChanges);
        if (userById?.roles?.[0].id !== role?.id) {
            changes.set('userRole', role?.id)
        } else {
            changes.delete('userRole')
        }
        setHaveChanges(changes);
    }

    async function deleteUser() {
        try {
            const userId = userInfo?.user_id;
            const personId = userInfo?.person_id;
            await DeleteUserService(userId);
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t("delete_person_success_text")
            });
            if(personId){
                await getModalInfo({person_id: personId, user_id: null});
            }else{
                onClose();
            }
        } catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t("delete_person_error_text")
            });
        }
    }

    const onConfirmDeleteProfileButton = () => {
        setVisibleDeleteAccountModal(false)
        deleteUser();
    }

    return <Modal
        show={show}
        onHide={onClose}
        size="lg"
        centered
        className={'info_modal'}
        onWheel={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
    >
        {isLoading ? <div className="loader-wrap"><Loader size={140}/></div> :
            <UserPersonInfoContext.Provider value={{userInfo, getModalInfo, updateUserInfo, personById}}>
                <div className="user_info_modal">
                    <div className="user-header">
                        <p className="title">{t('profile_page_title')}
                        </p>
                        <CloseIcon onClick={onClose}/>
                    </div>
                    <div className="user_info_modal__wrapper">
                        <div className="user_info_modal__media">
                            <ProfileMedia personById={personById} />
                            <ProfileTags user={user} personById={personById} isProfile={false} userInfo={userInfo}/>
                        </div>
                        <div className="user_info_modal__content">
                            <form autoComplete={'off'} className={'input-form'}>
                                <div className="row-item">
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_name')}</label>
                                        <input type="text"
                                               value={userInfo?.first_name}
                                               autoComplete={'off'}
                                               name={'first_name'}
                                               onChange={getInputValue}
                                               placeholder={t('input_name')}
                                               disabled={!isCheckPermissionEditUserInfoInputs}
                                        />
                                        {!!errors.first_name && (
                                            <div className="input_group__error">{errors.first_name}</div>
                                        )}
                                    </div>
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_surname')}</label>
                                        <input type="text"
                                               value={userInfo?.last_name}
                                               name={'last_name'}
                                               autoComplete="off"
                                               onChange={getInputValue}
                                               placeholder={t('input_surname')}
                                               disabled={!isCheckPermissionEditUserInfoInputs}
                                        />
                                        {!!errors.last_name && (
                                            <div className="input_group__error">{errors.last_name}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row-item">
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_company')}</label>
                                        <input type="text"
                                               value={userInfo?.organization}
                                               name={'organization'}
                                               disabled={(!isCheckPermissionEditUserInfoInputs || user?.company?.id || (!user?.person_id && !user?.id))}
                                               onChange={getInputValue}
                                               placeholder={t('input_company')}
                                        />
                                    </div>
                                    <div className="input_group">
                                        <label htmlFor="">{t('input_position')}</label>
                                        <input type="text"
                                               value={userInfo?.position}
                                               name={'position'}
                                               disabled={(!isCheckPermissionEditUserInfoInputs || (!user?.person_id && !user?.id))}
                                               onChange={getInputValue}
                                               placeholder={t('input_position')}
                                        />
                                    </div>
                                </div>
                                <div className="input_group full">
                                    <label htmlFor="">{t('input_notes')}</label>
                                    <textarea value={userInfo?.note}
                                              name={'note'}
                                              disabled={(!isCheckPermissionEditUserInfoInputs || (!user?.person_id && !user?.id))}
                                              onChange={getInputValue}
                                              placeholder={t(config.productName)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <ProfileContacts contactItems={contactItems}
                                     errors={errors}
                                     setErrors={setErrors}
                                     haveChanges={haveChanges}
                                     setHaveChanges={setHaveChanges}
                                     personById={personById}
                                     isProfile={false}
                                     userInfo={userInfo}
                                     setContactItems={setContactItems}
                    />

                    <div className="settings_tab">
                        <Tabs
                            defaultActiveKey="0"
                            id="uncontrolled-tab-example">
                            <Tab eventKey="0"
                                 className={'settings_content'}
                                 title={
                                     <span className="tab-title">
                         {t('profile_info_title')}
                                         {!(videoList?.count || highlights?.length) ?
                                             <InfoTooltip title={t('tooltip_info_title')}
                                                          description={t('tooltip_info_description')}
                                             /> : <InfoCheckIcon/>}
                    </span>
                                 }>
                                <ParticipationData videosList={videoList}
                                                   user={user}
                                                   highlights={highlights}
                                                   userInfo={userInfo}
                                                   avatar={personById?.avatar}
                                                   userRoles={props.userRoles}
                                                   setVideoList={setVideoList}
                                                   isLoadingVideos={isLoadingVideos}
                                                   closeModal={props.closeModal}
                                                   isProfile={false}
                                                   setUserInfo={setUserInfo}
                                />
                            </Tab>
                            <Tab eventKey="1"
                                 className={'settings_content'}
                                 title={
                                     <span className="tab-title">
                         {t('profile_settings_title')}
                                         {!userInfo?.login ?
                                             <InfoTooltip title={t('tooltip_settings_title')}
                                                          description={t('tooltip_settings_description')}/>
                                             : <InfoCheckIcon/>}
                    </span>
                                 }>
                                    <div className="user-info-settings">
                                    {isCheckPermissionEditViewSettings? <>
                                        {!personById?.id && <p>{t('profile_settings_desc')}</p>}
                                        <div className="input_group">
                                            <label htmlFor="">{t('input_login')}</label>
                                            <input type="text"
                                                   value={userInfo?.login}
                                                   name={'login'}
                                                   onChange={getInputValue}
                                                   placeholder={t('input_login')}
                                                   autoComplete={'off'}
                                                   disabled={!isCheckPermissionEditUserSettings}
                                            />
                                            {!!errors.login && (
                                                <div className="input_group__error">{errors.login}</div>
                                            )}
                                        </div>
                                        <div className="input_group">
                                            <label htmlFor="">{t('input_password')}</label>
                                            <input
                                                type={`${passwordVisible ? "text" : "password"}`}
                                                value={userInfo?.password}
                                                name={'password'}
                                                onChange={getInputValue}
                                                placeholder={t('input_password')}
                                                autoComplete="new-password"
                                                disabled={!isCheckPermissionEditUserSettings}
                                            />
                                            <EyeIcon className="input-icon"
                                                     onClick={() => setPasswordVisible(!passwordVisible)}/>
                                            {!!errors.password && (
                                                <div className="input_group__error">{errors.password}</div>
                                            )}
                                        </div>
                                    </> : <>
                                        <p>{t('no_access_to_update_user')}</p>
                                        <div className="warning-info">
                                            <InfoIcon/>
                                            <span>{t('no_access_to_update_user_warning')}</span>
                                        </div>
                                    </>}
                                </div>
                                {( isCheckPermissionViewRoleSettings) &&
                                    <div className="roles-accordion">
                                        <Accordion defaultActiveKey="0" className={'accordion-wrapper'}>
                                            <Accordion.Item eventKey="1" className={'roles-accordion__wrap'}>
                                                <Accordion.Header
                                                    className={`roles-accordion__wrap--header `}>
                                                    <ArrowDownIcon className={`arrow-icon`}/>
                                                    {t('profile_roles')}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {isCheckPermissionEditRoles ?
                                                      <UserRoles rolesList={rolesList}
                                                                 toggleRole={toggleRole}
                                                                 userRole={userRole}
                                                      />
                                                      :
                                                      <div className="roles-accordion__list">
                                                          <div className="roles-accordion__list--item">
                                                              {userRole?.name}
                                                          </div>
                                                      </div>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                }
                                {userInfo?.user_id ?
                                    <div className="remove-user">
                                        <div className="remove-user__wrap">
                                            <span
                                                className="remove-user__wrap--title">{t('remove_user_settings')}</span>
                                            <div className="remove-user__wrap--description">
                                              <span>
                                                {t('remove_user_settings_description')}
                                              </span>
                                                <button onClick={() => setVisibleDeleteAccountModal(true)}>
                                                    <DeletingIcon/>
                                                    {t('button_delete_information')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="buttons-wrapper">
                    <button className={`close_btn`} onClick={onClose}
                    >
                        {t('button_cancel')}
                    </button>

                    <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                            disabled={!haveChanges.size}
                            onClick={saveChanges}
                    >
                        {t('button_save_changes')}
                    </button>
                </div>
            </UserPersonInfoContext.Provider>

        }
        <Toast
          className='upload_video_answer'
          onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
          show={toastInfo.isShow}
          delay={3000}
          autohide
        >
            <div className="toasblock">
                {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                <div className="toasblock__wrap">
						                <span
                              className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                    <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                </div>
            </div>
        </Toast>
        {isVisibleDeleteAccountModal &&
          <UserDeleteModal
            show={isVisibleDeleteAccountModal}
            onClose={() => setVisibleDeleteAccountModal(false)}
            onConfirm={onConfirmDeleteProfileButton}
            isDeleteUserSettings={true}
          />}
    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personById', 'userById', 'highlights', 'token', 'rolesList', 'userRoles'
    ])
};

const mapDispatchToProps = {
    UpdatePersons,
    GetPersonById,
    GetVideos,
    UpdateUser,
    GetUserById,
    GetHighlights,
    ClearPersonById,
    ClearUserById,
    GetUsersPersons,
    GetVideosService,
    GetVideoById,
    GetProtocol
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoModal);
