import {UTIL_CONSTS} from "../constants/utilConsts";
import {getRandomColor} from '../../utils/helperFunctions'

export const SetIsMobile = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.SET_MOBILE,
            payload: data
        })
    }
};
export const SetIsFullWidth = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.SET_FULL_WIDTH,
            payload: data
        })
    }
};

export const ChangeHeaderTitle = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.CHANGE_HEADER_TITLE,
            payload: data
        })
    }
};

export const EventColors = ({id, length}) => {
    const data = [];
    for (let i = 0; i < length; i++) {
        data.push(getRandomColor());
    }

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.EVENT_COLORS,
            payload: {id, data}
        })
    }
};

