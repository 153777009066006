import {
	CalendarLogOut,
	ClearUserInfoGoogleService,
	DeleteOutlookAuth,
	GetAuthorizationUrlOutlook,
	GetCalendarSynchronize, GetGoogleAuth,
	GetUserCalendars
} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {googleLogout} from "@react-oauth/google";

export const useCalendarActions = () => {
	const dispatch = useDispatch();
	const OutlookAuth = async () => {
		try{
			const res = await GetAuthorizationUrlOutlook({
				redirect_to_integrations: true
			});
			window.location.href = res.url;
		}catch (e) {
			console.error(e)
		}
	}

	const OutlookLogOut = async () => {
		try{
			localStorage.removeItem('close-connect-calendar-modal')
			await dispatch(DeleteOutlookAuth());
			await dispatch(GetUserCalendars());
			await dispatch(GetCalendarSynchronize());
		}catch (e) {
			console.error(e)
		}
	}

	async function GoogleLogOut() {
		try{
			googleLogout();
			dispatch(ClearUserInfoGoogleService());
			await dispatch(CalendarLogOut());
			await dispatch(GetGoogleAuth());
			await dispatch(GetUserCalendars());
		}catch (e) {
			console.error(e)
		}
	}

	return {
		OutlookAuth,
		GoogleLogOut,
		OutlookLogOut
	};
};