// Import packages
import React, {useState} from 'react';

// Import assets
import './delete-person.scss';
import {useTranslation} from "react-i18next";
import {DeletingIcon} from "../../../image";
import UserDeleteModal from "../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";

// Import Components

// Import Utils

function DeletePerson(props) {
    const {deleteAccount} = props;
    const [isVisibleModal, setVisibleModal] = useState(false);
    const {t} = useTranslation();

    const onConfirmButton = () => {
      setVisibleModal(false)
      deleteAccount();
    }
    return(
      <>
          <div className="delete-person">
              <div className="delete-person__title">{t('profile_delete_title')}</div>
              <div className="delete-person__content">
                  <div className="delete-person__text">{t('profile_delete_text')}</div>
                  <button onClick={() => setVisibleModal(true)} className="delete-person__button">
                      <DeletingIcon/>
                      {t('profile_delete_button')}
                  </button>
              </div>
          </div>
          {isVisibleModal &&
            <UserDeleteModal
              show={isVisibleModal}
              onClose={() => setVisibleModal(false)}
              onConfirm={onConfirmButton}
              isDeleteAccount={true}
            />}
      </>
      )
    ;
}

export default DeletePerson;
