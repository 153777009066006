import React from 'react';
import './Button.scss';

// variant - тип кнопки secondary | primary | ghost
const Button = (props) => {
	const {
		variant = 'primary',
		size = 'l',
		leftIcon,
		rightIcon,
		color,
		text,
		className
	} = props;

	const getProps = () => {
		let newProps = { ...props };
		delete newProps.size;
		delete newProps.variant;
		delete newProps.leftIcon;
		delete newProps.rightIcon;
		delete newProps.text;

		return newProps;
	};

	return (
		<button {...getProps()} className={`custom-button custom-button--${variant} custom-button--${size} custom-button--${variant}--${color} ${className || ''}`} >
			<>
				{leftIcon ?
					<div className={`custom-button--icon custom-button--${size}--icon`}>{leftIcon}</div>
					: null}
			</>
			<>
				{text ? <span className="custom-button--label">{text}</span> : null}
				<>
					{rightIcon ?
						<div className={`custom-button--icon custom-button--${size}--icon`}>{rightIcon}</div>
						: null}
				</>
			</>
		</button>
	);
};

export default Button;