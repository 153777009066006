import React from 'react';
import {Tooltip} from "antd";
import {InfoCheckIcon, InfoIcon} from "../../../image";
import {useTranslation} from "react-i18next";
import './TooltipSettingAutoChains.scss';

const TooltipSettingAutoChains = (props) => {
	const {data, onClick} = props;
	const {t} = useTranslation();

	if(String(data?.preset?.is_auto) === 'true'){
		return (
			<Tooltip placement="top" title={t('tooltip_auto_settings_chains')}>
				<InfoIcon className={"tooltip-settings-auto-chain"} onClick={() => onClick(data?.id)}/>
			</Tooltip>
		)
	}
	if(String(data?.preset?.is_auto) === 'false'){
		return (
			<Tooltip placement="top" title={t('tooltip_manual_settings_chains')}>
				<InfoCheckIcon className={"tooltip-settings-auto-chain"} onClick={() => onClick(data?.id)}/>
			</Tooltip>
		)
	}

	return (
				<Tooltip placement="top" title={t('tooltip_not_select_settings_chains')}>
					<InfoIcon className={"tooltip-settings-auto-chain--manual"}  onClick={() => onClick(data?.id)}/>
				</Tooltip>
	);
};

export default TooltipSettingAutoChains;