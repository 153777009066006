import {DateObject} from "react-multi-date-picker";
import {SORTED_ITEM_TYPES} from "../Sorted/helpers";


export const FILTER_SORT_PAGE_TYPES = {
	MANAGED_VIDEOS: 'manageVideos',
	PERSONS_ALL: 'personsAll',
	USER_VIDEOS_PAGE: 'userVideosPage',
	CHAIN_PAGE: 'chainPage',
	CHANNEL_PAGE: 'channelPage',
	KNOWLEDGE_DATA: 'knowledgeData'
}

export const ALL_FILTERS = {
	ALL: 'all',
	NAME: 'name',
	DATE: 'date',
	DURATION: 'duration',
	STATUS: 'status',
	OWNERS: 'owners',
	LINK: 'link',
	CHANNELS: 'channels',
	CHAINS: 'chains',
	PARTICIPANTS: 'participants',
	CONTENT_TYPES: 'contentTypes',
}

export const VIDEO_LINKS = {
	NON_PUBLIC: 'not_public',
	PUBlIC: 'public',
	PUBLIC_BY_DATE: 'public_time'
}

export const getValueFilters = (filtersOrSort, pageType) => {
	const result = {};
	const filters = filtersOrSort?.[pageType]?.filters;
	const sort = filtersOrSort?.[pageType]?.sort;
	const addFilter = (key, targetKey) => {
		if(filters?.[key]?.length) {
			result[targetKey] = filters[key];
		}
	};

	const addDateFilter = () => {
		if(filters?.[ALL_FILTERS.DATE]?.length) {
			const [start, end] = filters[ALL_FILTERS.DATE];
			result.date_start = new DateObject(start).format('YYYY-MM-DD');
			result.date_end = new DateObject(end || start).add(1, 'days').format('YYYY-MM-DD');
		}
	};

	if([
		FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS,
		FILTER_SORT_PAGE_TYPES.USER_VIDEOS_PAGE,
		FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA,
		FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE,
		FILTER_SORT_PAGE_TYPES.CHAIN_PAGE
	].includes(pageType)) {
		addFilter(ALL_FILTERS.LINK, 'share_link_types');
		addFilter(ALL_FILTERS.CHANNELS, 'channel_ids');
		addFilter(ALL_FILTERS.STATUS, 'statuses');
		addDateFilter();
		addFilter(ALL_FILTERS.NAME, 'description_like');
		addFilter(ALL_FILTERS.PARTICIPANTS, 'person_ids');
		addFilter(ALL_FILTERS.CHAINS, 'chain_ids');
	}

	const sortedBy = sort?.[SORTED_ITEM_TYPES.DATE_VIDEO] ||
		sort?.[SORTED_ITEM_TYPES.DESCRIPTION_VIDEO] ||
		sort?.[SORTED_ITEM_TYPES.DURATION_VIDEO] ||
		sort?.select;

	if(sortedBy) {
		result.sort_by = sortedBy;
	}

	return result;
};


const createFilter = (id, type, lng, ruLabel, enLabel) => ({
	id,
	type,
	label: lng === 'ru' ? ruLabel : enLabel,
});

export const getAllFilter = (pageType, lng) => {
	const commonFilters = [
		createFilter(0, ALL_FILTERS.NAME, lng, 'Имя', 'Name'),
		createFilter(1, ALL_FILTERS.DATE, lng, 'Дата', 'Date'),
		createFilter(2, ALL_FILTERS.LINK, lng, 'Ссылка', 'Link'),
		createFilter(3, ALL_FILTERS.PARTICIPANTS, lng, 'Участники', 'Participants'),
	];

	switch(pageType) {
		case FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS:
		case FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA:
			return [
				...commonFilters,
				createFilter(3, ALL_FILTERS.STATUS, lng, 'Статус', 'Status'),
				createFilter(6, ALL_FILTERS.CHANNELS, lng, 'Каналы', 'Channels'),
			];

		case FILTER_SORT_PAGE_TYPES.USER_VIDEOS_PAGE:
			return [
				createFilter(0, ALL_FILTERS.NAME, lng, 'Имя', 'Name'),
				createFilter(1, ALL_FILTERS.DATE, lng, 'Дата', 'Date'),
				createFilter(2, ALL_FILTERS.LINK, lng, 'Ссылка', 'Link'),
				createFilter(3, ALL_FILTERS.CHANNELS, lng, 'Каналы', 'Channels'),
				createFilter(4, ALL_FILTERS.CHAINS, lng, 'Цепочки', 'Chains'),
			];

		case FILTER_SORT_PAGE_TYPES.CHAIN_PAGE:
			return commonFilters;

		case FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE:
			return [
				...commonFilters,
				createFilter(4, ALL_FILTERS.CHAINS, lng, 'Цепочки', 'Chains'),
			];

		default:
			return [];
	}
};


export const getFilterLinksItems = (lng) => {
	return [
		{
			id: 0,
			type: VIDEO_LINKS.NON_PUBLIC,
			label: lng === 'ru' ? 'Непубличная' : 'Non-public'
		},
		{
			id: 1,
			type: VIDEO_LINKS.PUBlIC,
			label: lng === 'ru' ? 'Публичная' : 'Public'
		},
		{
			id: 2,
			type: VIDEO_LINKS.PUBLIC_BY_DATE,
			label: lng === 'ru' ? 'Публичная (по дате)' : 'Public (by date)'
		}
	]
}

export const VIDEO_STATUSES = {
	BOT_STARTED: 'bot-started',
	UPLOADING: 'uploading',
	CONVERTING: 'converting',
	CONVERSION_FAILED: 'conversion-failed',
	SENDING_TO_STORAGE: 'sending-to-storage ',
	SENDING_TO_STORAGE_FAILED: 'sending-to-storage_failed',
	PROCESSING: 'processing',
	PROCESSING_REJECTED: 'processing-rejected',
	PROCESSING_FAILED: 'processing-failed',
	PROCESSED: 'processed',
}

export const getFilterStatusesItems = (lng) => {
	return [
		{
			id: 0,
			type: VIDEO_STATUSES.BOT_STARTED,
			label: lng === 'ru' ? 'Бот запущен' : 'Bot started'
		},
		{
			id: 1,
			type: VIDEO_STATUSES.UPLOADING,
			label: lng === 'ru' ? 'Загрузка' : 'Uploading'
		},
		{
			id: 2,
			type: VIDEO_STATUSES.CONVERTING,
			label: lng === 'ru' ? 'Преобразование' : 'Converting'
		},
		{
			id: 3,
			type: VIDEO_STATUSES.CONVERSION_FAILED,
			label: lng === 'ru' ? 'Ошибка преобразования' : 'Conversion failed'
		},
		{
			id: 4,
			type: VIDEO_STATUSES.SENDING_TO_STORAGE,
			label: lng === 'ru' ? 'Отправка в хранилище' : 'Sending to storage'
		},
		{
			id: 5,
			type: VIDEO_STATUSES.SENDING_TO_STORAGE_FAILED,
			label: lng === 'ru' ? 'Ошибка отправки в хранилище' : 'Sending to storage failed'
		},
		{
			id: 6,
			type: VIDEO_STATUSES.PROCESSING,
			label: lng === 'ru' ? 'Обработка' : 'Processing'
		},
		{
			id: 7,
			type: VIDEO_STATUSES.PROCESSING_REJECTED,
			label: lng === 'ru' ? 'Обработка отклонена' : 'Processing rejected'
		},
		{
			id: 8,
			type: VIDEO_STATUSES.PROCESSING_FAILED,
			label: lng === 'ru' ? 'Ошибка обработки' : 'Processing failed'
		},
		{
			id: 9,
			type: VIDEO_STATUSES.PROCESSED,
			label: lng === 'ru' ? 'Обработан' : 'Processed'
		}
	]
}