
export const CONTENT_TYPE = {
	STARRED: 'is_starred',
	ACTIONS_ITEMS: 'is_action_item'
}

export const getContentTypeItems = (lng) => {
	return [
		{
			id: 0,
			type: CONTENT_TYPE.STARRED,
			label: lng === 'ru' ? 'Отмечен' : 'Starred'
		},
		{
			id: 1,
			type: CONTENT_TYPE.ACTIONS_ITEMS,
			label: lng === 'ru' ? 'Action Items' : 'Action Items'
		}
	]
}

export const getLabelTypes = (language) => {
	return language === 'en' ? {
		MENTIONED: 'Mentioned',
		ASSIGNED: 'Assigned'
	} : {
		MENTIONED: 'Упомянутые',
		ASSIGNED: 'Назначенные'
	}
}
