import {_urlChains, request} from "../api";
import {VIDEOS_CONSTS} from "../constants";
import {CHAINS_CONSTS} from "../constants/chainsConsts";

export const getChains = (data) => {
	const requestData = {
		url: `${_urlChains}`,
		method: "GET",
		data
	};
	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				const data = res && res.data;
				await dispatch({
					type: CHAINS_CONSTS.GET_CHAINS,
					payload: data
				})
			})
	};
};

export const getChain = (id,data) => {
	const requestData = {
		url: `${_urlChains}/${id}`,
		method: "GET",
		data
	};
	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				const data = res && res.data;
				await dispatch({
					type: CHAINS_CONSTS.GET_CHAIN,
					payload: data
				})
			})
	};
};

export const addChain = (data) => {
	const requestData = {
		url: `${_urlChains}`,
		method: "POST",
		data
	};
	return dispatch => {
		return request(requestData)
			.then((res) => {
				return res?.data
			})
	}
};

export const deleteChain = (id,data) => {
	const requestData = {
		url: `${_urlChains}/${id}`,
		method: "DELETE",
		data
	};
	return dispatch => {
		return request(requestData)
			.then((res) => {
				return res
			})
	}
};

export const updateChain = (id,data) => {
	const requestData = {
		url: `${_urlChains}/${id}`,
		method: "PATCH",
		data
	};

	return dispatch => {
		return request(requestData)
			.then((res) => {
				return res?.data
			})
	}
};

export const addVideosToChain = (chainId, data) => {
	let requestData = {
		url: `${_urlChains}/${chainId}`,
		method: "PUT",
		data: {
			videos_ids: data
		}
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				await dispatch({
					type: VIDEOS_CONSTS.ADD_CHAIN_TO_VIDEOS,
					payload: {chain_id: Number(chainId), data}
				})
			})
	};
};