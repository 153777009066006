import React, {useContext, useEffect, useState} from 'react';
import './ChannelForm.scss';
import {useTranslation} from "react-i18next";
import {PlusGreenIcon, SearchIcon} from "../../../image";
import {Form} from "react-bootstrap";
import UserInfoItem from "../../../shared/features/userInfoItem";
import {
	AddChannel, ClearChannelById,
	GetChannelById, GetChannels,
	GetUsersPersons,
	UpdateChannel, UpdateChannelUsers
} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {hasPermission} from "../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../utils/constants";
import {Button} from "../../Button";
import {ToastContext} from "../../Toast/ToastContext";


const initialState = {
	channelName: '',
	managerName: '',
	participantsName: '',
	managersIds: [],
	all_participants: [],
	type: false,
	all_users_are_participants: false
}

const ChannelForm = (props) => {
	const {channelId, closeIcon, onClose,setSelectedChannel} = props;
	const [channelInfo, setChannelInfo] = useState(initialState);
	const [haveChanges, setHaveChanges] = useState(new Map());
	const [showMangers, setShowManagers] = useState(false);
	const [participantsList, setParticipantsList] = useState([]);
	const [participants, setParticipants] = useState([]);
	const [checkedAll, setCheckedAll] = useState(channelInfo?.all_users_are_participants);
	const [selectedIdsManagers, setSelectedIdsManagers] = useState([]);
	const [usersList, setUserList] = useState([]);
	const usersListState = useSelector(state => state.users?.usersPersonsList?.results || []);
	const channelById = useSelector(state => state.channels?.channelById);
	const dispatch = useDispatch();
	const isCreate = !channelId && hasPermission(ROLE_ITEMS.CHANNELS_CREATE);
	const isCheckUpdateChannel = hasPermission(ROLE_ITEMS.CHANNELS_EDIT) || isCreate;
	const {t} = useTranslation();
	const {setToastInfo} = useContext(ToastContext);

	function getInputValue(e) {
		const {name, value} = e.target
		setChannelInfo({
			...channelInfo,
			[name]: value
		})

		const changes = new Map(haveChanges);
		// if (roleById?.[name] !== value) {
		//     changes.set(name, value)
		// } else {
		//     changes.delete(name)
		// }
		setHaveChanges(changes)
	}

	function toggleCheck() {
		setChannelInfo({
			...channelInfo,
			type: !channelInfo.type
		})
	}

	function getSearchValue(e) {
		const {name, value} = e.target
		setChannelInfo({
			...channelInfo,
			[name]: value
		})
	}

	function checkParticipant(e, item) {
		if(e?.target?.checked) {
			setParticipants([...participants, item?.user_id])
			setParticipantsList([...participantsList, item?.user_id])
		} else {
			setParticipants(participants.filter((id) => id !== item?.user_id))
			setParticipantsList(participantsList.filter((id) => id !== item?.user_id))
		}
	}

	function checkAll(e) {
		if(e.target.checked) {
			setParticipants(searchParticipants().map((item)=>item?.user_id))
		} else {
			setParticipants([])
		}
		setCheckedAll(e.target.checked)
	}

	const getChannelInfo = async (id) => {
		try {
			if(!id) {
				setChannelInfo({
					...initialState,
					all_participants: usersListState || [],
				});
				setParticipants([]);
				setParticipantsList([]);
				setUserList(usersListState)
			} else {
				const channel = await dispatch(GetChannelById(id));
				const part = (channel?.participants || []).map((item) => item?.user_id)
				setUserList(usersListState.sort((item)=>part.includes(item?.user_id) ? -1 : 1))
				setParticipants((channel?.participants || []).map((item) => item?.user_id));
				setParticipantsList((channel?.participants || []).map((item) => item?.user_id));
				setChannelInfo({
					...channelInfo,
					channelName: channel.name,
					managersIds: channel?.managers || [],
					type: channel?.all_users_are_participants ? 'all' : false,
					participants: channel?.participants || [],
					all_participants: usersListState || [],
				});
			}
		} catch (error) {
			console.error(error)
		}
	}

	const searchParticipants = () => {
		const managerIds = (channelInfo?.managersIds || []).map((item) => item?.user_id)
		return usersList
			.filter((item) => !managerIds.includes(item.user_id))
			.filter((user) => {
				if(channelInfo?.participantsName) {
					return (user?.last_name || '').toLowerCase().includes(channelInfo.participantsName.toLowerCase())
						|| (user?.first_name || '').toLowerCase().includes(channelInfo.participantsName.toLowerCase())
				}
				return user;
			})
	}

	const createChannel = async () => {
		try {
			const participantsList = participants.map((item) => {
				return {
					user_id: item,
					is_bound: true
				}
			})
			const response = await dispatch(AddChannel({name: channelInfo?.channelName}));
			if(setSelectedChannel) setSelectedChannel(response?.data?.id);
			await dispatch(UpdateChannel(response?.data?.id, {
				name: channelInfo?.channelName,
				all_users_are_participants: channelInfo.type
			}));
			if(!channelInfo.type){
				await dispatch(UpdateChannelUsers(response?.data?.id, {participants: participantsList}));
			}
			await dispatch(GetChannels);
			getChannelInfo(response?.data?.id);
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("create_channel_success")
			});
			if(onClose) onClose();
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("create_channel_error")
			});
		}
	}

	const updateChannel = async () => {
		try {
			let results = [];
			results = (channelById?.participants || []).map((item) => {
				if(participants.includes(item?.user_id)) {
					return {
						user_id: item?.user_id,
						is_bound: true
					}
				} else {
					return {
						user_id: item?.user_id,
						is_bound: false
					}
				}
			});
			participantsList.forEach((id) => {
				const item = results.find((user) => user?.user_id === id);
				if(!item) {
					results.push({
						user_id: id,
						is_bound: true
					})
				}
			})

			await dispatch(UpdateChannelUsers(channelId, {participants: results}));
			await dispatch(UpdateChannel(channelId, {
				name: channelInfo?.channelName,
				all_users_are_participants: channelInfo.type
			}));
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("update_channel_success")
			});
			if(onClose) onClose();
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("update_channel_error")
			});
		}
	}

	const saveResult = async () => {
		try {
			if(isCreate) {
				createChannel();
			} else {
				updateChannel();
			}
		} catch (e) {

		}
		// onClose()
	}

	useEffect(() => {
		getChannelInfo(channelId);
	}, [channelId])

	useEffect(() => {
		dispatch(GetUsersPersons({
			limit: 300,
			filter: 'users'
		}));

		return () => {
			dispatch(ClearChannelById())
		}
	}, [])

	return (
		<>
			{channelById?.is_private ? <span className="info-text">{t('private_channel_info_text')}</span> :
				<div className="channel-form">
					<div className="channel-form__header">
						{isCheckUpdateChannel
							? <span
								className="channel-form--title">{isCreate ? t('channel_create') : t('channel_editing')}</span>
							: <span className="channel-form--sub-title">{channelInfo.channelName}</span>
						}
						<>{closeIcon}</>
					</div>
					{isCheckUpdateChannel
						?
						<div className={`channel-form__input-wrap ${isCreate ? 'create' : ''}`}>
							<input type="text"
							       value={channelInfo.channelName}
							       autoComplete={'off'}
							       name={'channelName'}
							       onChange={getInputValue}
							       placeholder={'Name'}
							/>
							{!isCreate &&
								<span className="count">{channelById?.participants?.length} {t('participants')}</span>}
						</div>
						: null}
					<div className="channel-form--subtitle">{t('channel_managers')}</div>
					<div className="channel-form__managers managers">
						{isCheckUpdateChannel ?
							<>
								{
									!showMangers ? <button className="managers--add">
											<PlusGreenIcon/>
											<span className="managers--add--sub-title">{t('add_manager')}</span>
										</button>
										: <div className="managers__search-block">
											<input type="text"
											       value={channelInfo.managerName}
											       autoComplete={'off'}
											       name={'managerName'}
											       onChange={getInputValue}
											       placeholder={'Name'}
											/>

											<div className="managers__buttons">
												<button className="managers__buttons--close-btn"
												        onClick={() => {
													        setShowManagers(false)
												        }}>
													{t('button_cancel')}
												</button>

												<button
													className={`managers__buttons--save-btn ${!channelInfo?.managerName ? 'disabled' : ''}`}
													disabled={!channelInfo?.managerName}
													// onClick={saveChanges}
												>
													{t('button_save')}
												</button>

											</div>
										</div>
								}
							</>
							: null}
						{channelInfo?.managersIds?.length ?
							<Form
								style={{
									paddingTop: isCheckUpdateChannel ? '16px' : '0',
									margin: 0
								}}
								className={`managers__list custom-scroll`}>
								{
									(showMangers ? usersList : channelInfo?.managersIds)?.map((item, index) => {
										return (
											<div
												className={`managers__list--item`}
												key={index}
											>
												{isCheckUpdateChannel ?
													<Form.Check
														type={'checkbox'}
														onChange={() => setSelectedIdsManagers([...selectedIdsManagers, item?.user_id])}
														checked={true}
														disabled={true}
													/>
													: null}
												<UserInfoItem data={item}/>
											</div>
										)
									})
								}
							</Form>
							: null
						}
					</div>
					<div className="channel-form--sub-title">{t('channel_members')}</div>
					{hasPermission(ROLE_ITEMS.CHANNELS_EDIT) &&
						<Form className="check-types">
							{/*<Form.Check*/}
							{/*    inline*/}
							{/*    name="type"*/}
							{/*    label={'Выбрать пользователей вручную'}*/}
							{/*    type={'radio'}*/}
							{/*    checked={!channelInfo.type}*/}
							{/*    onChange={(e) => toggleCheck({*/}
							{/*        name: 'participant',*/}
							{/*        value: e?.target?.checked,*/}
							{/*    })}*/}
							{/*/>*/}
							<Radio
								label={t('all_users_company')}
								checked={channelInfo.type}
								onChange={(e) => toggleCheck({
									name: 'all',
									value: e?.target?.checked,
								})}
							/>
							<Radio label={t('select_users_manually')}
							       checked={!channelInfo.type}
							       onChange={(e) => toggleCheck({
								       name: 'participant',
								       value: e?.target?.checked,
							       })}/>
						</Form>}

					{!channelInfo?.type ? <>
							{isCheckUpdateChannel ?
								<>
									<div className="participants">
										<SearchIcon/>
										<input type="text"
										       value={channelInfo.participantsName}
										       autoComplete={'off'}
										       name={'participantsName'}
										       onChange={getSearchValue}
										       placeholder={t('input_search')}
										/>
									</div>

									<Form className="check-icon">
										<CheckBox
											checked={checkedAll}
											onChange={checkAll}
										/>
										{t('all_users_from_list')}
										<div className="count">{channelInfo.all_participants?.length || 0} {t('users')}</div>
									</Form>
								</>
								: null}
							<Form
								style={{
									paddingTop: isCheckUpdateChannel ? '16px' : '0',
									margin: 0
								}}
								className="managers__list custom-scroll">
								{
									searchParticipants().map((item, index) => {
										return <div className={`managers__list--item`} key={index}>
											{isCheckUpdateChannel ?
												<CheckBox
													checked={participants.includes(item?.user_id)}
													onChange={(e) => checkParticipant(e, item)}
												/>
												: null}
											<UserInfoItem data={item}/>
										</div>
									})
								}
							</Form>
						</>
						: <div className={`managers__list custom-scroll all`}>
							{
								usersList.map((item, index) => {
									return <div className={`managers__list--item`} key={index}>
										<UserInfoItem data={item}/>
									</div>
								})
							}

						</div>
					}
					{isCheckUpdateChannel ?
						<div className="channel-form__footer">
							<Button
								onClick={() => {
									if(onClose) {
										onClose()
									}
								}}
								text={t('button_cancel')}
								variant="secondary"
							/>
							<Button
								text={t('button_save')}
								onClick={saveResult}
								variant="primary"
							/>
						</div>
						: null}
				</div>
			}
		</>
	);
};

function Radio({label, checked, onChange}) {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(checked)
	}, [checked])

	return <Form.Check
		inline
		name="type"
		label={label}
		type={'radio'}
		checked={isChecked}
		onChange={onChange}
	/>
}


function CheckBox({checked, onChange}) {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(checked)
	}, [checked])

	return <Form.Check
		type={'checkbox'}
		checked={isChecked}
		onChange={onChange}
	/>
}


export default ChannelForm;