import React from 'react';
import './pagination.scss';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from "react-bootstrap/PageItem";
import { ArrowLeftIcon } from "../../../image";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

function CustomPagination(props) {
  const { count, activePage, changePage, listLength } = props;
  const { t } = useTranslation();
  const length = Math.ceil(count / listLength);

  const getPageOptions = () => {
    const arr = [];
    for (let i = 1; i <= length; i++) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };

  const onChangePage = (page) => {
    changePage(page);
  };

  const renderPaginationItems = () => {
    const pages = [];

    if (length <= 7) {
      // Show all pages if the total number of pages is less than or equal to 5
      for (let i = 1; i <= length; i++) {
        pages.push(
          <PageItem key={i} active={i === activePage} onClick={() => changePage(i)}>
            {i}
          </PageItem>
        );
      }
    } else {
      // Handle pagination for more than 5 pages
      if (activePage <= 5) {
        // Display the first 5 pages if active page is less than or equal to 5
        for (let i = 1; i <= 5; i++) {
          pages.push(
            <PageItem key={i} active={i === activePage} onClick={() => changePage(i)}>
              {i}
            </PageItem>
          );
        }
        pages.push(<Pagination.Ellipsis key="ellipsis-end" className="pagination-ellipsis"/>);
        pages.push(
          <PageItem key={length} active={length === activePage} onClick={() => changePage(length)}>
            {length}
          </PageItem>
        );
      } else if (activePage > 5 && activePage <= length - 5) {
        // Middle case: show ellipsis, 1, ellipsis, 3 center pages, ellipsis, last page
        pages.push(
          <PageItem key={1} active={1 === activePage} onClick={() => changePage(1)}>
            1
          </PageItem>
        );
        pages.push(<Pagination.Ellipsis key="ellipsis-start" className="pagination-ellipsis" />);

        for (let i = activePage - 1; i <= activePage + 1; i++) {
          pages.push(
            <PageItem key={i} active={i === activePage} onClick={() => changePage(i)}>
              {i}
            </PageItem>
          );
        }

        pages.push(<Pagination.Ellipsis key="ellipsis-end" className="pagination-ellipsis"/>);
        pages.push(
          <PageItem key={length} active={length === activePage} onClick={() => changePage(length)}>
            {length}
          </PageItem>
        );
      } else {
        // Last 5 pages case: show ellipsis and last 5 pages
        pages.push(
          <PageItem key={1} active={1 === activePage} onClick={() => changePage(1)}>
            1
          </PageItem>
        );
        pages.push(<Pagination.Ellipsis key="ellipsis-start" className="pagination-ellipsis"/>);

        for (let i = length - 4; i <= length; i++) {
          pages.push(
            <PageItem key={i} active={i === activePage} onClick={() => changePage(i)}>
              {i}
            </PageItem>
          );
        }
      }
    }

    return pages;
  };

  return (
    <Pagination>
      {activePage > 1 && (
        <Pagination.First onClick={() => changePage(activePage - 1)}>
          <ArrowLeftIcon />
        </Pagination.First>
      )}

      {renderPaginationItems()}

      {activePage < length && (
        <Pagination.Next onClick={() => onChangePage(activePage + 1)}>
          <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />
        </Pagination.Next>
      )}

      <div className="pagination-info">
        <span className="pagination-info--text">{t('page')}</span>
        <Select
          popupClassName={"page-select"}
          suffixIcon={<ArrowLeftIcon />}
          style={{ width: '64px' }}
          options={getPageOptions()}
          value={getPageOptions()[activePage - 1].value}
          onChange={(value) => {
            changePage(value);
          }}
        />
        <span>{t('of')} {length}</span>
      </div>
    </Pagination>
  );
}

export default CustomPagination;
