import React, {useContext, useEffect, useState} from 'react';
import {QRCodeSVG} from "qrcode.react";
import {Text} from "../../../../modules";
import './TelegramBlock.scss';
import {useTranslation} from "react-i18next";
import {Button} from "../../../../modules/Button";
import {SuccessModalContext} from "../../helpers";

const img = "data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_13783_149884)'%3E%3Cpath d='M12 0.5C8.81812 0.5 5.76375 1.76506 3.51562 4.01469C1.2652 6.26522 0.000643966 9.31734 0 12.5C0 15.6813 1.26562 18.7357 3.51562 20.9853C5.76375 23.2349 8.81812 24.5 12 24.5C15.1819 24.5 18.2362 23.2349 20.4844 20.9853C22.7344 18.7357 24 15.6813 24 12.5C24 9.31869 22.7344 6.26431 20.4844 4.01469C18.2362 1.76506 15.1819 0.5 12 0.5Z' fill='url(%23paint0_linear_13783_149884)'/%3E%3Cpath d='M5.43087 12.376C8.92962 10.852 11.2621 9.84723 12.4284 9.36173C15.7621 7.97554 16.454 7.73479 16.9059 7.72663C17.0052 7.72504 17.2265 7.7496 17.3709 7.86632C17.4909 7.96476 17.5246 8.09788 17.5415 8.19135C17.5565 8.28473 17.5771 8.49754 17.5602 8.66367C17.3802 10.5612 16.5984 15.1658 16.2009 17.2911C16.034 18.1904 15.7021 18.4919 15.3815 18.5213C14.684 18.5854 14.1552 18.0608 13.4802 17.6185C12.4246 16.926 11.8284 16.4952 10.8027 15.8196C9.61774 15.0389 10.3865 14.6097 11.0615 13.9084C11.2377 13.7249 14.309 10.932 14.3671 10.6787C14.3746 10.647 14.3821 10.5289 14.3109 10.4667C14.2415 10.4042 14.1384 10.4256 14.0634 10.4425C13.9565 10.4665 12.2709 11.5817 9.00087 13.788C8.52274 14.1169 8.08962 14.2772 7.69962 14.2688C7.27212 14.2596 6.44712 14.0265 5.83399 13.8274C5.08399 13.5831 4.48587 13.4539 4.53837 13.039C4.56462 12.823 4.86274 12.6019 5.43087 12.376Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_13783_149884' x1='1200' y1='0.5' x2='1200' y2='2400.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232AABEE'/%3E%3Cstop offset='1' stop-color='%23229ED9'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_13783_149884'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A";
const Info = {
		description: {
			ru: 'Подключите Telegram, чтобы получить быстрый доступ к чат-боту, который позволит вам мгновенно получать информацию от помощника с искусственным интеллектом.',
			en: 'Connect Telegram to gain quick access to the chatbot, allowing you to instantly retrieve information from the AI assistant.'
		},
		description_btn: {
			ru: 'Чтобы подключиться к Telegram, отсканируйте QR-код или перейдите по ссылке',
			en: 'To connect to Telegram, scan the QR code or follow the link'
		}
}

const TelegramBlock = () => {
	const {i18n} = useTranslation();
	const {telegramLink} = useContext(SuccessModalContext);

	const handleClickLinkBtn = () => {
		window.open(telegramLink, '_blank')
	}

	return (
		<div className="telegram-block">
			<Text font="medium" size="f18" className="calendars-block--title">Telegram</Text>
			<div className="calendars-block__top">
				<Text font="liteMedium" size="f16" color="#9898A6">{Info.description[i18n.language]}</Text>
			</div>
			<div className="telegram-block__body">
				<Text font="liteMedium" size="f16" color="#9898A6">{Info.description_btn[i18n.language]}</Text>
				<QRCodeSVG
					value={telegramLink || ''}
					size={186}
					bgColor={"#F2F2F2"}
					fgColor={"#000000"}
					level={"H"}
					imageSettings={{
						src: img,
						x: undefined,
						y: undefined,
						height: 36,
						width: 36,
						opacity: 1,
						excavate: true,
					}}
				/>
				<Button
					text={'Connect by link'}
					variant="secondary"
					size={'s'}
					onClick={handleClickLinkBtn}
				/>
			</div>
		</div>
	);
};

export default TelegramBlock;