import React from 'react';
import {connect} from "react-redux";
import './channels-modal.scss';
import {Modal} from "react-bootstrap";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {CloseIcon} from "../../../image";
import {
    GetChannelById,
    GetChannels
} from "../../../redux/actions";
import ChannelForm from "../../../modules/Channel/ChannelForm/ChannelForm";

function ChannelsModal(props) {
    const {onHide, channelById} = props;

    function onClose() {
        if(onHide) onHide();
        if(channelById) props.GetChannelById(channelById?.id);
        props.GetChannels();
    }

    return <Modal
        show={true}
        onHide={onClose}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'channels-modal'}
        centered
    >
        <ChannelForm
            channelId={channelById?.id}
            onClose={onClose}
            closeIcon={<CloseIcon onClick={onClose}/>}
        />

    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['searchedUsers'])
};

const mapDispatchToProps = {
    GetChannels,
    GetChannelById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsModal);
