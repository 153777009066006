import {AUTH_CONSTS} from "../constants";

export const initialState = {
    token: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CONSTS.LOG_IN:
            return {
                ...state,
                token: action.payload.token
            };
        case AUTH_CONSTS.LOG_OUT:
            console.log('LOG_OUT');
            return initialState;
        default:
            return state;
    }
}
