// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './notifications.scss';
import {FilterCheckIcon, NotificationIcon, VideoFolderIcon} from "../../../image";

// Import Components
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetNotifications, GetNotificationsCount, SeeAllNotification, UpdateNotification} from "../../../redux/actions";
import {changeTimeFormat} from "../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import InfoTooltip from "../../../pages/Dashboard/containers/Profile/tooltip";
import {history} from "../../../utils/history";

function Notifications(props) {
    const {notificationsList, notificationsCount} = props;
    const {t, i18n} = useTranslation();
    // const socketRef = useRef(null);
    const [all, setAll] = useState(false);
    // const [unreadList, setUnreadList] = useState([]);
    const [list, setList] = useState([]);

    // useEffect(()=>{
    //     socketRef.current = io(`${HOST_NAME}/user_notifications`)
    //     socketRef?.current?.on('updated', (message) => {
    //         console.log('message', message);
    //         if (message?.cancelled_by_server) {
    //
    //         }
    //     /*    history.push({
    //             pathname: `/dashboard/videos/video/${message?.video_id}`,
    //             state: {
    //                 openEdit: true,
    //                 routs: [
    //                     {
    //                         name: t('button_record')
    //                     },
    //                 ]
    //             }
    //         })*/
    //         // Handle the received message here
    //     });
    // }, [])

    useEffect(() => {
        props.GetNotifications()
        props.GetNotificationsCount()
    }, [])

    useEffect(() => {
        // notificationsList?.length && setUnreadList(notificationsList)
        setList(notificationsList)
    }, [notificationsList])


    function toggleFilter(checked) {
        setAll(checked)
        !!notificationsList?.length && setList(checked ? notificationsList : notificationsList?.filter(i => !!i.is_new))
        props.GetNotificationsCount()
    }

    async function seeAll() {
        // !!notificationsList?.length && notificationsList.forEach(item => {
        //     item?.is_new && props.UpdateNotification(item?.id, {
        //         is_new: false
        //     })
        // })
        // await props.GetNotificationsCount()
        props.SeeAllNotification({
                    is_new: false
                })
    }

    function navigateTo(notification) {
        let path = ``
        let routs = []

        if (notification?.video_id) {
            path = `/dashboard/videos/video/${notification?.video_id}`
            routs = [
                {
                    name: t('notifications')
                }
            ]
        } else if (notification?.channel_id) {
            path = `channels/${notification?.channel_id}`

        }

        path && history.push({
            pathname: path,
            state: {
                routs: routs
            }
        })
        props.UpdateNotification(notification?.id, {
            is_new: false
        })
    }

    return <DropdownMenu button={<button className="notification_btn">
        <NotificationIcon/>
        {!!notificationsCount && <span className="has_notification"/>}
    </button>}
                         className={'notification-menu'}
                         contentWidth={'364px'}
    >
        <div className="header">{t('notifications')}</div>
        <div className="filters-wrapper">
            <div className="filters">
                <div className={`filters-btn ${!all ? 'active' : ''}`}
                     onClick={() => toggleFilter(false)}>
                    {t('not_read')}
                    <span className="count">({notificationsCount})</span>
                </div>
                <div className={`filters-btn ${all ? 'active' : ''}`}
                     onClick={() => toggleFilter(true)}>
                    {t('all')} <span className="count">({notificationsList?.length})</span>
                </div>
            </div>
            <InfoTooltip title={t('mark_every_as_read')}
                         placement={'left'}
                         elem={<FilterCheckIcon onClick={seeAll}/>}/>
        </div>
        <div
          className="notification_list"
          onWheel={(e)=>e.stopPropagation()}
        >
            {
                !!list?.length && list?.map(item => {
                    return <div key={item.id}
                                className={`notification ${item?.is_new ? 'new' : ''}`}
                                onClick={() => navigateTo(item)}
                    >
                        <div className="notification-img">
                            <VideoFolderIcon/>
                            {/*<div className="mark-icon"><FillStarIcon/></div>*/}
                        </div>
                        <div className="notification-info">
                            <div className="name">{item?.description}</div>
                            <div className="date">{changeTimeFormat(item?.creation_time, i18n.language)}</div>

                            {
                                item?.is_new && <InfoTooltip title={t('mark_as_read')}
                                                             placement={'left'}
                                                             className={'new-mark-wrap'}
                                                             cb={(e) => {
                                                                 e.stopPropagation()
                                                                 props.UpdateNotification(item?.id, {
                                                                     is_new: false
                                                                 })
                                                             }}
                                                             elem={<span className="new-mark"/>}
                                />
                            }
                        </div>
                    </div>
                })
            }

        </div>
    </DropdownMenu>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['notificationsList', 'notificationsCount'])
};

const mapDispatchToProps = {
    GetNotifications,
    UpdateNotification,
    GetNotificationsCount,
    SeeAllNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
