import {profileErrorMessages} from "../../../../../../utils/constants";
import isEmail from "validator/es/lib/isEmail";
import validator from "validator";

export const validateUserForm = (data) => {
    const {first_name, last_name, person_info, login, password} = data
    const {email, linkedin, facebook, phone_number} = person_info;

    let errorList = null;

    if ((first_name || '').length < 2) {
        errorList = {
            ...errorList,
            first_name: profileErrorMessages.first_name
        }
    }

    if ((last_name || '').length < 2) {
        errorList = {
            ...errorList,
            last_name: profileErrorMessages.last_name
        }
    }

    if (!login) {
        errorList = {
            ...errorList,
            login: profileErrorMessages.required
        }
    }else if(!isEmail(login)){
        errorList = {
            ...errorList,
            login: profileErrorMessages?.email
        }
    }

    if ((password || '').length < 8) {
        errorList = {
            ...errorList,
            password: profileErrorMessages.required
        }
    }

    !!email?.length && email.forEach((item, index) => {
        if (!isEmail(item)) {
            errorList = {
                ...errorList,
                email: email?.map((mail, i) => {
                    return i === index ? profileErrorMessages?.email : undefined
                })
            }
        }
    })

    !!linkedin?.length && linkedin.forEach((item, index) => {
        const isValidLinkedinURL = validator.isURL(item, {
            host_whitelist: ['linkedin.com', 'www.linkedin.com']
        })
        if (!isValidLinkedinURL) {
            errorList = {
                ...errorList,
                linkedin: facebook?.map((mail, i) => {
                    return i === index ? profileErrorMessages.linkedin : undefined
                })
            }
        }
    })
    !!facebook?.length && facebook.forEach((item, index) => {
        const isValidFacebookURL = validator.isURL(item, {
            host_whitelist: ['facebook.com', 'www.facebook.com']
        })
        if (!isValidFacebookURL) {
            errorList = {
                ...errorList,
                facebook: facebook?.map((mail, i) => {
                    return i === index ? profileErrorMessages.facebook : undefined
                })
            }
        }
    })
    !!phone_number?.length && phone_number.forEach((item, index) => {
        if (item?.length < 11) {
            errorList = {
                ...errorList,
                phone_number: phone_number?.map((mail, i) => {
                    return i === index ? profileErrorMessages.phone_number : undefined
                })
            }
        }
    })


    return errorList;
}

export const generateData = (userInfo, personInfo, contactItems, userRole) => {
    let obj ={
    ...userInfo,
      person_info: {
    ...personInfo,
          email: contactItems.email.filter((item)=>Boolean(item)),
          phone_number: contactItems.phone_number.filter((item)=>Boolean(item)),
          linkedin: contactItems.linkedin.filter((item)=>Boolean(item)),
          facebook: contactItems.facebook.filter((item)=>Boolean(item)),
    },
        role_ids: [Number(userRole?.id)]
    }

    if(!userRole){
        delete obj.role_ids
    }

    return obj
}