import {_urlSignatures, request} from "../api";
import {PROFILE_CONSTS} from "../constants";

export const SearchSignaturesService = (data) => {
	let requestData = {
		url: `${_urlSignatures}/search/fuzzy`,
		method: "POST",
		data: JSON.stringify(data),
	};

	return request(requestData)
};
export const SaveSignatureService = (data) => {
	let requestData = {
		url: `${_urlSignatures}`,
		method: "POST",
		data: JSON.stringify(data),
	};

	return request(requestData)
};

export const GetUserSignaturesService = () => {
	let requestData = {
		url: `${_urlSignatures}`,
		method: "GET",
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				await dispatch({
					type: PROFILE_CONSTS.SET_USER_SIGNATURES,
					payload: res?.data
				})
			})
	};
};

export const DeleteUserSignaturesService = (data) => {
	let requestData = {
		url: `${_urlSignatures}`,
		method: "DELETE",
		data: JSON.stringify(data),
	};

	return dispatch => {
		return request(requestData)
			.then(async (res) => {
				await dispatch({
					type: PROFILE_CONSTS.DELETE_USER_SIGNATURES,
					payload: res?.data
				})
			})
	};
};