import React, {useEffect, useRef, useState} from 'react';
import './FilterName.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {DeleteFilterAction, SetFilterAction} from "../../../../redux/actions";
import {ALL_FILTERS, getValueFilters} from "../../helpers";


const FilterName = ({onGetVideos, pageType}) => {
	const selectedName = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.NAME] || '');
	const {t, i18n} = useTranslation();
	const [open, setOpen] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [searchText, setSearchText] = useState(selectedName);
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, pageType);
	const dispatch = useDispatch();
	const inputRef = useRef(null);

	const getLabel = () => {
		let text = '';

		if(selectedName) {
			text = selectedName
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);

		if(newOpen) {
			setSearchText(selectedName)
		}
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = (data) => {
		dispatch(SetFilterAction({
			pageType,
			filterType: ALL_FILTERS.NAME,
			value: data
		}))
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		setValueFilter(selectedName);
	}
	const getVideos = async (type) => {
		let params = {
			...allFiltersValue,
			description_like: searchText,
		};
		if(!searchText || type === 'delete') delete params?.description_like;

		await onGetVideos(params)
	}

	const handleApplyClick = async () => {
		setOpenTooltip(false);

		try {
			setOpen(false);
			setValueFilter(searchText);
			await getVideos()
		} catch (error) {
			console.log(error)
		}
	}

	const deleteFilter = async () => {
		dispatch(DeleteFilterAction({
			pageType,
			filterType: ALL_FILTERS.NAME
		}));
		await getVideos('delete')
	}

	const RenderTooltip = (props) => {
		if(selectedName.length) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={selectedName}
				>
					<span className="filter-name__label--text">{`${t('name')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return (
			<span className="filter-name__label--text">{`${t('name')}: ${getLabel()}`}</span>
		)
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleApplyClick()
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [open]);

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-name'
			onOpenChange={handleOpenChange}
			destroyTooltipOnHide={true}
			open={open}
			content={
				<div className="filter-name__container" onKeyDown={handleKeyDown}>
					<div className="filter-name__search" tabIndex={0}>
						<div className="filter-name__search--input-wrap">
							<SearchIcon/>
							<input
								value={searchText}
								onChange={(e) => setSearchText(e?.target?.value || '')}
								type="text" placeholder={t('input_search')}
								ref={inputRef}
							/>
						</div>
					</div>
					<div className="filter-name__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-name__label ${selectedName.length ? 'selected' : ''}`}>
				<div className={`filter-name__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedName ?
						<ArrowLeftIcon className={`filter-name__label--arrow ${open ? 'open' : ''}`}/>
						: null
					}
				</div>
				{selectedName ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-name__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterName;