import React, {useEffect, useState} from 'react';
import {LayoutContainer} from "../../modules/Layout";
import {useDispatch, useSelector} from "react-redux";
import NoteBlock from "./components/NoteBlock/NoteBlock";
import {
	GetNotesService, resetFiltersNotesListService,
	updateApiStatusNotesListService,
	updateFiltersNotesListService
} from "../../redux/actions/notesActions";
import {ApiStatus} from "../../utils/constants";
import {Loader} from "../../shared/features/Loader";
import './NotesPage.scss';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import CustomPagination from "../../shared/features/Pagination";
import {scrollTop} from "../../utils/helperFunctions";
import FiltersList from "./components/FiltersList/FiltersList";
import {Link, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {history} from "../../utils/history";
import {ROUTERS_MAP} from "../../utils/routes";
import UserInfoModal from "../Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {Text} from "../../modules";

const NotesPage = () => {
	const isFullWidth = useSelector(state => state.utils.isFullWidth);
	const notesListInfo = useSelector(state => state.notes.notesList);
	const notesList = notesListInfo.data?.results || [];
	const notesListCount = notesListInfo.data?.count || 0;
	const isLoading = notesListInfo.apiStatus !== ApiStatus.SUCCESS;
	const dispatch = useDispatch();
	const [activePage, setActivePage] = useState(1);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const channelsIds = urlParams.get('channelsIds');
	const contentTypeQuery = urlParams.get('contentType');
	const personIdQuery = urlParams.get('personId');
	const filters = useSelector(state => state.notes.notesList.filters);
	const {t} = useTranslation();

	useEffect(() => {
		const obj = {
			...filters
		}
		if(channelsIds) {
			obj.channels_ids = channelsIds.split(' ').map(id => Number(id));
		}
		if(contentTypeQuery) {
			obj[contentTypeQuery] = Boolean(contentTypeQuery);
		}
		if(personIdQuery && personIdQuery !== 'null') {
			obj.mentioned_person_id = Number(personIdQuery)
		}
		dispatch(updateFiltersNotesListService({
			...obj,
		}));
	}, [channelsIds]);

	const getNotes = async (page, filters) => {
		try {
			await dispatch(updateApiStatusNotesListService(ApiStatus.LOADING));
			await dispatch(GetNotesService({
				limit: 20,
				offset: (page - 1) * 20,
				...filters,
			}));
			await dispatch(updateApiStatusNotesListService(ApiStatus.SUCCESS));
		} catch (e) {
			await dispatch(updateApiStatusNotesListService(ApiStatus.ERROR));
		}
	}

	const changePage = async (number) => {
		scrollTop();
		setActivePage(number)
		getNotes(number, filters);
	};

	const [showModal, setShowModal] = useState(false)
	const isAuth = useSelector(state => Boolean(state.auth?.token));
	const userProfile = useSelector(state => state.profile.userProfile);
	const [currentUser, setCurrentUser] = useState(null)

	function toggleUserModal(id) {
		if(isAuth) {
			setShowModal(!showModal);
			if(userProfile?.attached_person?.id === Number(id) || userProfile?.id === Number(id)) {
				history.push(ROUTERS_MAP.PROFILE);
			} else {
				setCurrentUser({person_id: Number(id)});
			}
		}
	}

	useEffect(() => {
		if(notesListInfo?.data?.offset === 0) {
			setActivePage(1)
		}
	},[notesListInfo.data]);

	useEffect(() => {
		const obj = {
			...filters
		}
		if(channelsIds) {
			obj.channels_ids = channelsIds.split(' ').map(id => Number(id));
		}
		if(contentTypeQuery) {
			obj[contentTypeQuery] = Boolean(contentTypeQuery);
		}
		if(personIdQuery && personIdQuery !== 'null') {
			obj.mentioned_person_id = Number(personIdQuery)
		}
		getNotes(1,obj);

		return () => {
			dispatch(resetFiltersNotesListService());
		}
	}, [personIdQuery]);


	return (
		<LayoutContainer>
			<div className={`main-container channels-wrap ${isFullWidth ? '' : 'full'}`}>
				<div className="notes">
					<Breadcrumb>
						{
							(location?.state?.routs || []).map((item, index) => {
								return (
									<Breadcrumb.Item
										key={item?.name}
										active={item?.active}
										className={item?.active ? 'breadcrumb_name' : ''}
									>
										{item?.active ?
											<div className="channels-page__name">
												{item?.name}
											</div>
											:
											<Link
												to={{
													pathname: item?.path,
													state: {
														routs: location?.state?.routs.slice(0, index + 1)
													}
												}}
											>
												{item?.name}
											</Link>
										}
									</Breadcrumb.Item>)
							})
						}
						<Breadcrumb.Item active>
							<span>{t('notes')}</span>
						</Breadcrumb.Item>
					</Breadcrumb>
					<FiltersList/>
					{isLoading ? <div className="notes__loader"><Loader size={50}/></div> :
						<>
						{notesList.length ?
							<>
								<ResponsiveMasonry
									columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1200: 4}}
									className="notes_container"
									gutter={"20px"}
								>
									<Masonry gutter={"20px"}>
										{/* array of JSX items */}
										{notesList.map(note => <NoteBlock toggleUserModal={toggleUserModal} key={note.id} note={note}/>)}
									</Masonry>
								</ResponsiveMasonry>
								{notesListCount > 20 &&
									<CustomPagination
										count={notesListCount}
										activePage={activePage}
										listLength={20}
										changePage={changePage}
									/>
								}
							</>
							:
							<div className="empty-info">
								<Text font="liteBold" size="f16" color="#9898A6">{t('empty_list_videos_text_1')}</Text>
								<Text font="liteBold" size="f16" color="#9898A6">{t('empty_list_videos_text_2')}</Text>
							</div>
						}
						</>

					}
				</div>
				{showModal && <UserInfoModal show={showModal}
				                             closeModal={toggleUserModal}
				                             user={currentUser}
				/>}
			</div>
		</LayoutContainer>
	);
};

export default NotesPage;