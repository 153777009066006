import {CHANNEL_CONSTS} from "../constants";
import {_urlChannels, request} from "../api";


export const GetChannels = () => {
    let requestData = {
        url: `${_urlChannels}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: CHANNEL_CONSTS.GET_CHANNELS,
                    payload: data
                })
                return data;
            })
    };
};

export const GetChannelById = (id) => {
    let requestData = {
        url: `${_urlChannels}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                // console.log('data', data);
                await dispatch({
                    type: CHANNEL_CONSTS.GET_CHANNEL_BY_ID,
                    payload: data
                })
                return data
            })
    };
};


export const ClearChannelById = () => {
    return dispatch => {
        dispatch({
            type: CHANNEL_CONSTS.GET_CHANNEL_BY_ID,
            payload: null
        })
    }
};

export const AddChannel = (data) => {
    let requestData = {
        url: `${_urlChannels}`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: CHANNEL_CONSTS.ADD_CHANNEL,
                    payload: {...data, ...res.data}
                })
                return res
            })
    };
};

export const UpdateChannel = (id, data) => {
    const requestData = {
        url: `${_urlChannels}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CHANNEL_CONSTS.UPDATE_CHANNEL,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};
export const UpdateChannelUsers = (id, data) => {
    const requestData = {
        url: `${_urlChannels}/${id}/change_users`,
        token: true,
        method: "PATCH",
        data,
    };
    return () => {
        return request(requestData)
            .then(() => {
                // dispatch({
                //     type: CHANNEL_CONSTS.UPDATE_CHANNEL,
                //     payload: {
                //         id, data
                //     }
                // });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteChannel = id => {
    const requestData = {
        url: `${_urlChannels}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CHANNEL_CONSTS.DELETE_CHANNEL,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};
