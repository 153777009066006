// Import packages
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './participation-data.scss';
import CombinePersonsDropdown from "../../../../../shared/features/PombinePersons/CombinePersonsDropdown";
import {getAuthorName, hasPermission} from "../../../../../utils/helperFunctions";
import {
	CloseIcon, DeletingIcon,
	ErrorIcon, InfoIcon,
	 PlayedIcon,
	 StarIcon,
	SuccessIcon
} from "../../../../../image";
import {useDispatch, useSelector} from "react-redux";
import {
	ClearHighlights,
	ClearPersonById, ClearProfile,
	DeletePersonService,
	GetVideosService, LogOut,
	UserDetachVideoService
} from "../../../../../redux/actions";
import VideoBlock from "../../Channels/VideoBlock/VideoBlock";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import {Loader} from "../../../../../shared/features/Loader";
import {Toast} from "react-bootstrap";
import {Skeleton, Tooltip} from "antd";
import {history} from "../../../../../utils/history";
import {ProfileRecordingBlock} from "../../../../../modules/UserInfo/components/ProfileRecordingBlock";
import {UserPersonInfoContext} from "../../../../../modules/UserInfo/components/contexts";
import dayjs from "dayjs";
import {monthsShort, monthsShortEn, ROLE_ITEMS} from "../../../../../utils/constants";
import UserDeleteModal from "../../../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";
import {GetNotesService} from "../../../../../redux/actions/notesActions";
import {Text} from "../../../../../modules";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {MarkDownText} from "../../../../../shared/features/MarkDownText";
import {objectToQueryString} from "../../../../../utils/api";
import {CONTENT_TYPE} from "../../../../Notes/helpers";


function ParticipationData(props) {
	const {videosList, user, highlights, setVideoList, isLoadingVideos, closeModal, onOpenModal, isProfile} = props;
	const {t, i18n} = useTranslation();
	const dispatch = useDispatch();
	const channels = useSelector(state => state.channels?.channels || []);
	const channelsList = generateChannelsList(channels)
	const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
	const url = window.location.pathname;
	const parts = url.split('/');
	const userIdQuery = parts.at(-1);
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const {getModalInfo, userInfo, updateUserInfo} = useContext(UserPersonInfoContext);
	const personId = userInfo?.person_id ? userInfo?.person_id : (Number(userIdQuery) || null);
	const userId = userInfo?.user_id ? userInfo?.user_id : (Number(userIdQuery) || null);
	const [isVisibleDeleteAccountModal, setVisibleDeleteAccountModal] = useState(false);
	const [noteInfo, setNoteInfo] = useState();

	const isCheckPermissionDetachUser = isProfile
		? hasPermission(ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT)
		: (userInfo?.status === 'new' || !userInfo?.user_id)
			? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT)
			: hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT);

	const isCheckPermissionDeleteParticipant = isProfile
		? hasPermission(ROLE_ITEMS.SELF_PROFILE_DELETE)
		: (userInfo?.status === 'new' || !userInfo?.user_id)
			? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_DELETE)
			: hasPermission(ROLE_ITEMS.FOREIGN_USERS_DELETE);

	const isCheckPermissionRecognitionUser = isProfile
		? hasPermission(ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT)
		: (userInfo?.status === 'new' || !userInfo?.user_id)
			? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT)
			: hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT);

	const formatDate = (date, lng) => {
		const day = dayjs(date).date();
		const monthIndex = dayjs(date).month(); // 0-11
		const year = dayjs(date).year();
		const month = lng === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

		return `${day} ${month} ${year}`;
	};

	function findFirstIntersection(array1, array2) {
		for(let item1 of array1) {
			const matchingItem = array2.find(item2 => item1 === item2.id);
			if(matchingItem) {
				return matchingItem
			}
		}
		return null;
	}

	const logOut = async () => {
		await dispatch(LogOut());
		localStorage.setItem('logout', 'true');
		history.push('/');
		ClearProfile()
	}

	function navigateTo() {
		history.push({
			pathname: `/dashboard/videos/userVideos/${personId}`,
			state: {
				personId: personId,
				routs: [
					{
						path: '/dashboard/profile',
						name: t('profile_profile')
					},
					{
						name: getAuthorName(user?.id ? user : user, null, i18n.language),
						active: true
					}
				]
			}
		})
	}

	const onDetachUser = async (video) => {
		try {
			setIsLoading({isShow: true, id: video?.id});
			await dispatch(UserDetachVideoService({
				video_id: Number(video?.id),
				person_id: personId
			}));
			const videosResponse = await dispatch(GetVideosService({
				person_id: personId,
				limit: 1000
			}, true))

			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('unpin_user_video_success')
			});
			setIsLoading({isShow: false, id: 0});
			setVideoList && setVideoList(videosResponse || {});
		} catch (error) {
			console.error(error);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('unpin_user_video_error')
			});
			setIsLoading({isShow: false, id: 0});
		}
	}

	async function deletePerson() {
		try {
			await DeletePersonService(personId);
			await dispatch(ClearPersonById());
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("delete_participant_success_text")
			});
			if(personId) {
				if(getModalInfo) await getModalInfo({person_id: null, user_id: userId})
				if(updateUserInfo) await updateUserInfo({person_id: null, user_id: userId})
				dispatch(ClearHighlights());
				setVideoList({});
			}
			if(!userId) {
				if(closeModal) {
					closeModal()
				} else {
					logOut();
				}
			}

		} catch (error) {
			console.error(error);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("delete_participant_error_text")
			});
		}
	}

	const onConfirmDeleteProfileButton = () => {
		setVisibleDeleteAccountModal(false)
		deletePerson();
	}

	const getNotes = async () => {
		try {
			const response = await dispatch(GetNotesService({
				limit: 4,
				offset: 0,
        mentioned_person_id: personId
			}, true));
			setNoteInfo(response)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if(personId) {
			getNotes()
		}
	}, [personId]);
	const markdownRef = useRef(null);


	useEffect(() => {
		const container = markdownRef.current;
		if(!container) return;
		const handleClick = (event) => {
			const target = event.target;

			if(target.tagName === "SPAN" && target.hasAttribute("data-person-id")) {
				const personId = target.getAttribute("data-person-id");
				if(personId && personId !== 'null') {
					if(getModalInfo) {
						getModalInfo({person_id: Number(personId), user_id: null})
					} else {
						onOpenModal({person_id: Number(personId), user_id: null})
					}
				}
			}
		};

		const currentContainer = container?.querySelectorAll('.markdown-text-person');
		const currentTimeContainer = container?.querySelectorAll('.markdown-text-video-time');

		if(currentContainer.length) {
			currentContainer.forEach(item => {
				item.addEventListener("click", handleClick);
			})
		}
		if(currentTimeContainer.length) {
			currentTimeContainer.forEach(item => {
				item.addEventListener("click", handleClick);
			})
		}

		return () => {
			if(currentContainer.length) {
				currentContainer.forEach(item => {
					item.removeEventListener("click", handleClick);
				})
			}
			if(currentTimeContainer.length) {
				currentTimeContainer.forEach(item => {
					item.removeEventListener("click", handleClick);
				})
			}
		};
	}, [noteInfo, markdownRef]);

	function navigateToNotes() {
		if(closeModal) closeModal()
		history.push({
			pathname: `${ROUTERS_MAP.NOTES}`,
			search: objectToQueryString({contentType: CONTENT_TYPE.ACTIONS_ITEMS, personId: personId}),
			state: {
				routs: [
					{
						path: ROUTERS_MAP.PROFILE,
						name: t('profile_title')
					}
				]
			}
		})
	}

	return <div className="participation-data">
		{personId ? null : <div className="empty_video" style={{marginBottom: 20}}>{t('participants_not_combine')}</div>}
		{!isCheckPermissionRecognitionUser ?
			<div className="participation-data__permissions-info">
				<InfoIcon/>
				<span>{t('permissions_banner_text')}</span>
			</div>
			: null}
		{isLoadingVideos ?
			<div className="mock-wrap">
				{Array(3).fill(null).map((item) => {
					return (
						<div className="mock-wrap--item">
							<Skeleton.Node active={true} block={true}/>
						</div>
					)
				})}
			</div>
			:
			<>
				{personId ?
					<>

						{
							videosList?.count ? <div>
									<div className={'participation-data__wrap'}>
										<span className={'participation-data__wrap--title'}>{t('profile_video')}</span>
										{videosList?.count > 3 ?
											<span
												className="count"
												onClick={navigateTo}
											>
		              {t('see_all')}&nbsp;({(videosList?.count)})
								</span>
											: null
										}
									</div>

									<div className="video_list">
										{
											videosList?.results?.slice(0, 3).map((item, index) => {
												const badgeInfo = findFirstIntersection(item?.channels || [], channels)
												return (
													<div className="video_list--wrap" key={index}>
														{isCheckPermissionDetachUser ?
															<div className="video_list__detach">
																<Tooltip
																	placement="right"
																	title={t('unpin_user_video')}
																>
																	<div
																		className="video_list__detach--icon"
																		onClick={() => onDetachUser(item)}
																	>
																		{(isLoading.isShow && isLoading.id === item?.id) ?
																			<Loader size={20}/>
																			:
																			<CloseIcon/>
																		}
																	</div>
																</Tooltip>
															</div>
															: null}
														<VideoBlock
															badge={badgeInfo}
															channelsList={channelsList}
															updateUserModal={(user) => {
																if(getModalInfo) {
																	getModalInfo(user)
																} else {
																	if(onOpenModal) onOpenModal(user)
																}
															}}
															routs={[
																{
																	path: '/dashboard/profile',
																	name: t('profile_profile')
																},
																{
																	id: user?.id ? user : user,
																	name: getAuthorName(user?.id ? user : user, null, i18n.language),
																},
																{
																	path: `/dashboard/profile`,
																	name: `${i18n.language === 'ru' ? 'Видео с' : 'Video with'} ` + getAuthorName(user?.id ? user : user, null, i18n.language),
																	active: true
																}
															]}
															video={item}
															isDetach={isCheckPermissionDetachUser}
														/>
													</div>
												)
											})
										}
									</div>
								</div>
								: <div className="empty_video">{t('empty_video')}</div>
						}
					</>
					: null}
			</>
		}

		<div className={'participation-data__wrap'}>
			<span className={'participation-data__wrap--title'}>{t('participated_in_notes')}</span>
			{noteInfo?.count > 0 ?
				<span
					className="count"
					onClick={navigateToNotes}
				>
                      {t('see_all')}&nbsp;({(noteInfo?.count)})
                    </span>
				: null}
		</div>
		<div className='participation-data__notes'>
			<div className='participation-data__notes--subtitle'>
				<Text font="bold" size="f16" color="#4A4A4A">Stared </Text>
				<Text font="medium" size="f15" color="#9898A6">(last {noteInfo?.results?.length || 0})</Text>
			</div>
        {(noteInfo?.results || []).length > 0 ?
			<div className='participation-data__notes--list' ref={markdownRef}>
						{noteInfo?.results.map((note) => (
							<div className="note-block">
								<div className="note-block__header">
									<div className="note-block__header--wrap">
										<Tooltip
											placement="right"
											title={note.video?.description || ''}
										>
											<Link
												to={`${ROUTERS_MAP.VIDEO}/${note?.video?.video_id}`}
												className="video-link">
												<div className="note-block__header--video">
													<PlayedIcon/>
													<Text font="medium" size="f12" color="#828282">{note.video?.description || ''}</Text>
												</div>
											</Link>
										</Tooltip>
										<Text font="book" size="f12" color="#828282">
											{formatDate(note?.video?.creation_time, i18n.language)}, {dayjs(note?.video?.creation_time).format('HH:mm:ss')}
										</Text>
									</div>
								</div>
								<div className="note-block__body">
									<div className="note-block__body--content custom-scroll">
										{(note?.notes || []).map((note, index) => (
											<div className="note-block__body--content--item">
												<MarkDownText
													key={index}
													text={note.text}
												/>
												{note?.is_starred ? <StarIcon className="star"/> : null}
											</div>
										))}
									</div>
								</div>
							</div>
						))}
			</div>
          :
          <div className='participation-data__notes--empty'>
              <Text size="f16" color="#9898A6">{t('no_stared_notes')}</Text>
          </div>
        }
		</div>
		{personId &&
			<ProfileRecordingBlock isProfile={isProfile}/>
		}
		{(user && isCheckPermissionRecognitionUser) &&
			<div className={'connect_person'}>
				<span>{t('connect_person')}</span>

				<CombinePersonsDropdown user={user} setVideoList={setVideoList}/>
			</div>
		}
		{(personId && isCheckPermissionDeleteParticipant && videosList?.count > 0) ?
			<div className="remove-participation">
				<div className="remove-participation__wrap">
					<span className="remove-participation__wrap--title">{t('remove_video_participation')}</span>
					<div className="remove-participation__wrap--description">
					<span>
						{t('remove_video_participation_description')}
					</span>
						<button onClick={() => setVisibleDeleteAccountModal(true)}>
							<DeletingIcon/>
							{t('button_delete_information')}
						</button>
					</div>
				</div>
			</div>
			: null}
		{/*{*/}
		{/*    (!avatar || avatar === 'None') && <div className={'add_img'}>*/}
		{/*        <span>{t('add_img')}</span>*/}

		{/*        <button className="add_img_button">*/}
		{/*            <PictureIcon/>*/}
		{/*            {t('upload_img')}*/}
		{/*        </button>*/}
		{/*    </div>*/}
		{/*}*/}
		<Toast
			className='upload_video_answer'
			onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
			show={toastInfo.isShow}
			delay={3000}
			autohide
		>
			<div className="toasblock">
				{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
				<div className="toasblock__wrap">
						                <span
							                className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
					<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
				</div>
			</div>
		</Toast>
		{isVisibleDeleteAccountModal &&
			<UserDeleteModal
				show={isVisibleDeleteAccountModal}
				onClose={() => setVisibleDeleteAccountModal(false)}
				onConfirm={onConfirmDeleteProfileButton}
				isDeleteUserSettings={false}
			/>}

	</div>
}

export default ParticipationData;
