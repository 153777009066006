function isMaxProduct() {
    // REACT_APP_PRODUCT_NAME - обязательная переменная
    return process.env.REACT_APP_PRODUCT_NAME.toLowerCase() === "max";
};

const config = {
    productName: isMaxProduct() ? "secretopus_title" : "secretopus_title",
};

export default config;
