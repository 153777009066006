import React, {useCallback, useContext, useState} from 'react';
import './ChainBlock.scss';
import {ChainIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusNewIcon, StarsIcon} from "../../../../image";
import {Popover, Tooltip} from "antd";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {hasPermission} from "../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../utils/constants";
import {Button} from "../../../Button";
import EditChainInput from "../../../Chain/components/SettingsAutoChainsModal/components/EditChainInput";
import {CheckBox} from "../../../CheckBox";
import {getChainsFromChannel} from "../../../Chain/components/SettingsAutoChainsModal/helpers";
import useEditChain from "../../../Chain/components/SettingsAutoChainsModal/useEditChain";
import DropdownMenu from "../../../../shared/features/dropdownMenu";


const ChainBlock = (props) => {
	const {channel,checkedChain,setCheckedChains,handleCheckedChainsData} = props;
	const {t} = useTranslation();

	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const [isVisibleAddForm, setIsVisibleAddForm] = useState(false);
	const toggleIsVisibleAddForm = useCallback(() => setIsVisibleAddForm(prevState => !prevState), []);
	const [editChainInfo, setEditChainInfo] = useState('');
	const [chainName, setChainName] = useState('');
	const [openChains, setOpenChains] = useState({});

	const closeAndClearInput = () => {
		toggleIsVisibleAddForm();
		setChainName('');
		setEditChainInfo({});
	}

	const {AddChain, DeleteChain, UpdateChain} = useEditChain({closeAndClearInput, chainName, channel, editChainInfo});

	console.log('sa', openChains)
	const onHandleEditChangeChain = (id) => {
		const findChain = chainsAll.find((item) => item?.id === id);
		setEditChainInfo(findChain || {})
		toggleIsVisibleAddForm();
		setChainName(findChain.name);
	}
	const onHandleClickCheckBoxChain = (e, chainsId) => {
		const value = e.target.checked;
		const findItem = checkedChain.find((item) => item.id === chainsId);
		let arr = [];

		if(findItem){
			arr = checkedChain.map((item) => {
				if(item.id === chainsId){
					return {
						...item,
						is_bound: value
					}
				}
				return item
			});
		}else{
			arr = [...checkedChain,{id: chainsId, is_bound: value}]
		}
		setCheckedChains(arr)
		if(handleCheckedChainsData) handleCheckedChainsData(arr)
	}
	const selectedChainsId = checkedChain.filter((item) => item.is_bound).map(item => item.id);

	return (
		<div className="chain-block">
			<div className="chain-block--title">
				{t('chains')}
			</div>
			{(!isVisibleAddForm && (hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || channel?.is_private)) ?
				<Button
					text={t('add_chain')}
					variant="ghost"
					onClick={toggleIsVisibleAddForm}
					leftIcon={<PlusNewIcon/>}
					className="chain-block--add-btn"
				/>
				: null}
			{isVisibleAddForm && !editChainInfo?.id ?
				<EditChainInput
					onSave={() => AddChain(channel)}
					setChainName={setChainName}
					chainName={chainName}
					closeAndClearInput={closeAndClearInput}
				/>
				: null}
			<div className="chain-block__list">
				{getChainsFromChannel(chainsAll, channel)
					.map((chain, index) => (
						<>
							{isVisibleAddForm && editChainInfo?.id === chain?.id ?
								<EditChainInput
									onSave={() => UpdateChain(channel)}
									setChainName={setChainName}
									chainName={chainName}
									closeAndClearInput={closeAndClearInput}
								/>
								:
								<div className="chain-block__item" key={index}>
									<CheckBox
										type={'checkbox'}
										checked={selectedChainsId.includes(chain?.id)}
										onChange={(e) => onHandleClickCheckBoxChain(e, chain?.id)}
									/>
									{(chain?.name || '').length > 25 ?
										<Tooltip title={chain?.name} placement="top">
											<div className="chain-block__item--badge">
												<ChainIcon/>
												<span>{chain?.name}</span>
											</div>
										</Tooltip>
										:
										<div className="chain-block__item--badge">
											<ChainIcon/>
											<span>{chain?.name}</span>
										</div>
									}
									<DropdownMenu
										closeAfterToggle={true}
										className='popover_edit-menu--settings'
										contentWidth={'176px'}
									>
											<div
												className={'popover_edit-menu--item'}
												onClick={(e) => {
													onHandleEditChangeChain(chain?.id)
												}}
											>
												<EditIcon className={''}/> {t('button_edit')}
											</div>
										<div
											className={'popover_edit-menu--item delete'}
											onClick={(e) => {
												DeleteChain(chain?.id)
											}}
										>
											<DeletingIcon className={''}/> {t('button_delete')}
										</div>
									</DropdownMenu>

								</div>
							}
						</>
					))}
			</div>
		</div>
	);
};

export default ChainBlock;