export const USERS_CONSTS = {
    GET_USERS: "GET_USERS",
    GET_USER_BY_ID: "GET_USER_BY_ID",
    GET_USERS_PERSONS: "GET_USERS_PERSONS",
    ADD_USER: "ADD_USER",
    CLEAR_USERS: "CLEAR_USERS",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    SEARCH_USERS: "SEARCH_USERS",
    SEARCH_USERS_CLEAR: "SEARCH_USERS_CLEAR",
    CLEAR_USER_BY_ID: "CLEAR_USER_BY_ID",
};
