import {_urlUsers, _urlUsersPersons, request} from "../api";
import {USERS_CONSTS} from "../constants";
import {objectToQueryString} from "../../utils/api";

export const GetUsers = (params) => {
    let requestData = {
        url: `${_urlUsers}${objectToQueryString(params)}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: data
                })
            })
    };
};

export const SearchUsers = (text) => {
    let requestData = {
        url: `${_urlUsers}/search?q=${text}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.SEARCH_USERS,
                    payload: data
                })
            })
    };
};

export const ClearUsersSearch = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.SEARCH_USERS_CLEAR
        })
    };
};

export const GetUserById = (id) => {
    let requestData = {
        url: `${_urlUsers}/${id}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                })
                return res?.data
            })
    };
};

export const GetUsersPersons = ({
                                    offset = 0,
                                    limit = 10,
                                    filter = 'users',
                                    person_status = undefined,
                                    role_id = undefined,
                                } = {},setData) => {
    let requestData = {
        url: `${_urlUsersPersons}?offset=${offset}&limit=${limit}&filter=${filter}`,
        method: "GET",
    };
    person_status && (requestData.url = requestData.url + `&person_status=${person_status}`);
    role_id && (requestData.url = requestData.url + `&role_id=${role_id}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
              if (setData) {
                return data
              } else {
                await dispatch({
                  type: USERS_CONSTS.GET_USERS_PERSONS,
                  payload: data
                })
                  return data;
              }
            })
    };
};

export const ClearUsers = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.CLEAR_USERS
        })
    };
};

export const ClearUserById = () => {
    return async dispatch => {
        await dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID
        })
    };
};

export const AddUser = (data) => {
    let requestData = {
        url: `${_urlUsers}`,
        method: "POST",
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USERS_CONSTS.ADD_USER,
                    payload: data
                });
            })
    };
};

export const UpdateUser = (id, data) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USERS_CONSTS.UPDATE_USER,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteUserService = (id) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        method: "DELETE",
    };
    return request(requestData)
      .then(async (res) => {
        return res?.data
      })
};

