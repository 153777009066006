import React, {useEffect, useRef, useState} from 'react';
import './FilterParticipants.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {CheckBox} from "../../../CheckBox";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {DeleteFilterAction, SetFilterAction, GetUsersPersons} from "../../../../redux/actions";
import {ALL_FILTERS, getValueFilters} from "../../helpers";
import {getAuthorName} from "../../../../utils/helperFunctions";

const FilterParticipants = ({onGetVideos, pageType}) => {
    const {t, i18n} = useTranslation();
    const usersList = useSelector(state => state.users.usersPersonsList.results || []);
    const users = usersList.filter((item) => item?.person_id).map((user) => {
        return {
            name: getAuthorName(user, null, i18n.language),
            id: user?.person_id
        }
    });
    const selectedUsersState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.PARTICIPANTS] || []);
    const [selectedItems, setSelectedItems] = useState([...selectedUsersState]);
    const [open, setOpen] = useState(true);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [isAll, setIsAll] = useState(false);
    const [searchText, setSearchText] = useState('');
    const allFilters = useSelector(state => state.filtersOrSort);
    const allFiltersValue = getValueFilters(allFilters, pageType);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [sortUsers, setSortUsers] = useState([]);

    const getLabel = () => {
        let text = '';
        if (selectedUsersState.length === 1) {
            const findStatus = users.find((user) => user.id === selectedUsersState[0]);
            text = findStatus?.name
        } else if (selectedUsersState.length > 1) {
            text = `+${selectedUsersState.length}`
        } else {
            text = 'All'
        }
        return text
    }

    function handleOpenChange(newOpen) {
        setOpen(newOpen);
        setOpenTooltip(false);
        setSearchText('');
        if (!newOpen) {
            setSelectedItems(selectedUsersState)
        }
    }

    function handleOpenTooltipChange(newOpen) {
        setOpenTooltip(newOpen);
    }

    const setValueFilter = (data) => {
        dispatch(SetFilterAction({
            pageType,
            filterType: ALL_FILTERS.PARTICIPANTS,
            value: data
        }))
    }

    const getVideos = async (type) => {
        let params = {
            ...allFiltersValue,
            person_ids: selectedItems,
        };
        if (!selectedItems?.length || type === 'delete') delete params?.person_ids;

        await onGetVideos(params);
    }

    const onSelectedFilter = (value) => {
        let arr = [...selectedItems];

        if (selectedItems.includes(value)) {
            arr = arr.filter((item) => item !== value);
        } else {
            arr.push(value)
        }
        setSelectedItems(arr);
    }

    const handleCancelClick = () => {
        setOpen(false);
        setOpenTooltip(false);
        setValueFilter(selectedUsersState);
    }

    const handleAllClick = (isCheck) => {
        if (isCheck) {
            setSelectedItems(users.map((item) => item.id))
        } else {
            setSelectedItems([])
        }
    }

    const handleApplyClick = async () => {
        try {
            setOpen(false);
            setOpenTooltip(false);
            setValueFilter(selectedItems);
            await getVideos()
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFilter = async () => {
        dispatch(DeleteFilterAction({
            pageType,
            filterType: ALL_FILTERS.PARTICIPANTS
        }));
        await getVideos('delete')
    }

    useEffect(() => {
        dispatch(GetUsersPersons({filter: 'persons', limit: 1000}));
    }, [])

    useEffect(() => {
        setSelectedItems(selectedUsersState)
        setSearchText('');
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setSortUsers(sortUsers.sort((a) => selectedItems.includes(a.id) ? -1 : 1))

    }, [open]);

    useEffect(() => {
        setIsAll(selectedItems.length === users.length)
    }, [selectedItems]);

    useEffect(() => {
        setSortUsers(users)
    }, [usersList]);

    const RenderTooltip = () => {
        if (selectedUsersState.length >= 1) {
            return (
                <Tooltip
                    open={open ? false : openTooltip}
                    onOpenChange={handleOpenTooltipChange}
                    placement="top"
                    title={<RenderTooltipText/>}
                >
                    <span
                        className="filter-participant__label--text">{`${t('participants_filter')}: ${getLabel()}`}</span>
                </Tooltip>
            )
        }
        return <span className="filter-participant__label--text">{`${t('participants_filter')}: ${getLabel()}`}</span>
    }

    const RenderTooltipText = () => {
        const itemsFiltered = users.filter((item) => selectedUsersState.includes(item.id));
        return (
            <div className="filter-participant__tooltip">
                {itemsFiltered.map((item) => (
                    <div className="filter-participant__tooltip--text">{item?.name}</div>
                ))}
            </div>
        )
    }

    const getFilteredStatuses = () => {
        if (searchText) {
            return sortUsers.filter((items) => (items?.name || '').toLowerCase().includes(searchText.toLowerCase()))
        }
        return sortUsers
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && selectedItems.length) {
            handleApplyClick()
        }
    };

    return (
        <Popover
            arrow={false}
            trigger='click'
            placement="bottomLeft"
            overlayClassName='filter-participant'
            onOpenChange={handleOpenChange}
            destroyTooltipOnHide={true}
            open={open}
            content={
                <div className="filter-participant__container" onKeyDown={handleKeyDown}>
                    {users?.length >= 7 ?
                        <div className="filter-participant__search" tabIndex={0}>
                            <div className="filter-participant__search--input-wrap">
                                <SearchIcon/>
                                <input
                                    value={searchText}
                                    onChange={(e) => setSearchText(e?.target?.value || '')}
                                    type="text" placeholder={t('input_search')}
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                        : null}
                    {getFilteredStatuses().length ?
                        <>
                            <div style={{paddingRight: 16}}>
                                <div className="filter-participant__list custom-scroll">
                                    {searchText ? null :
                                        <div className="filter-participant__list--item">
                                            <CheckBox
                                                checked={selectedItems.length === users.length}
                                                onChange={() => {
                                                    handleAllClick(!isAll);
                                                }}
                                                label={i18n.language === 'ru' ? 'Все' : 'All'}
                                            />
                                        </div>
                                    }

                                    {getFilteredStatuses().map((item) => (
                                        <div
                                            key={item.id}
                                            className="filter-participant__list--item">
                                            <CheckBox
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => {
                                                    onSelectedFilter(item.id)
                                                }}
                                                label={item.name}
                                            />
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </>
                        :
                        <>
                            {searchText ?
                                <div className="filter-participant__empty">
                                    <span>{t('no_found_search_item')}</span>
                                </div>
                                :
                                null
                            }
                        </>
                    }
                    <div className="filter-participant__footer">
                        <Button
                            text={t('button_cancel')}
                            variant="secondary"
                            size="xs"
                            onClick={handleCancelClick}
                        />
                        <Button
                            text={t('apply')}
                            variant="primary"
                            size="xs"
                            disabled={!selectedItems.length}
                            onClick={handleApplyClick}
                        />
                    </div>
                </div>
            }
        >
            <div className={`filter-name__label ${selectedUsersState.length ? 'selected' : ''}`}>
                <div className={`filter-name__wrap`}>
                    <FilterIcon/>
                    <RenderTooltip/>
                    {!selectedUsersState.length ?
                        <ArrowLeftIcon className={`filter-participant__label--arrow ${open ? 'open' : ''}`}/> : null}
                </div>
                {selectedUsersState.length ?
                    <CloseStrokeIcon
                        onClick={deleteFilter}
                        className={`filter-name__label--arrow`}
                    /> : null}
            </div>
        </Popover>
    );
};

export default FilterParticipants;