// Import packages
import React, {useContext, useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import "./link-content.scss"

// Import Components
import DatePicker from "react-datepicker";
import DatePickerDropdowns from "../DatePickerDropdowns/DatePickerDropdowns";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {Form} from "react-bootstrap";
import {UpdateLink} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {CopyIcon} from "../../../../image";
import {copyToClipboard} from "../../../../utils/helperFunctions";
import {ToastContext} from "../../../../modules/Toast/ToastContext";

function LinkContent(props) {
    const {hideTitle, link, handleLinkData, onHide, videoId} = props;
    const [checkedType, setCheckedType] = useState(true);
    const [channelInfo, setChannelInfo] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const {setToastInfo} = useContext(ToastContext);

    const {t} = useTranslation();

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setStartDate(link?.expiration_time ? new Date(link?.expiration_time): tomorrow)
        setCheckedType(link?.is_public)
        setChannelInfo(!!link?.expiration_time)
    }, [])

    useEffect(() => {
        handleLinkData && handleLinkData({
            is_public: checkedType,
            expiration_time: !channelInfo ? null : startDate
        })
    }, [checkedType, channelInfo, startDate])

    function toggleCheck() {
        setChannelInfo(!channelInfo)
    }

    function changeTime(date) {
        const dateStart = new Date(date);
        setStartDate(dateStart);

        dateStart.setHours(0, 0, 0, 0);

        // props.GetCalendarsEvents({
        //     datetime_start: dateStart.toISOString(),
        //     datetime_end: dateEnd.toISOString()
        // })
    }

    function toggleType(e) {
        setCheckedType(e.target.checked)
        props.UpdateLink(link?.id, {
            is_public: e.target.checked
        }, videoId)
    }

    const changeDateFormat = (date) => {
        const day = date?.getDate()
        const month = date?.getMonth() + 1
        const year = date?.getFullYear()

        return `${day}/${month}/${year}`
    }

    function onClose() {
        onHide()
        setCheckedType(true)
        setChannelInfo(false)
        setStartDate(new Date())
    }

    function saveChanges() {
        props.UpdateLink(link?.id, {
            is_public: checkedType,
            expiration_time: !channelInfo ? null : startDate
        }, videoId)
        onClose()
    }

    let disabledBtn =
        (link?.is_public === checkedType)
        && (changeDateFormat(new Date(link?.expiration_time)) === changeDateFormat(startDate))
        && (!!link?.expiration_time === !!channelInfo);

    const copyLinkUrl = () => {
        if(link?.url){
            copyToClipboard(link.url);
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t("link_copied_to_clipboard")
            });
        }
    }

    return <div className={`link-content-wrapper`}>
        {!hideTitle && <div className="link-title">
            {t('profile_link')}
            {/*<CloseIcon onClick={handleCloseMenu}/>*/}
        </div>}
        <div className="link-content">
            <Form className={'link-switch'}>
                <Form.Check
                    type="switch"
                    id="switch-one"
                    label={checkedType ? t('public') : t('non_public')}
                    checked={checkedType}
                    onChange={(e) => {
                        toggleType(e)
                    }}
                />
            </Form>
            {!checkedType ?
                <div
                  onClick={copyLinkUrl}
                  className="link-content__link-copy">
                    <CopyIcon/>
                    <span>{t('copy_link')}</span>
                </div>
            : null}
            {checkedType && <Form className="link-types">
                <Form.Check
                    inline
                    name="type"
                    label={t('indefinitely')}
                    type={'radio'}
                    checked={!channelInfo}
                    onChange={(e) => toggleCheck({
                        name: 'participant',
                        value: e?.target?.checked,
                    })}
                />
                <Form.Check
                    inline
                    name="type"
                    label={`${t('by_date')} (${t('before')} ${changeDateFormat(startDate)})`}
                    type={'radio'}
                    checked={channelInfo}
                    onChange={(e) => toggleCheck({
                        name: 'all',
                        value: e?.target?.checked,
                    })}
                />
            </Form>}

            {
                checkedType && channelInfo && <div className={`calendar-wrapper`}>
                    <DatePickerDropdowns changeTime={changeTime}
                                         date={startDate}/>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            changeTime(date)
                        }}
                        startDate={startDate}
                        dateFormat="yyyy/MM/dd"
                        inline
                    />
                </div>
            }
        </div>
        {!hideTitle && !!checkedType && <div className="buttons-wrapper">
            <button className={`close_btn`} onClick={onClose}>
                {t('button_cancel')}
            </button>

            <button className={`save_btn ${disabledBtn ? 'disabled' : ''}`}
                    disabled={disabledBtn}
                    onClick={saveChanges}>
                {t('button_save')}
            </button>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    UpdateLink
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkContent);
