import {NOTE_CONSTS} from "../constants/notesConst";
import {ApiStatus} from "../../utils/constants";

export const initialState = {
	notesList: {
		apiStatus: ApiStatus.INITIAL,
		filters: {},
		data: [],
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case NOTE_CONSTS.GET_NOTES:
			return {
				...state,
				notesList: {
					...state.notesList,
					data: action.payload,
				}
			};
		case NOTE_CONSTS.CHANGE_API_STATUS_GET_NOTES:
			return {
				...state,
				notesList: {
					...state.notesList,
					apiStatus: action.payload,
				}
			};
		case NOTE_CONSTS.UPDATE_FILTERS:
			return {
				...state,
				notesList: {
					...state.notesList,
					filters: action.payload,
				}
			};
		case NOTE_CONSTS.RESET_FILTERS:
			return {
				...state,
				notesList: {
					...state.notesList,
					filters: {},
				}
			};
		default:
			return state;
	}
}