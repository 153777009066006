import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {
	ArrowTopCircleIcon,
	ChatIcon,
	CleanIcon,
	CopyIcon,
	ErrorIcon,
	OctopusIcon,
	PlusIcon,
	SearchIcon, SuccessIcon
} from "../../../../image";
import './MessageBlock.scss';
import {TABS} from "../Chat/Chat";
import {useDispatch, useSelector} from "react-redux";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {Loader} from "../../../features/Loader";
import {Tooltip} from "antd";
import {GetMessagesService, SendMessageService} from "../../../../redux/actions/chatActions";
import {getDateOpenChat, getTimeDifference, getTokensText, preparedMessages} from "../../helpers";
import {MarkDownText} from "../../../features/MarkDownText";
import {useTranslation} from "react-i18next";
import SourcesBlock from "../SoursecBlock/SourcesBlock";
import {Form, Toast} from "react-bootstrap";
import {copyToClipboard} from "../../../../utils/helperFunctions";
import {useLocation} from "react-router-dom";
import {monthsShort, monthsShortEn} from "../../../../utils/constants";

const DefaultMessages = (i18n,checkVideoPage) => [
	{
		response_text: i18n.language === 'en'
			? "Hi! I\'m Secretopus AI bot. I can help you find key information from your conversations without the need to go through lengthy chat logs."
			: "Привет! Я AI-бот Secretopus. Я могу помочь вам найти ключевую информацию из ваших разговоров без необходимости просматривать длинные журналы чатов."
	},
	{
		response_text: i18n.language === 'en'
			? 'To enable global search mode, simply tab the switch next to the search bar.'
			: 'Чтобы включить режим глобального поиска, просто нажмите на переключатель рядом со строкой поиска.'
		,
	},
	{
		response_text: i18n.language === 'en'
			? checkVideoPage
				? 'I can help you find information either for a specific video or across all videos. If you need to switch the chat mode, use the toggle at the top of the window.'
				: 'I can help you find information either for a specific video or across all videos.'
			: checkVideoPage
				? 'Я могу помочь вам найти информацию как по конкретному видео, так и по всем видеороликам. Если вам нужно переключить режим чата, воспользуйтесь переключателем в верхней части окна.'
				: 'Я могу помочь вам найти информацию как для конкретного видео, так и для всех остальных.'
	},

];

const MessageBlock = (props) => {
	const location = useLocation();
	const {activeTab, setActiveTab,isOpenPopover} = props;
	const [text, setText] = useState('');
	const userInfo = useSelector(state => state.profile.userProfile);
	const userAvatar = useSelector(state => state.profile.userAvatar);
	const videoInfo = useSelector(state => state.videos.videoById);
	const listRef = useRef(null);
	const textAreaRef = useRef(null);
	const [isLoadingMessage, setIsLoadingMessage] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
	const [messages, setMessages] = useState(preparedMessages(DefaultMessages(i18n, checkVideoPage), [], userInfo));
	const isCheckMessages = messages.length <= 3;
	const [chatVideoType, setChatVideoType] = useState(false);
	const videoId = (!chatVideoType && checkVideoPage) ? (location.pathname || '').split('/')?.at(-1) : null;

	useEffect(()=>{
		const value = window.localStorage.getItem('chat_type');
		// if(chatVideoType !== Boolean(value)){
		// 	setChatVideoType(Boolean(value === !!Number(videoId)))
		// }
		if(isOpenPopover){
			setChatVideoType(!Number(videoId))
		}else{
			window.localStorage.removeItem('chat_type')
		}
	},[isOpenPopover])
	const changeText = (e) => {
		setText(e?.target?.value || '')
	}

	const addText = async (text) => {
		try {
			if(text) {
				setError(null);
				setText('');
				setMessages([...messages, {
					id: 100,
					isBot: false,
					text,
					date: dayjs(),
					used_db_query: false,
					author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
				}]);
				setIsLoadingMessage(true);
				await dispatch(SendMessageService({
					video_id: videoId ? Number(videoId) : undefined,
					request_text: text,
					clear_session: false
				}))
				const response = await dispatch(GetMessagesService(videoId));
				setMessages(preparedMessages(DefaultMessages(i18n,checkVideoPage), response?.chat || [], userInfo));

				textAreaRef.current.style.height = '20px';
				setIsLoadingMessage(false);
			}
		} catch (e) {
			setError(e);
			setIsLoadingMessage(false)
		}
	}

	const copyMessage = (item) => {
		try {
			copyToClipboard(item.text)
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("toast_success_description")
			});
		} catch (e) {
			console.error(e)
		}
	}

	const getMessages = async () => {
		try {
			const response = await dispatch(GetMessagesService(videoId));
			setMessages(preparedMessages(DefaultMessages(i18n,checkVideoPage), response?.chat || [], userInfo))
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const clearChat = async () => {
		try {
			if(!isCheckMessages) {
				await dispatch(SendMessageService({
					video_id: videoId ? Number(videoId) : undefined,
					clear_session: true
				}));
				const response = await dispatch(GetMessagesService(videoId));
				setMessages(preparedMessages(DefaultMessages(i18n,checkVideoPage), response?.chat || [], userInfo))
			}
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			// Если нажата клавиша Enter без Shift
			if (!e.shiftKey) {
				e.preventDefault(); // Предотвращаем перенос строки
				addText(text);
			}
		}
	};

	useEffect(() => {
		function updateHeightTextArea() {
			this.style.height = '20px';
			this.style.overflow = 'hidden';
			this.style.height = this.scrollHeight + 'px';
		}

		textAreaRef.current?.addEventListener('input', updateHeightTextArea);

		return () => {
			textAreaRef.current?.removeEventListener('input', updateHeightTextArea);

		}
	}, []);

	useLayoutEffect(() => {
		if(listRef.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	}, [messages, isLoadingMessage]);

	useEffect(() => {
		if(activeTab === TABS.MESSAGE) {
			getMessages();
		}
	}, [chatVideoType]);

	const updateChatType = (e) => {
		window.localStorage.setItem('chat_type', e?.target.checked);
		setChatVideoType(e?.target.checked);
	}
	const userMessages = messages.filter((item)=>!item?.isBot);


	return (
		<>
			<div className={`clean-chat ${isCheckMessages ? 'clean-chat--disabled' : ''}`}>
					<Form.Check
						type="switch"
						className="clean-chat--svitch"
						checked={chatVideoType}
						onChange={updateChatType}
						disabled={!checkVideoPage}
						label={t('this_global')}
					/>
				<div className="clean-chat__video-info">
					{!chatVideoType && (videoInfo?.description || '').length > 24 ?
						<Tooltip
							placement="top"
							title={videoInfo?.description}
						>
							<span
								className="clean-chat__video-info--name">{videoInfo?.description}</span>
						</Tooltip>
						:
						<span
							className="clean-chat__video-info--name">{!chatVideoType ? videoInfo?.description : t('this_global')}</span>
					}
					<span className="clean-chat__video-info--date">{getDateOpenChat(userMessages?.[0]?.date || dayjs(), i18n)}</span>
				</div>
				<CleanIcon onClick={clearChat}/>
				<span
					className="clean-chat--btn"
					onClick={clearChat}
				>
					{t('chat_clean_btn')}
				</span>
			</div>
			<div className="message-block">
				<div
					className="message-block__wrap   custom-scroll"
					ref={listRef}
				>
					<div className="message-block__list">
						{messages.map((item) => (
							<div className="message">
								{item?.isBot ?
									<div className="message__avatar-bot">
										<OctopusIcon className='message__avatar-bot--icon'/>
									</div>
									:
									<img src={generateMediaUrl(userAvatar)} alt=""/>
								}
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">{item.author_name}</div>
										<div className="message__block_title--date">
											{item.date ? <span>{getTimeDifference(item.date, i18n)}</span> : null}
											{item?.used_db_query ? <span>&#32;&#32;&#8226;&#32;&#32;used data sourses</span> : null}
											{item?.tokens_used ? <span>&#32;&#32;&#8226;&#32;&#32;{getTokensText(item.tokens_used)}</span> : null}
										</div>
									</div>
									<MarkDownText
										className="message__block--text"
										text={item.text}
									/>
									<SourcesBlock
										messages={messages}
										listRef={listRef}
										videoInfo={videoInfo}
										videoId={videoId}
										item={item}
										copyMessage={copyMessage}
									/>
								</div>
							</div>
						))}
						{isLoadingMessage ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div
											className="message__block_title--date">{i18n.language === 'ru' ? '0 мин. назад' : '0 m. ago'}</div>
									</div>
									<div className="message__block--text-loader">
										{t('chat_querying_db')}
										<Loader size={16}/>
									</div>
								</div>
							</div>
							: null}
						{error ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div className="message__block_title--date">{getTimeDifference(dayjs(), i18n)}</div>
									</div>
									<div className="message__block--text-error">
										{t('chat_system_error')}
									</div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</div>
			<div className="chat__footer">
				<Tooltip
					placement="left"
					title={t('chat_tooltip_badge_search')}
				>
					<div className='chat__tabs'>
						<div
							className={`chat__tabs-item ${activeTab === TABS.MESSAGE ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.MESSAGE)}
						>
							<OctopusIcon className='chat__tabs_icon-octopus'/>
						</div>
						<div
							className={`chat__tabs-item ${activeTab === TABS.SEARCH ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.SEARCH)}
						>
							<SearchIcon className='chat__tabs_icon-search'/>
						</div>
					</div>
				</Tooltip>
				<div
					className="chat-input"
					tabIndex={0}
				>
					<textarea
						ref={textAreaRef}
						value={text}
						onChange={changeText}
						onKeyDown={handleKeyDown}
						className="chat-input__input custom-scroll"
						placeholder={t('chat_message_input')}
						id={"autoTextarea"}
					/>
					<ArrowTopCircleIcon
						onClick={()=>addText(text)}
						className={`chat-input__right-icon${text ? '--active' : ''}`}/>
				</div>
			</div>
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						<span
							className="toasblock__wrap--title">{t('toast_success_title')}</span>
						<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
					</div>
				</div>
			</Toast>
		</>
	);
};

export default MessageBlock;