import React, {Fragment, useState} from 'react';
import {Avatar, Tooltip} from "antd";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {CloseIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {Loader} from "../../../../shared/features/Loader";
import {GetVideosService, UserDetachVideoService} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {VIDEOS_CONSTS} from "../../../../redux/constants";
import {isCheckDetachPermission} from "../../../../utils/helperFunctions";

const UserGroupContainer = (props) => {
	const {users, videoId,updateUserModal, updateUserInfo, userInfo, activePage, isDetach, isActiveUser, toggleUserModal,setOpen} = props;
	const {i18n, t} = useTranslation();
	const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const params = useParams();
	const userProfile = useSelector(state => state.profile.userProfile);

	const dispatch = useDispatch();

	function getAuthorName(data) {
		if(data?.name) {
			return data?.name;
		} else if(data?.first_name) {
			return `${data.first_name ? data.first_name : ''} ${data?.last_name ? data?.last_name : ''}`
		} else {
			return `id-${data?.person_id || data?.id || ''} ${i18n.language === 'ru' ? 'неизвестный' : 'unknown'}`
		}
	}

	function isCheckUserKnow(data) {
		return !!(data?.image_path || data?.avatar) && (data?.image_path || data?.avatar) !== "None"
	}

	function isCheckActiveUser(data) {
		return isActiveUser && (userProfile?.id === data?.user_id || userProfile?.attached_person?.id === data?.person_id)
	}

	const onDetachUser = async (person_id) => {
		try {
			let query = {
				limit: 1000,
			}
			setIsLoading({isShow: true, id: person_id});
			await dispatch(UserDetachVideoService({
				video_id: Number(videoId),
				person_id: person_id || null,
			}));
			if(updateUserInfo && !!userInfo) {
				const videosResponse = await dispatch(GetVideosService({
					person_id: (userInfo?.person_id || userInfo?.id) ? (userInfo?.person_id || userInfo?.id) : userInfo?.user_id,
					limit: 1000,
					channel_id: params?.channelId,
					chain_id: params?.chainId
				}))
				updateUserInfo(videosResponse || {});
				setIsLoading({isShow: false, id: 0});
			} else {
				if(activePage) {
					await dispatch(GetVideosService({limit: 20, offset: (activePage - 1) * 20, manage: true}));
					setIsLoading({isShow: false, id: 0});
				} else {
					if(params?.channelId) query['channel_id'] = params?.channelId;
					if(params?.chainId) query['chain_id'] = params?.chainId;
					if(params?.id) query['person_id'] = params?.id;
					if(params?.channelId && !params?.chainId && !params?.id){
						const response = await dispatch(GetVideosService({
							...query,
							manage: false,
						}, true));
						await dispatch({
							type: VIDEOS_CONSTS.GET_VIDEOS_CHANNEL,
							payload: response
						})
					}else{
						await dispatch(GetVideosService({
							...query,
							manage: false,
						}));
					}
					setIsLoading({isShow: false, id: 0});
				}
			}
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('unpin_user_video_success')
			});
		} catch (error) {
			console.error(error);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('unpin_user_video_error')
			});
			setIsLoading({isShow: false, id: 0});
		}
	}

	const onHandleClickUser = (user) => {
			if(updateUserModal){
				updateUserModal(user)
			}else{
				toggleUserModal(user)
			}
		setOpen(false);
	}

	return (
		<div className={`user-group__container ${users?.length > 4 ? 'user-group__container--large' : ''}`}>
			{
				!!users?.length && users?.map((user, index) => (
					<div
						key={index}
						className="user-group__container-item"
					>
						<Avatar
							key={index}
							src={generateMediaUrl(user?.avatar)}
							className={`user-group__container-item--avatar ${isCheckActiveUser(user) ? 'user-group__container-item--active' : ''}`}
							size={35}
							onClick={() => onHandleClickUser(user)}
						/>
						<div
							className="user-group__container-item--text"
							onClick={() => onHandleClickUser(user)}
						>
							<span>
							{getAuthorName(user)}
						</span>
							<span>{user?.email || 'user@gmail.com'}</span>
						</div>
						{ (isDetach && isCheckDetachPermission(user, userProfile)) ?
							<Tooltip
								placement="right"
								title={t('unpin_user_video')}
								className={"user-group__container-item--icon"}
								overlayClassName={"user-group__container-item--icon"}
							>
								<Fragment>
									{(isLoading.isShow && isLoading.id === (user?.person_id || user?.id)) ?
										<Loader size={15}/>
										:
										<CloseIcon
											className={`${!isCheckUserKnow(user) ? "icon-disabled" : ""}`}
											onClick={(e) => {
												e.stopPropagation();
												if(!isLoading.isShow && isCheckUserKnow(user)) {
													onDetachUser(user.id)
												}
											}}
										/>
									}
								</Fragment>
							</Tooltip>
							: null}
					</div>
				))
			}
			<Toast
				className='toaster'
				bg={toastInfo.type} onClose={() => setToastInfo({isShow: false})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				{toastInfo?.text || ''}
			</Toast>
		</div>
	);
};

export default UserGroupContainer;