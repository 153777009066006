// Import packages
import React, {useState} from 'react';
import {connect, useSelector} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './link-dropdown.scss';
// Import Components
import DropdownMenu from "../../../../shared/features/dropdownMenu/DropdownMenu";
import LinkContent from "../LinkContent/LinkContent";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {hasPermission} from "../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../utils/constants";

function LinkDropdown(props) {
    const {link, isOwner, video_id, data} = props;

    const [menuIsClosed, setMenuIsClosed] = useState(true);
    const {t} = useTranslation();
    const userProfile = useSelector(state => state.profile.userProfile);
    const isCheckOwner = ((data?.owners || []).some((item) => item?.user_id === userProfile?.id)
        || (data?.owners || []).some((item) => item?.person_id === userProfile?.attached_person?.id));

    const isCheckPermissionSharingLink = isCheckOwner ? hasPermission(ROLE_ITEMS.SELF_VIDEOS_SHARE_LINKS_EDIT)
        : hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_SHARING_EDIT)


    function handleCloseMenu() {
        setMenuIsClosed(!menuIsClosed)
    }

    const changeDateFormat = (date) => {
        const newDate = new Date(date)
        const day = newDate?.getDate()
        const month = newDate?.getMonth() + 1
        const year = newDate?.getFullYear()

        return `${day}/${month}/${year}`
    }

    return (
      <>
          {isCheckPermissionSharingLink ?
            <DropdownMenu
              button={<div className={`link-btn ${isOwner ? 'is-owner' : ''}`}>
                  {link?.is_public ? <>
                      {t('public')}<br/>
                      {link?.expiration_time && `(${t('before')} ${changeDateFormat(link?.expiration_time)})`}
                  </> : t('non_public')}
              </div>}
              closeDropdown={menuIsClosed}
              onClose={handleCloseMenu}
              className={'link-dropdown'}
              closeAfterToggle={false}
              contentWidth={'347x'}
              fullWidth={false}
            >
                <LinkContent link={link} onHide={handleCloseMenu} videoId={video_id}/>
            </DropdownMenu>
            :
            <div className={`link-btn ${isOwner ? 'is-owner' : ''}`}>
                {link?.is_public ? <>
                    {t('public')}<br/>
                    {link?.expiration_time && `(${t('before')} ${changeDateFormat(link?.expiration_time)})`}
                </> : t('non_public')}
            </div>
          }
      </>
    )

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        ''
    ])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDropdown);
