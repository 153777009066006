import React from 'react';
import './TranscriptionAuthorBlock.scss'
import {getAuthorName} from "../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import {getTextItemTime} from "../../helpers";
import {useSelector} from "react-redux";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {MarkDownText} from "../../../../shared/features/MarkDownText";

const TranscriptionAuthorBlock = ({data,changeVideoTime,toggleUserModal,foundedTexts,activeTextIndex, searchText, index}) => {
	const videoInfo = useSelector(state => state.videos.videoById);
	const videoPersons = videoInfo?.persons;
	const {t} = useTranslation();

	const getImgPerson = (id) => {
		const findImg = videoPersons?.find(i => i.id === id);
		if(findImg) {
			return generateMediaUrl(findImg.image_path);
		}
		return generateMediaUrl(null)
	}

	return (
		<div className="transcription-author-block">
			<img
				className="transcription-author-block--img"
				src={getImgPerson(data.person_id)}/>
			&nbsp;
			<div onClick={()=> toggleUserModal(data?.person_id)}>
				<MarkDownText
					text={
						`@${data.author
							? data.author
							: getAuthorName(videoPersons?.find(i => i.id === data.person_id), data?.person_id)
							|| `${t('unknown')} ${data?.person_id}`
						}`
					}
					isSearch={true}
					searchText={searchText}
					className={'text-markdown'}
					item={data}
					foundedTexts={foundedTexts}
					activeIndex={(foundedTexts || []).findIndex((item)=> item === index)}
					activeTextIndex={activeTextIndex}
				/>
			</div>

			{/*<span*/}
			{/*	className="transcription-author-block--text"*/}
			{/*	*/}
			{/*>*/}
      {/*   */}
      {/*  </span>*/}
			<span className="transcription-author-block--to">&nbsp;{t('at')}&nbsp;</span>
			<span
				onClick={() => changeVideoTime(data?.start_time,data?.start_time)}
				className="transcription-author-block--text"
			>
           {getTextItemTime(data?.start_time || 0,)}
        </span>
		</div>
	);
};

export default TranscriptionAuthorBlock;