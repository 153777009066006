import {USER_CALENDAR_CONSTS} from "../constants";

export const initialState = {
    userCalendars: [],
    calendarEvents: [],
    calendarSynchronize: null,
    clientId: '',
    googleToken: '',
    outlookInfo: {},
    googleUserInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_CALENDAR_CONSTS.GET_USER_CALENDARS:
            return {
                ...state,
                userCalendars: action.payload,
            };
        case USER_CALENDAR_CONSTS.GET_CALENDAR_EVENTS:
            return {
                ...state,
                calendarEvents: action.payload,
            };
        case USER_CALENDAR_CONSTS.GET_CALENDAR_SYNCHRONIZE:
            return {
                ...state,
                calendarSynchronize: action.payload,
            };
        case USER_CALENDAR_CONSTS.CALENDAR_LOG_OUT:
            return initialState;
        case USER_CALENDAR_CONSTS.GET_GOOGLE_AUTH:
           return {
                ...state,
                clientId: action.payload?.client_id,
                googleToken: action.payload?.token,
                signature: action.payload?.signature,
           };
        case USER_CALENDAR_CONSTS.GOOGLE_AUTH:
            return {
                ...state,
                clientId: action.payload?.client_id,
                googleToken: action.payload?.token,
                signature: action.payload?.signature,
            };
        case USER_CALENDAR_CONSTS.OUTLOOK_AUTH:
            return {
                ...state,
                outlookInfo: action.payload,
            };
        case USER_CALENDAR_CONSTS.OUTLOOK_LOG_OUT:
            return {
                ...state,
                outlookInfo: null
        }
        case USER_CALENDAR_CONSTS.GET_GOOGLE_USER_INFO:
            return {
                ...state,
                googleUserInfo: action.payload
        }
        case USER_CALENDAR_CONSTS.CLEAR_GOOGLE_USER_INFO:
            return {
                ...state,
                googleUserInfo: {}
        }
        default:
            return state;
    }
}
