import React, {useEffect, useState} from 'react';
import {Modal} from "antd";
import label from '../../../image/images/calendar-connect.png'
import {Button} from "../../Button";
import './ConnectedCalendarModal.scss';
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConnectedCalendarModal = ({onSuccess}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const isCalendarAddedStorage = localStorage.getItem('close-connect-calendar-modal');
    const {t,i18n} = useTranslation();
    const isCalendarAdded = searchParams.get('isCalendarAdded');
    const newVCSName = searchParams.get('newVCSName');

    const onClose = () => {
        localStorage.setItem('close-connect-calendar-modal', 'true')
        setIsModalOpen(false);
        searchParams.delete('isCalendarAdded');
        searchParams.delete('newVCSName');
        history.push({ search: searchParams.toString() });
        onSuccess();
    };

    useEffect(() => {
        if (isCalendarAdded === 'true' && !isCalendarAddedStorage) {
            setIsModalOpen(true);
        }
    }, [])

    const getDescription = () => {
        if (i18n.language === 'ru') {
            return `Ваше имя для участия в видеоконференции "${newVCSName}" было добавлено в ваш профиль.
                        Мы будем использовать его для фильтрации информации о вашем участии в видеороликах.`
        } else {
            return `Your name in video conference "${newVCSName}" has been added to your profile.
                        We will use it to filter information about your participation in videos.`
        }
    }

    return (
        <Modal
            classNames={{
                wrapper: 'connect-calendar-modal__wrapper',
            }}
            open={isModalOpen}
            onCancel={onClose}
            centered
        >
            <div className="connect-calendar-modal__container">
                <img src={label} className="connect-calendar-modal__container--img"/>
                <div className="connect-calendar-modal__container--title">
                    {t('calendar_connected_title')}
                </div>
                {newVCSName ?
                    <div className="connect-calendar-modal__container--description">
                        {getDescription()}
                    </div>
                : null}
                <Button
                    variant="primary"
                    size="l"
                    text={i18n.language === 'en' ? 'Let`s work' : 'Продолжить работу'}
                    onClick={onClose}
                />
            </div>
        </Modal>
    );
};

export default ConnectedCalendarModal;