import React, {useCallback, useEffect, useState} from 'react';
import './AddVideoToChainModal.scss'
import {Form, Modal} from "react-bootstrap";
import {CloseIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {generateChannelsList} from "../helpers";
import {GetChannelById, GetChannels, GetVideosService, GetVideosServiceReturnList} from "../../../../redux/actions";
import {Loader} from "../../Loader";
import VideoBlock from "../../../../pages/Dashboard/containers/Channels/VideoBlock/VideoBlock";
import {addVideosToChain} from "../../../../redux/actions/chainsActions";
import {useParams} from "react-router-dom";
import {ROUTERS_MAP} from "../../../../utils/routes";


const AddVideoToChainModal = (props) => {
	const {show, onClose, channelId, chainId,videosList} = props;
	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels || []);
	const channelsList = generateChannelsList(channels);
	const channelInfo = channels.find((item) => item?.id === Number(channelId));
	const [selectedIds, setSelectedIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const toggleIsLoading = useCallback(() => setIsLoading(prevState => !prevState), []);
	const dispatch = useDispatch();
	const channelVideos = (videosList?.results || [])?.filter(i => !!i?.channels?.includes(Number(channelId)));
	const videos = channelVideos.filter((video) => !(video?.chains_ids || []).includes(Number(chainId)));
	const params = useParams();


	const handleClickCheckBox = (e, id) => {
		if(e?.target?.checked) {
			setSelectedIds([...selectedIds, id])
		} else {
			setSelectedIds(selectedIds.filter((item) => item !== id));
		}
	}

	const AddVideosToChain = async () => {
		try {
			toggleIsLoading();
			await dispatch(addVideosToChain(chainId, selectedIds))
			await dispatch(GetChannels());
			await dispatch(GetChannelById(channelId));
			toggleIsLoading();
			onClose();
		} catch (e) {
			toggleIsLoading();
			console.warn(e)
		}
	}


	return (
		<Modal
			show={show}
			onHide={onClose}
			size="lg"
			centered
			className={'add-video-to-chain'}
		>
			<div className="modal-content">
				<div className="modal-content__header">
					<p className="modal-content__header--title">{t('add_video')}</p>
					<CloseIcon onClick={onClose}/>
				</div>
				<div className="modal-content__wrapper custom-scroll">
					{isLoading ?
						<div className="modal-content__loader">
							<Loader size={120}/>
						</div>
						:
						<>
							{videos.map((video) => (
								<div
									key={video?.id}
									className="modal-video"
								>
									<Form.Check
										checked={selectedIds.includes(video?.id)}
										type={'checkbox'}
										onChange={(e) => handleClickCheckBox(e, video?.id)}
									/>
									<VideoBlock
										badge={channelInfo}
										channelsList={channelsList}
										routs={[
											{
												path: '/dashboard/videos/review',
												name: t('menu_item_knowledge')
											},
											{
												path: '/dashboard/videos/review',
												name: t('menu_item_knowledge')
											},
											{
												path: `${ROUTERS_MAP.CHANNEL}/${channelInfo?.id}`,
												name: channelInfo?.name
											}
										]}
										video={video}
									/>

								</div>
							))}
						</>
					}
				</div>
				<div className="modal-content__footer">
					<button className={`close_btn`} onClick={onClose}
					>
						{t('button_cancel')}
					</button>
					<button
						className={`save_btn `}
						disabled={!selectedIds.length || isLoading}
						onClick={AddVideosToChain}
					>
						{isLoading ?
							<Loader size={20} color="white"/>
							: `${t('button_add')} ${selectedIds.length ? `(${selectedIds.length})` : ''}`
						}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default AddVideoToChainModal;