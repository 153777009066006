import React, {useEffect, useState} from 'react';
import './RecentVideo.scss';
import {generateMediaUrl} from "../../../../../utils/generateMediaUrl";
import {Loader} from "../../../../../shared/features/Loader";
import dayjs from "dayjs";
import UserIcons from "../../../../../shared/features/userIcons/UserIcons";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {MarkDownText} from "../../../../../shared/features/MarkDownText";
import {Tooltip} from "antd";


const RecentVideo = (props) => {
	const {video, routs, channelsList, ref, isPreview} = props;
	const [isVisibleDefaultPoster, setIsVisibleDefaultPoster] = useState(false);
	const [isLoader, setLoader] = useState(false);
	const [showChannels, setShowChannels] = useState(false);
	const channels = useSelector(state => state?.channels?.channels || [])
	const allChannelsIds = channels.map((item) => item?.id);
	const videoChannels = (video?.channels || []).filter((item) => allChannelsIds.includes(item));
	const badgeInfo = channels.find((item) => item.id === videoChannels?.[0]);
	const countChannels = videoChannels.length;

	useEffect(() => {
		document.addEventListener('click', toggleMenu, {capture: true});
		return () => {
			document.removeEventListener('click', toggleMenu)
		}
	}, []);

	const toggleMenu = (event) => {
		const path = event.composedPath ? event.composedPath() : event.path
		if(!path?.includes(ref?.current)) {
			setShowChannels(false)
		}
	}

	return (
		<div className="recent-video">
			<div className="recent-video--wrap">
				{isLoader && (
					<div className="recent-video--loader">
						<Loader size={50}/>
					</div>
				)}
				<Link
					to={{
						pathname: `/dashboard/videos/video/${video?.id}`,
						state: {routs}
					}}
					className={'recent-video--poster'}
				>
					{isPreview ?
						<Tooltip
							overlayInnerStyle={{width: '260px', padding: 0}}
							title={
								<div className='video-preview-block'>
									<p className='video-preview-block--title'>{video?.description}</p>
									<MarkDownText
										className="video-preview-block--text"
										text={video?.summary}
									/>
								</div>
							}
							placement="bottom"
							mouseEnterDelay={0.5}
						>
							<img
								className="recent-video--poster-img"
								src={video?.thumbnail_url || "https://grinvich.com/static/assets/images/media-default-video.png"}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src="https://grinvich.com/static/assets/images/media-default-video.png";
								}}
								alt={video?.description}
							/>

						</Tooltip>
						:
						<img
							className="recent-video--poster-img"
							src={video?.thumbnail_url || "https://grinvich.com/static/assets/images/media-default-video.png"}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src="https://grinvich.com/static/assets/images/media-default-video.png";
							}}
							alt={video?.description}
						/>
					}
				</Link>
				<div className="recent-video__channels">
					{badgeInfo && <div className="recent-video__channels--badge"
					                   onClick={() => setShowChannels(!showChannels)}
					>
						<span className="dote" style={{backgroundColor: badgeInfo?.bg}}/>
						<span>{badgeInfo?.name}</span>
					</div>}
					{countChannels - 1 > 0 && <div className="channels_count"
					                                    onClick={() => setShowChannels(!showChannels)}
					>
						+{countChannels - 1}
					</div>}
				</div>
			</div>
			<p className="recent-video--name">{video?.description}</p>
				{video?.duration &&
					<div className="recent-video__time">
						{video?.duration}
					</div>
				}
				<div className="recent-video__info">
					<div className="recent-video__info-date">
						<span>{dayjs(video?.created_at).format('DD.MM.YYYY')},</span>
						<span>{dayjs(video?.created_at).format('HH:mm:ss')}</span>
					</div>
					<UserIcons persons={video?.persons} videoId={video?.id}/>
				</div>
			{
				showChannels && !!videoChannels.length && <div className="channels_list">
					{
						videoChannels?.map((item, index) => {
							return <div className="channel_item"
							            key={index}
							            key={channelsList[item]?.name}
							            style={{
								            backgroundColor: channelsList[item]?.bgColor,
								            color: channelsList[item]?.color,
							            }}>
								{channelsList[item]?.name}
							</div>
						})
					}
				</div>
			}
		</div>
	);
};

export default RecentVideo;