import React, {useState} from 'react';
import {Popover} from "antd";
import './AvatarGroup.scss';
import UsersGroupList from "./UsersGroupList";
import UserGroupContainer from "./UserGroupContainer";

const AvatarGroup = (props) => {
	const {users,videoId,updateUserModal, updateUserInfo, userInfo, activePage, isDetach, isActiveUser, toggleUserModal} = props;
	const [open, setOpen] = useState(false);

	const hidePopover = () => {
		setOpen(false);
	};

	const onToggleUserModal = (user) => {
		hidePopover();
		toggleUserModal(user);
	}


	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			onOpenChange={handleOpenChange}
			open={open}
			content={
				<UserGroupContainer
					users={users}
					videoId={videoId}
					setOpen={setOpen}
					updateUserModal={updateUserModal}
					updateUserInfo={updateUserInfo}
					userInfo={userInfo}
					activePage={activePage}
					isDetach={isDetach}
					isActiveUser={isActiveUser}
					toggleUserModal={onToggleUserModal}
				/>
			}
			overlayClassName="user-group__popover"
			destroyTooltipOnHide={true}
		>
			<UsersGroupList users={users} isActiveUser={isActiveUser}/>
		</Popover>
	);
};

export default AvatarGroup;