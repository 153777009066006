import {CHAINS_CONSTS} from "../constants/chainsConsts";

export const initialState = {
	chainsList: [],
	chainById: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CHAINS_CONSTS.GET_CHAINS:
			return {
				...state,
				chainsList: action.payload,
			};
		case CHAINS_CONSTS.GET_CHAIN:
			return {
				...state,
				chainById: action.payload,
			};
		case CHAINS_CONSTS.UPDATE_CHAINS:
			let updatedList = state.chains.chainsList.map(item => {
				return item.user_id === action.payload.id ? {
					...item,
					name: action.payload.data.name,
					description: action.payload.data.description,
				} : item
			})
			return {
				...state,
				usersPersonsList: {
					...state.usersPersonsList,
					results: updatedList
				},
			};
		default:
			return state;
	}
}