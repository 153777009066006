// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './settings-modal.scss';
import {CloseStrokeIcon, GoogleCalendarIcon, OutlookCalendarIcon} from "../../../../image";

//Import components
import {Form, Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    GetUserCalendars,
    UpdateUserCalendars,
    GetCalendarSynchronize,
    GoogleAuth,
    GetAuthorizationUrlOutlook, DeleteOutlookAuth, CalendarLogOut, GetGoogleAuth
} from "../../../../redux/actions";
import {googleLogout, GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import {useTranslation} from "react-i18next";
import config from "../../../../utils/config";
import InfoTooltip from "../../../../pages/Dashboard/containers/Profile/tooltip";
import {Button} from "../../../../modules/Button";

function SettingsModal(props) {
    const {
        showSettingsModal, onHide, userCalendars, targetRef, user, googleToken, clientId, setGoogleUser,
        handleOnSuccess
    } = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [checkedChannels, setCheckedChannels] = useState(null);

    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];
    let checkList = checkedChannels && Object.keys(checkedChannels).filter(i => checkedChannels?.[i]?.enable_sync)
    let initList = userCalendars && userCalendars.filter(i => i?.enable_sync).map(i => i.id)
    const disabledBtn = checkList?.sort()?.toString() !== initList?.sort()?.toString()
    const isMax = config.productName.includes('max')
    const outlookInfo = useSelector(state => state.userCalendars.outlookInfo)
    const isCheckTokens = !!(googleToken || outlookInfo?.token?.access_token);

    const calendars = isMax ? userCalendars
        : !!userCalendars?.length && userCalendars.filter(i => !i?.calendar_id?.includes('neutronix'))

    useEffect(() => {
        if(showSettingsModal) props.GetUserCalendars()

        const element = targetRef.current;
        if (element) {
            const selectedElement = element.querySelector('.react-datepicker__current-month');

            if (selectedElement) {
                const words = selectedElement?.innerText?.split(' ');
                const lastWords = words[1];
                selectedElement.innerHTML = selectedElement?.innerText?.replace(lastWords, `<span class="last-words">${lastWords}</span>`);
            }
        }
    }, [showSettingsModal]);

    useEffect(() => {
        let channels = {}
        !!userCalendars?.length && userCalendars.forEach(item => {
            channels = {
                ...channels,
                [item?.id]: {
                    id: item?.id,
                    enable_sync: item?.enable_sync
                }
            }
        })
        setCheckedChannels(channels)
        return () => {
            setCheckedChannels(null)
        }
    }, [userCalendars?.length]);

    function checkChannel(e, id) {
        let channels = {
            ...checkedChannels,
            [id]: {
                id,
                enable_sync: e.target.checked
            }
        }
        setCheckedChannels(channels)
    }

    async function googleLogOut() {
        try{
            setCheckedChannels(null)
            setGoogleUser(undefined)
            googleLogout()
            await dispatch(CalendarLogOut());
            await dispatch(GetGoogleAuth());
            await dispatch(GetUserCalendars());
            await dispatch(GetCalendarSynchronize());

        }catch (e) {
            console.error(e)
        }
    }

    async function saveResult() {
        const updatePromises = [];

        // Создаем массив промисов для всех изменений, которые нужно отправить
        !!userCalendars?.length && userCalendars.forEach(item => {
            if (item?.enable_sync !== checkedChannels[item?.id]?.enable_sync) {
                const updatePromise = props.UpdateUserCalendars(item?.id, {
                    enable_sync: checkedChannels[item?.id]?.enable_sync
                });
                updatePromises.push(updatePromise); // Сохраняем промис
            }
        });
        onHide();

        // Ждем, пока все промисы завершатся
        if (updatePromises.length > 0) {
            await Promise.all(updatePromises);
        }
        await props.GetCalendarSynchronize();
    }

    const OutlookAuth = async () => {
       try{
           const res = await GetAuthorizationUrlOutlook();
           window.location.href = res.url;
       }catch (e) {
           console.error(e)
       }
    }
    const logoutOutlook = async () => {
      try{
          localStorage.removeItem('close-connect-calendar-modal')
          await dispatch(DeleteOutlookAuth());
          await dispatch(GetUserCalendars());
          await dispatch(GetCalendarSynchronize());
      }catch (e) {
          console.error(e)
      }
    }

    return <Modal
        show={showSettingsModal}
        onHide={onHide}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        contentClassName={'settings_modal'}
        centered
    >
        {isCheckTokens ?
            <div className="settings__header">
                <span className="settings__header--title">{t('calendar_settings_title')}</span>
                <CloseStrokeIcon onClick={onHide}/>
            </div>
          : null}
        <div className="settings-content" onClick={e => e.stopPropagation()}>
           <div className="title">
               {t('calendar_settings_integrations')}
               {!isCheckTokens ? <CloseStrokeIcon onClick={onHide}/> : null}
           </div>
            <div className="settings-content__wrap">
            <div className="calendar-info">
                <div className="user-block">
                    <div className="user-block__wrap">
                        <GoogleCalendarIcon/>
                        <div className="user-block__info">
                            <div className="user-block__info--title">{t('calendar_settings_user_title')}</div>
                            {user?.email && <div className="user-block__info--name">
                                <span>{t('calendar_settings_user_name')}</span>
                                {user?.email}
                            </div>}
                        </div>
                    </div>
                    {googleToken ?
                      <button  className="calendar-info__btn calendar-info__btn--logout" onClick={googleLogOut}>
                              {t('calendar_settings_disconnect')}
                      </button>
                      : clientId &&
                      <GoogleOAuthProvider clientId={clientId}>
                            <GoogleButton
                              GoogleAuth={props?.GoogleAuth}
                              t={t}
                              handleOnSuccess={handleOnSuccess}
                            />
                      </GoogleOAuthProvider>
                    }
                </div>
                {(calendars || []).filter((item) => item?.calendar_service?.name === 'google').length ?
                  <div className="calendar-info__list">
                    {(calendars || [])
                      .filter((item) => item?.calendar_service?.name === 'google')
                      .map((data, index) => {
                          return <div key={`default-${data?.id}`} className="calendar-info__list--item">
                              <Form.Check
                                type={'checkbox'}
                                checked={checkedChannels?.[data.id]?.enable_sync}
                                onChange={(e) => checkChannel(e, data.id)}
                              />
                              <div className="label"
                                   style={{
                                       backgroundColor: tagColors[index >= 5 ? index % 5 : index] + '14',
                                       color: tagColors[index >= 5 ? index % 5 : index],
                                   }}
                              >
                                  {data?.calendar_id?.length > 27 ? <InfoTooltip elem={data?.calendar_id}
                                                                                 title={data?.calendar_id}/> : data?.calendar_id}
                              </div>
                          </div>
                      })}
                </div>
                  : null}
            </div>
            <div className="calendar-info">
                <div className="user-block">
                    <div className="user-block__wrap">
                        <OutlookCalendarIcon/>
                        <div className="user-block__info">
                            <div className="user-block__info--title">{t('calendar_settings_outlook_title')}</div>
                            {(outlookInfo?.outlook_email && outlookInfo?.token?.access_token) &&  <div className="user-block__info--name">
                                <span>{t('calendar_settings_outlook_title')}</span>
                                {outlookInfo?.outlook_email}
                            </div>}
                        </div>
                    </div>
                    {outlookInfo?.token?.access_token ?
                      <button className="calendar-info__btn calendar-info__btn--logout" onClick={logoutOutlook}>
                              {t('calendar_settings_disconnect')}
                          </button>
                          :
                          <button className="calendar-info__btn calendar-info__btn--connect"
                                  onClick={() => OutlookAuth()}>
                              {t('connect')}
                          </button>
                    }
                </div>
                {(calendars || []).filter((item) => item?.calendar_service?.name === 'outlook').length ?
                <div className="calendar-info__list">
                    {(calendars || [])
                      .filter((item) => item?.calendar_service?.name === 'outlook')
                      .map((data, index) => {
                          return <div key={`default-${data?.id}`} className="calendar-info__list--item">
                              <Form.Check
                                type={'checkbox'}
                                checked={checkedChannels?.[data.id]?.enable_sync}
                                onChange={(e) => checkChannel(e, data.id)}
                              />
                              <div className="label"
                                   style={{
                                       backgroundColor: tagColors[index >= 5 ? index % 5 : index] + '14',
                                       color: tagColors[index >= 5 ? index % 5 : index],
                                   }}
                              >
                                  {data?.calendar_id?.length > 27 ? <InfoTooltip elem={data?.calendar_id}
                                                                                 title={data?.calendar_id}/> : data?.calendar_id}
                              </div>
                          </div>
                      })}
                </div>
                  : null}
            </div>
            </div>
            {isCheckTokens ?
                  <div className="buttons">
                    <Button
                      text={t('button_cancel')}
                      variant="secondary"
                      onClick={onHide}
                    />
                    <Button
                      text={t('button_save')}
                      variant="primary"
                      disabled={!disabledBtn}
                      onClick={saveResult}
                    />
                </div>
              : null}
        </div>
    </Modal>
}

function GoogleButton({handleOnSuccess, t}) {
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar',
        onSuccess: async (codeResponse) => {
            handleOnSuccess(codeResponse)
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return <button className="calendar-info__btn calendar-info__btn--connect"
                   onClick={() => login()}>
        {t('connect')}
    </button>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['userCalendars'])
};

const mapDispatchToProps = {
    GetUserCalendars,
    UpdateUserCalendars,
    GetCalendarSynchronize,
    GoogleAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
