// Import packages
import React from 'react';
import {connect} from "react-redux";
import {Route, useRouteMatch} from "react-router-dom";

// Import assets
import './users.scss';

// ImportComponents
import Persons from "./persons/Persons";
import New from "./new/New";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";

function Users(props) {
    const {path} = useRouteMatch();

    return (
        <div className={`main-container ${props.isFullWidth ? '' : 'full'}`}>
            <Route path={`${path}/persons/(userId)?/:userId?/(personId)?/:personId?`} component={Persons}/>
            <Route path={`${path}/new`} component={New}/>
        </div>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['isFullWidth'])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
