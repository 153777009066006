import React, {useEffect, useState} from 'react';
import './FilterDate.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Select, Tooltip} from "antd";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {ALL_FILTERS, getValueFilters} from "../../helpers";
import {DeleteFilterAction,SetFilterAction} from "../../../../redux/actions";
import {Calendar, DateObject} from "react-multi-date-picker";
import {getDateOptions, getMonths, getMonthsOptions, getWeekDays, getYearOptions} from "./helper";
import dayjs from "dayjs";

const FilterDate = ({onGetVideos, pageType}) => {
	const selectedDateState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.DATE] || []);
	const {t, i18n} = useTranslation();
	const [open, setOpen] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [dateCalendar, setDateCalendar] = useState([]);
	const dispatch = useDispatch();
	const [dateInputs, setDateInputs] = useState({
		from: {
			day: dayjs().date(),
			month: dayjs().month() + 1,
			year: dayjs().year()
		},
		to: {
			day: dayjs().date(),
			month: dayjs().month() + 1,
			year: dayjs().year()
		}
	})
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters,pageType);

	const changeDateInputs = (type, typeValue, value) => {
		const obj = {
			...dateInputs,
			[type]: {
				...dateInputs[type],
				[typeValue]: value
			}
		}
		setDateInputs(obj);

		setDateCalendar([
			new DateObject({
				day: obj.from.day,
				month: obj.from.month,
				year: obj.from.year
			}),
			new DateObject({
				day: obj.to.day,
				month: obj.to.month,
				year: obj.to.year
			}),
		])
	}

	const getLabel = () => {
		let text = '';
		const dateFrom = new DateObject(selectedDateState[0]).format('YYYY-MM-DD');
		const dateTo = new DateObject(selectedDateState[1] || selectedDateState[0]).format('YYYY-MM-DD');
		if(selectedDateState.length === 2 && dateTo !== dateFrom) {
			const dateFrom = new DateObject(selectedDateState[0]).format('DD.MM.YYYY')
			const dateTo = new DateObject(selectedDateState[1]).format('DD.MM.YYYY')
			text = `${dateFrom} - ${dateTo}`
		} else if(selectedDateState.length >= 1) {
			const dateFrom = new DateObject(selectedDateState[0]).format('DD.MM.YYYY')
			text = `${dateFrom}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = (data) => {
		dispatch(SetFilterAction({
			pageType,
			filterType: ALL_FILTERS.DATE,
			value: data
		}))
	}

	const handleCancelClick = () => {
		setOpen(false);
		setValueFilter(selectedDateState);
	}
	const getVideos = async (type) => {
		let dates = {};
		if(dateCalendar?.length === 2) {
			dates = {
				date_start: new DateObject(dateCalendar[0]).format('YYYY-MM-DD'),
				date_end: new DateObject(dateCalendar[1]).add(1, 'days').format('YYYY-MM-DD'),
			}
		} else {
			dates = {
				date_start: new DateObject(dateCalendar[0]).format('YYYY-MM-DD'),
				date_end: new DateObject(dateCalendar[0]).add(1, 'days').format('YYYY-MM-DD'),
			}
		}

		let params = {
			...allFiltersValue,
			...dates
		}

		if(type === 'delete') {
			delete params?.date_start;
			delete params?.date_end;
		}

		await onGetVideos(params)
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false)
			setValueFilter(dateCalendar);
			await getVideos();
		} catch (error) {
			console.log(error)
		}
	}

	const deleteFilter = async () => {
		dispatch(DeleteFilterAction({
			pageType,
			filterType: ALL_FILTERS.DATE
		}));
		await getVideos('delete')
	}

	const RenderTooltip = (props) => {
		if(dateCalendar.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					destroyTooltipOnHide={true}
					title={getLabel()}
				>
					<span className="filter-date__label--text">{`${t('date')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return <span className="filter-date__label--text">{`${t('date')}: ${getLabel()}`}</span>
	}

	useEffect(() => {
		if(open && selectedDateState.length) {
			const dateFrom = new DateObject(selectedDateState[0]).format('YYYY-MM-DD');
			const dateTo = new DateObject(selectedDateState[1] || selectedDateState[0]).format('YYYY-MM-DD');
			if(dateTo === dateFrom) {
				setDateCalendar([new DateObject(selectedDateState[0]), new DateObject(selectedDateState[0])])
			}
		} else {
			setDateCalendar(selectedDateState)
		}
	}, [open]);

	useEffect(() => {
		setDateCalendar(selectedDateState)
	}, [selectedDateState.length])

	const getYear = (value) => {
		const year = value || dayjs().year();
		const years = getYearOptions();
		return years.find((item) => item.value === year);
	}
	const getDay = (day) => {
		const date = day || dayjs().date();
		const dates = getDateOptions();
		return dates.find((item) => item.value === date);
	}
	const getMonth = (value) => {
		const month = value || dayjs().month() + 1;
		const months = getMonthsOptions(i18n.language);
		return months.find((item) => item.value === month);
	}

	const onChangeDateCalendar = (dates) => {
		const dateFrom = new DateObject(dates[0]).format('YYYY-MM-DD');
		const dateTo = new DateObject(dates[1] || dates[0]).format('YYYY-MM-DD');
		setDateCalendar(dates)

		if(dates?.length) {

			setDateInputs({
				from: {
					day: dayjs(dateFrom).date(),
					month: dayjs(dateFrom).month() + 1,
					year: dayjs(dateFrom).year()
				},
				to: {
					day: dayjs(dateTo).date(),
					month: dayjs(dateTo).month() + 1,
					year: dayjs(dateTo).year()
				}
			})
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleApplyClick()
		}
	};

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-date'
			onOpenChange={handleOpenChange}
			open={open}
			content={
				<div className="filter-date__container" onKeyDown={handleKeyDown}>
					<div className="filter-date__panel">
						<div className="filter-date__panel--item">
							<span>from</span>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								style={{width: '60px'}}
								options={getDateOptions()}
								value={getDay(dateInputs.from.day)}
								onChange={(value) => changeDateInputs('from', 'day', value)}
							/>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								style={{width: '110px'}}
								options={getMonthsOptions(i18n.language)}
								value={getMonth(dateInputs.from.month)}
								onChange={(value) => changeDateInputs('from', 'month', value)}
							/>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								style={{width: '73px'}}
								options={getYearOptions()}
								value={getYear(dateInputs.from.year)}
								onChange={(value) => changeDateInputs('from', 'year', value)}
							/>
						</div>
						<div className="filter-date__panel--item">
							<span>to</span>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								style={{width: '60px'}}
								options={getDateOptions()}
								value={getDay(dateInputs.to.day)}
								onChange={(value) => changeDateInputs('to', 'day', value)}
							/>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								defaultValue={[getMonthsOptions(i18n.language)[dayjs().month()]]}
								style={{width: '110px'}}
								options={getMonthsOptions(i18n.language)}
								value={getMonth(dateInputs.to.month)}
								onChange={(value) => changeDateInputs('to', 'month', value)}
							/>
							<Select
								suffixIcon={<ArrowLeftIcon/>}
								defaultValue={[getYear()]}
								style={{width: '73px'}}
								options={getYearOptions()}
								value={getYear(dateInputs.to.year)}
								onChange={(value) => changeDateInputs('to', 'year', value)}
							/>
						</div>
					</div>
					<div>
						<div className="filter-date__list custom-scroll">
							<div className="filter-calendar">
								<Calendar
									weekStartDayIndex={1}
									range
									weekDays={getWeekDays(i18n.language)}
									months={getMonths(i18n.language)}
									calendarPosition="bottom-left"
									value={dateCalendar}
									minDate={new Date(2020, 0, 1)}
									showOtherDays
									onChange={onChangeDateCalendar}
								/>
							</div>
						</div>
					</div>
					<div className="filter-date__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-date__label ${selectedDateState.length ? 'selected' : ''}`}>
				<div className={`filter-date__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedDateState.length ?
						<ArrowLeftIcon className={`filter-date__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedDateState.length ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-date__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterDate;