import React, {useEffect, useRef, useState} from 'react';
import {
	GetVideosSearch,
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import './autocomplete.scss';
import {connect} from "react-redux";
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {Loader} from "../Loader";
import {useTranslation} from "react-i18next";


const debounce = require('lodash.debounce');

//authorName - string | null | undefined
//className - string | null | undefined
// persons - [] массив пользователей
// protocolData - {} необязательный
// onChangeInput - {} необязательный
// name - string необязательный
// placeholder - string необязательный
// onChangeUser - string необязательный
const AutocompleteUsers = (props) => {
	const {
		authorName,
		persons = [],
		protocolData,
		className,
		onChangeInput,
		name = "task_executor_name",
		placeholder,
		onChangeUser
	} = props;
	const [users, setUsers] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const [isVisiblePopup, setIsVisiblePopup] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const containerRef = useRef();
	const {t} = useTranslation();

	const searchUser = React.useCallback(debounce(async (value) => {
		try {
			setIsLoader(true);
			const response = await props.GetVideosSearch({
				search_query: value,
				exclude_videos: true,
				exclude_transcriptions: true,
				exclude_persons: false
			}, true)
			setIsLoader(false);

			const findUsers = response?.persons || [];
			const filteredVideoPersons = persons
				?.filter(item => item.first_name !== 'unrecognized')
				.filter((item) => `${item.first_name} ${item.last_name}`?.toLowerCase()?.includes(value?.toLowerCase()))

			setUsers(findUsers.length ? findUsers : filteredVideoPersons)
			if(findUsers.length || filteredVideoPersons.length) {
				setIsVisiblePopup(true);
			}
		} catch (error) {
			setIsLoader(false);
			console.warn(error)
		}
	}, 500), [])

	const handleChangeInput = (e) => {
		const value = e.target?.value || '';

			setInputValue(value);

			if(!Boolean(value)){
				setUsers([]);
			}else{
				searchUser(value);
			}

			if(onChangeInput) {
				onChangeInput(e)
			}
	}
	const handleChangeUser = (item) => {
		const name = `${item?.first_name || ''} ${item?.last_name || ''} `
		setInputValue(name);
		setIsVisiblePopup(false);

		if(onChangeUser) {
			onChangeUser(item)
		}
	}

	useEffect(() => {
		if(Boolean(authorName)) {
			setInputValue(authorName);
		}
	}, [authorName]);

	return (
		<div
			className="autocomplete_input"
			ref={containerRef}
			onMouseLeave={() => setIsVisiblePopup(false)}
		>
			<div
				className="autocomplete_input-wrap"
			>
				<input type="text"
				       className={className}
				       value={inputValue}
				       name={name}
				       placeholder={placeholder}
				       autoComplete="off"
				       onClick={() => {
					       if(Boolean(users.length) && !isVisiblePopup) {
						       setIsVisiblePopup(true)
					       }
					       if(!Boolean(users.length) && Boolean(authorName) && Boolean(inputValue)) {
						       searchUser(authorName);
					       }
				       }}
				       onChange={handleChangeInput}
				/>
				{isLoader && <Loader size={22} className="loader-input"/>}
			</div>
			{
				(isVisiblePopup && Boolean(users?.length) && Boolean(inputValue)) &&
				<div className="persons_list">
					{users.map(item => {
						return (
							<div
								key={item.id}
								className="person_item"
								onClick={() => handleChangeUser(item)}
							>
								<img
									src={generateMediaUrl(item?.image_path || item?.avatar)}
									key={item.id}
									alt=""
								/>
								<div className="person_info">
									<div className="name">
										{item?.first_name ? `${item.first_name || ''} ${item?.last_name || ''}` : `id-${item?.id} ${t('unknown')}`}
									</div>
									<div className="email">
										{item.email || 'user@gmail.com'}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			}
		</div>
	);
};
const mapDispatchToProps = {
	GetVideosSearch
}
export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteUsers);
