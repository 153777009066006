import React, {Fragment, useEffect, useRef, useState} from "react";
import {DeletingIcon, EditIcon, EllipsisIcon, InfoCheckIcon, PlusIcon, PlusNewIcon} from "../../../../../../image";

import ChangeVideoPerson from "../../../../../../shared/features/ChangeVideoPerson/ChangeVideoPerson";
import {getAuthorName, hasPermission} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import {useLocation} from "react-router-dom";
import InfoTooltip from "../../../Profile/tooltip";
import {MarkDownText} from "../../../../../../shared/features/MarkDownText";
import AutocompleteUsers from "../../../../../../shared/features/autocomplete/AutocompleteUsers";
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {VIDEO_PANEL_TABS} from "../../const";
import i18n from "i18next";
import {ROLE_ITEMS} from "../../../../../../utils/constants";
import TranscriptionAuthorBlock
  from "../../../../../Video/components/TranscriptionAuthorBlock/TranscriptionAuthorBlock";
import {Tooltip} from "antd";
import RecapTitleBlock from "../../../../../Video/components/RecapTitleBlock/RecapTitleBlock";

function TextItem({
                      index,
                      item,
                      token,
                      videoPersons,
                      toggleAddProtocol,
                      changeVideoTime,
                      getTime,
                      currentItem,
                      scrollPosition,
                      videoId,
                      searchText,
                      UpdateTranscription,
                      GetVideoById,
                      handleScroll,
                      personsList,
                      activeTabs,
                      className,
                      foundedTexts,
                      activeTextIndex,
                    videoTimelineScale,
                    toggleUserModal
                  }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
    const [selectedText, setSelectedText] = useState('');
    const [isEdited, setIsEdited] = useState(false)
    const [editingText, setEditingText] = useState('')
    const [assigneePersonId, setAssigneePersonId] = useState(undefined);

    let disabledBtn = editingText === item?.text && item.assignee_id === assigneePersonId?.id

    const location = useLocation()
    const ref = useRef();
    const {t} = useTranslation();

    const getTextItem = activeTabs === VIDEO_PANEL_TABS.SUMMERY ? item?.summary : item?.text

    useEffect(() => {
        setShowTooltip(false)
        setTooltipPosition({top: 0, left: 0})
        setSelectedText('')
    }, [scrollPosition]);

    useEffect(() => {
        ref?.current?.offsetHeight &&
        handleScroll(null, (ref?.current?.offsetHeight * index) || 1)
    }, [currentItem]);

    useEffect(() => {
        document.addEventListener('click', toggleText, {capture: true});
        return () => {
            document.removeEventListener('click', toggleText)
        }
    }, []);

    const toggleText = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowTooltip(false)
        }
    }
    const handleMouseUp = () => {
        const selection = window.getSelection();
        const text = selection.toString().trim();
        if (text) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setShowTooltip(true);
            setTooltipPosition({
                top: rect.top - (rect.height > 30 ? rect.height / 2 : rect.height),
                left: rect.left,
            });
            setSelectedText(text);
        } else {
            setShowTooltip(false);
        }
    };

    function CustomTooltip({show, position, content}) {
        if (!show) return null;
        let data = {
            ...item,
            text: content
        }
        return (
            <div
                style={{
                    top: position.top,
                    left: position.left,
                }}
                className={'text-tooltip'}
                onClick={(e) => {
                    token && toggleAddProtocol(e, data)
                }}
            >
                <PlusIcon/>
                {t('add_to_protocol')}
            </div>
        );
    }

    function removeFirstLine(text) {
      const lines = text.split('\n');
      // Удаляем первую строку и объединяем оставшиеся
      const modifiedText = lines.slice(1).join('\n');
      return modifiedText;
    }

  function scrollProtocolListToBottom() {
    const container = document.querySelector('.protocol-list');
    if (container) {
      container.scrollTop = container.scrollHeight;
    } else {
      console.warn("Элемент с классом 'protocol-list' не найден.");
    }
  }

    function toggleProtocol(e, item) {
        e.stopPropagation()
        toggleAddProtocol(e, {
          ...item,
          text: activeTabs === VIDEO_PANEL_TABS.SUMMERY ? `##### ${i18n.language === 'ru' ? 'Глава' : 'Chapter'} ${index + 1}. ${item?.text} \n ${item?.summary}` : item?.text,
        });
      setTimeout(()=>{
        scrollProtocolListToBottom();
      },500);
    }

    function addFavourites(e, item) {
        e.stopPropagation()
        const text = removeFirstLine(item.text);
        toggleAddProtocol(e, {
            ...item,
            text,
            task_executor_person_id: item?.assignee_id || undefined,
            task_executor_name: !item?.assignee_id ? (item?.assignee_name || undefined) : undefined
        });
      setTimeout(()=>{
        scrollProtocolListToBottom();
      },500);
    }



    function getInputValue(e) {
        setEditingText(e.target.value)
    }

    function toggleUpdate() {
        setEditingText(item?.text)
        setIsEdited(true)
    }

    function onClose() {
        setEditingText('')
        setIsEdited(false)
    }

    function saveChanges() {
        setEditingText('')
        setIsEdited(false)

        UpdateTranscription(
            item?.id,
            undefined, undefined,
            {
                text: editingText,
                start_time: Number(item?.start_time),
                end_time: Number(item?.end_time || 0),
                assignee_id : assigneePersonId?.id  || undefined,
                assignee_name : assigneePersonId?.name  || undefined,
            })
    }

    function getAssigneePersonName(id) {
        const person = [...(personsList?.results || []), ...videoPersons];
        const personFind = person.find((item)=> item.id === id);

        return personFind && (personFind?.first_name || personFind?.last_name )? `${personFind.first_name || ''} ${personFind.last_name || ''}`: `id-${id} ${t('unknown')}`
    }
  useEffect(() => {
    const handleClick = (event) => {
      const summaryElement = event.target.closest('summary');
      if (summaryElement) {
        const detailsElement = summaryElement.closest('details');
        if (detailsElement) {
          if (detailsElement.open) {
            summaryElement.textContent = 'Expand';
          }else{
            summaryElement.textContent = 'Collapse';
          }
        }
      }
    };

    const currentContainer = ref?.current;
    currentContainer?.addEventListener('click', handleClick);

    return () => {
      currentContainer?.removeEventListener('click', handleClick);
    };
  }, [ref]);

  const markdownRef = useRef();
  function convertToSeconds(timeString) {
    const timeParts = timeString.split(':').map(Number);
    const hours = timeParts.length === 3 ? timeParts[0] : 0;
    const minutes = timeParts.length >= 2 ? timeParts[timeParts.length - 2] : timeParts[0];
    const seconds = timeParts[timeParts.length - 1];

    return hours * 3600 + minutes * 60 + seconds;
  }

  useEffect(() => {
    const container =markdownRef.current;
    if (!container) return;

    // Функция-обработчик клика
    const handleClick = (event) => {
      const target = event.target;

      if (target.tagName === "SPAN" && target.hasAttribute("data-person-id")) {
        const personId = target.getAttribute("data-person-id");
        if(personId && personId !== 'null'){
          toggleUserModal(Number(personId))
        }
      }

      if (target.tagName === "SPAN" && target.hasAttribute("data-video-time")) {
        const time = target.getAttribute("data-video-time");
        if(time){
          const seconds = convertToSeconds(time)
          changeVideoTime(seconds,seconds)
        }
      }
    };

    const currentContainer = container?.querySelectorAll('.markdown-text-person');
    const currentTimeContainer = container?.querySelectorAll('.markdown-text-video-time');

    if(currentContainer.length){
      currentContainer.forEach(item => {
        item.addEventListener("click", handleClick);
      })
    }
    if(currentTimeContainer.length){
      currentTimeContainer.forEach(item => {
        item.addEventListener("click", handleClick);
      })
    }

    return () => {
      if(currentContainer.length){
        currentContainer.forEach(item => {
          item.removeEventListener("click", handleClick);
        })
      }
      if(currentTimeContainer.length){
        currentTimeContainer.forEach(item => {
          item.removeEventListener("click", handleClick);
        })
      }
    };
  }, [item]);

    return <div className={`texts__item ${item?.isMarked && currentItem ? 'active-text' : ''} ${className || ''}`}
                ref={ref}>
        <div className="texts__item__action">
          {token &&  <div
            className="texts__item__action__btn"
            >
            <Tooltip
              placement="left" title={t('add_to_favourites')}>
              <PlusNewIcon
                onClick={(e) => {
                  if(activeTabs !== VIDEO_PANEL_TABS.AI ){
                    toggleProtocol(e, item)
                  }else {
                    addFavourites(e, item)
                  }
                }}
              />
            </Tooltip>
          </div>
          }
        </div>
        <div className={`texts__item__text times_index_${index} ${item?.person_id === null ? 'texts__item__text-mt' : ''}`}>
            <div className={"texts__top-block"}>
                {activeTabs === VIDEO_PANEL_TABS.AI && item?.person_id === null ? null :
                    <div className={`author_name`}>
                    <span className={`author_name__text`}
                    >
                      {activeTabs === VIDEO_PANEL_TABS.TEXTS ?
                        <TranscriptionAuthorBlock
                          data={item}
                          videoTimelineScale={videoTimelineScale}
                          changeVideoTime={changeVideoTime}
                          toggleUserModal={toggleUserModal}
                          searchText={searchText}
                          activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
                          activeTextIndex={activeTextIndex}
                          index={index}
                        />
                      : null}
                      {activeTabs === VIDEO_PANEL_TABS.SUMMERY ?
                        <RecapTitleBlock
                          data={item}
                          index={index}
                          changeVideoTime={changeVideoTime}
                          searchText={searchText}
                          activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
                          activeTextIndex={activeTextIndex}
                        />
                        : null}
                    </span>
                    {(token && ![VIDEO_PANEL_TABS.SUMMERY, VIDEO_PANEL_TABS.AI].includes(activeTabs) && hasPermission(ROLE_ITEMS.FOREIGN_VIDEOS_AI_TEXT_EDIT)) &&
                        <ChangeVideoPerson
                            videoPersons={videoPersons}
                            videoId={videoId}
                            textItem={item}
                        />
                    }
                </div>
                }
                {(!isEdited && activeTabs === VIDEO_PANEL_TABS.TEXTS && hasPermission(ROLE_ITEMS.FOREIGN_VIDEOS_AI_TEXT_EDIT)) &&
                    <DropdownMenu button={
                        <EllipsisIcon className={'icon-menu'}/>}
                                  closeAfterToggle={true}
                                  className="actions-menu"
                                  contentWidth={'176px'}
                    >
                        <div className={'menu-item'} onClick={(e) => {
                            e.stopPropagation()
                            toggleUpdate()
                        }}>
                            <EditIcon className={''}/> {t('button_edit')}
                        </div>
                    </DropdownMenu>
                }
            </div>

            {
                !isEdited ?
                    <>
                      <span
                        onMouseUp={handleMouseUp}
                        className={`transcription-text ${ currentItem ? 'marked' : ''}`}
                      >
                      <MarkDownText
                          text={getTextItem}
                          isSearch={true}
                          searchText={searchText}
                          className="text-markdown"
                          item={item}
                          activeIndex={(foundedTexts || []).findIndex((item)=> item === index) + 1}
                          activeTextIndex={activeTextIndex}
                          markdownRef={markdownRef}
                      />
                    </span>
                    </>
                    : <div className="text_update">
                        <textarea
                            onChange={getInputValue}
                            value={editingText}
                            name="editingText"
                        />
                      {(activeTabs === VIDEO_PANEL_TABS.AI)  &&
                          <AutocompleteUsers
                            persons={videoPersons}
                            authorName={item?.assignee_id ? getAssigneePersonName(item?.assignee_id) : (item?.assignee_name || '') }
                            name={'author_name'}
                            placeholder={t('tasks_user_title')}
                            onChangeUser={setAssigneePersonId}
                            onChangeInput={(e)=>setAssigneePersonId(Number(e?.id)  ? e : {name: e?.target?.value} )}
                          />
                      }
                        <div className="update_buttons">
                            <button className={`close_btn`}
                                    onClick={onClose}
                            >
                                {t('button_cancel')}
                            </button>

                            <button className={`save_btn ${disabledBtn ? 'disabled' : ''}`}
                                    disabled={disabledBtn}
                                    onClick={() => {
                                        saveChanges()

                                        //change this method
                                        const urlParams = new URLSearchParams(location.search);
                                        const paramsUuid = urlParams.get('uuid')

                                        GetVideoById(videoId, paramsUuid)
                                    }}
                            >
                                {t('button_save')}
                            </button>
                        </div>
                    </div>
            }

        </div>

        <CustomTooltip show={showTooltip} position={tooltipPosition} content={selectedText}/>

    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['personsList'])
};

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TextItem);