import {hexToRgbA} from "../../../utils/helperFunctions";


const channelsColors = {
	'1': {
		color: '#4F40F7',
		bgColor: hexToRgbA('#4F40F7',0.1),
	},
	'2': {
		color: '#2877D5',
		bgColor: '#2877D419',
	},
	'3': {
		color: '#F7404B',
		bgColor: '#F7404B19',
	},
	'4': {
		color: 'rgba(255, 168, 0, 1)',
		bgColor: 'rgba(255, 168, 0, 0.1)',
	},
}
export const generateChannelsList = (channels) => {
	let channelsList = {};

	// return  channels.map((item, index) => {
	// 	return {
	// 		bg: channelsColors[index],
	// 		...item
	// 	}
	// })
	channels?.length && channels.forEach((item,index) => {
		channelsList = {
			...channelsList,
			[item.id]: {
				name: item.name,
				color: channelsColors[item?.is_private ? 4 : index]?.color,
				bgColor: channelsColors[item?.is_private ? 4 : index]?.bgColor,
			}
		}
	});

	return channelsList;
}

export const getDistanceRightBody = (id) => {
	const element = document.getElementById(id);

// Получаем ширину окна просмотра
	const viewportWidth = window.innerWidth;

// Получаем расстояние от левого края body до элемента
	let offsetLeft = 0;
	let currentElement = element;

	while (currentElement) {
		offsetLeft += currentElement?.offsetLeft;
		currentElement = currentElement?.offsetParent;
	}

// Получаем ширину элемента
	const elementWidth = element?.offsetWidth;

// Рассчитываем расстояние от правого края body до элемента
	const distanceFromRight = viewportWidth - (offsetLeft + elementWidth);

	return distanceFromRight;

}