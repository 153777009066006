import React from 'react';
import {useSelector} from "react-redux";
import {ChainIcon} from "../../../../image";
import './ChainList.scss';
import {Popover} from "antd";
import {useTranslation} from "react-i18next";
import {ROUTERS_MAP} from "../../../../utils/routes";
import {Link, useParams} from "react-router-dom";
import ChainsDropDown from "../../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import {hexToRgbA} from "../../../../utils/helperFunctions";


const ChainList = ({channel, channelsList}) => {
	const params = useParams();
	const chains = channel?.chains || [];
	const {t} = useTranslation();
	const channelStyle = channelsList?.[channel?.id];

	const renderChain = (chain) => {
		return (
			<Link
				to={{
					pathname: `${ROUTERS_MAP.CHANNEL}/${channel?.id}/chains/${chain?.id}`,
					state: {
						routs: [
							{
								path: ROUTERS_MAP.KNOWLEDGE_DATA,
								name: t('menu_item_knowledge')
							}
						]
					}
				}}
				className="chain-item--name"
			>
				<div key={chain.id} className="chains-list__item">
					<ChainIcon className="chains-list__item--icon"/>
					<div className="chains-list__item--text">{chain.name}</div>
				</div>
			</Link>
		)
	}

	return (
		<>
			{chains.length ?
				<div
					className="chains-list"
					style={{
						'--chain_сolor': channelStyle?.color || '#33BCC5',
						'--chain_bg': channelStyle?.bgColor || hexToRgbA('#33BCC5', 0.1)
					}}
				>
					{chains.slice(0, 7).map(chain => (
						<> {renderChain(chain)}</>
					))}
					{chains.length > 7 &&
						<Popover
							arrow={false}
							trigger='click'
							placement="bottomLeft"
							overlayClassName='chains-list__popup'
							content={
								<ChainsDropDown
									chains={chains}
									channel={channel}
									channelStyle={channelStyle}
								/>
							}
						>
							<span className="chains-list--see-all">{t('see_all')}</span>
						</Popover>
					}
				</div>
				: null}
		</>
	);
};

export default ChainList;