// Import packages
import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {Toast} from "react-bootstrap";

// Import assets
import './searcher.scss';

// Import Components
import NewRecorder from "../RecorderNew/NewRecorder";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
	ClearVideosSearch,
	GetVideosSearch,
	uploadVideo,
	VideoUrlRecord,
	GetVideos,
	ClearTextSearch
} from "../../../redux/actions";
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {EditGreenIcon, VideoCameraIcon} from "../../../image";
import {history} from "../../../utils/history";
import {useTranslation} from "react-i18next";
import Notifications from "../notification/Notifications";
import {Button} from "../../../modules/Button";
import {Loader} from "../Loader";

function Searcher(props) {
	const {className, isFullWidth} = props;
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(undefined);
	const [showToast, setShowToast] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [showPersonsModal, setShowPersonsModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null)
	const [focusedURL, setFocusedURL] = useState(false)
	const [urlValue, setUrlValue] = useState('')
	const [errorUrl, setErrorUrl] = useState(false)
	const uploadRef = useRef();
	const location = useLocation();

	const {t} = useTranslation();
	function closeModal() {
		setShowPersonsModal(false)
	}

	function submitFile(newFile) {
		let formData = new FormData();
		formData.append('file', newFile);
		setLoading(true)
		props.uploadVideo(formData)
			.then(() => {
				props.GetVideos({limit: 20, manage: true})
			})
			.catch(() => {
				setShowToast(true)
			})
			.finally(() => {
				setLoading(false)
				setFile(undefined)
			});
	}

	function handleUploadClick() {
		uploadRef.current?.click();
	}

	function handleFileUpload(e) {
		if(!e.target.files) {
			return;
		}
		if(e.target.files[0].type.includes('mp4')) {
			setFile(e.target.files[0]);
			submitFile(e.target.files[0])
		} else {
			setErrorText(t('error_file'));
			toggleToast()
		}
	}

	const toggleToast = () => setShowToast(!showToast);

	function ErrorToast() {
		return <Toast show={showToast} onClose={toggleToast} className={'upload_video_answer'}>
			<Toast.Header>
				<img
					src=""
					className="rounded me-2"
					alt=""
				/>
				<strong className="me-auto">{t('error_title')}</strong>
			</Toast.Header>
			<Toast.Body>{errorText ? errorText : t('error_description')}</Toast.Body>
		</Toast>
	}

	function getInputValue(e) {
		const {value} = e.target

		setUrlValue(value)
		setErrorUrl(false)
	}

	function saveUrl() {
		if(urlValue) {
			props.VideoUrlRecord({
				meeting_url: urlValue
			}).then((res) => {
				setUrlValue('')
				setFocusedURL(false)
				history.push({
					pathname: `/dashboard/videos/video/${res?.video_id}`,
					state: {openEdit: true}
				})
			}).catch(() => {
				setErrorUrl(true)
			})
		}
	}

	return (
		<div className={`searcher-wrapper ${className ? className : ''} ${isFullWidth ? '' : 'full'}`}>
			<div className={`search__buttons ${focusedURL ? 'open-url' : ''}`}>
				<div className={`url-wrapper ${errorUrl ? 'error' : ''}`}
				     onClick={() => setFocusedURL(true)}
				     style={{
					     display: location.pathname.includes('/dashboard/videos/video') ? 'none' : 'flex'
				     }}>
					<VideoCameraIcon/>
					<input
						onChange={getInputValue}
						value={urlValue}
						name="description"
						placeholder={t('input_paste_url')}
						type="text"
						onKeyDown={(e) => {
							if(e.key === 'Enter') {
								saveUrl()
							}
						}}
					/>
					<button className="start" onClick={saveUrl}>{t('button_start')}</button>
				</div>
				<div className="overlay" onClick={() => setFocusedURL(false)}/>
				<NewRecorder token={props.token}/>
				<Button
					text={file ? t('button_upload') : t('button_import')}
					onClick={handleUploadClick}
					variant="secondary"
					size="s"
					rightIcon={loading ? <Loader size={16}/> : null}
				/>

				<input
					type="file"
					ref={uploadRef}
					onChange={handleFileUpload}
					style={{display: 'none'}}
				/>
				{/*     {record ? (
                            <Recorder record={record}/>
                        ) : (
                            <button
                                onClick={() => setRecord(true)}
                                className="search__buttons-record"
                            >
                                <RecordIcon/> Record
                            </button>
                        )}*/}
			</div>
			<Notifications/>
			{/*</div>*/}
			{ErrorToast()}
			{showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}
		</div>
	);
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, [
		'isMobile',
		'isFullWidth',
		'headerTitle',
		'searchVideos',
		'token',
		'searchText'
	])
};

const mapDispatchToProps = {
	uploadVideo,
	GetVideosSearch,
	ClearVideosSearch,
	VideoUrlRecord,
	GetVideos,
	ClearTextSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(Searcher);
