// Import packages
import React from 'react';

// Import assets
import './user-roles.scss';

// Import Components
import {Form} from "react-bootstrap";

// Import Utils

function UserRoles(props) {
    const {rolesList, userRole, toggleRole} = props;

    return <Form className={`form-roles ${rolesList?.length < 4 ? 'full' : ''}`}>
            {
                !!rolesList?.length && rolesList?.map(item => {
                    return <Form.Check
                        name="type"
                        key={item?.id}
                        label={item?.name}
                        type={'radio'}
                        checked={userRole?.id === item?.id}
                        onChange={(e) => {
                            if(toggleRole) toggleRole(e, item)
                        }}
                    />
                })
            }
        </Form>
}

export default UserRoles;
