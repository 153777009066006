import React, {useEffect, useState} from 'react';
import {FilterIcon, PlusNewIcon} from "../../../../image";
import {Popover} from "antd";
import {Button} from "../../../Button";
import {useTranslation} from "react-i18next";
import {CheckBox} from "../../../CheckBox";
import './FiltersAll.scss';
import {getAllFilter} from "../../helpers";
import {SetFilterAction, UpdateSelectedFiltersAction} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";


const FiltersAll = (props) => {
	const {btnType = 'primary', pageType, className} = props;
	const {t, i18n} = useTranslation();
	const filtersArray = getAllFilter(pageType, i18n);
	const dispatch = useDispatch();
	const allFilters = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.selectedFilters || []);
	const [selectedFilters, setSelectedFilters] = useState([...allFilters]);
	const [open, setOpen] = useState(false);

	const onSelectedFilter = (value) => {
		let arr = [...selectedFilters];
		if(selectedFilters.includes(value)) {
			arr = arr.filter((item) => item !== value);
			dispatch(SetFilterAction({
				pageType,
				filterType: value,
				value: null
			}))
		} else {
			arr.push(value)
		}
		dispatch(UpdateSelectedFiltersAction({
			pageType,
			value: arr
		}))
		setSelectedFilters(arr);
		setOpen(false)
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
	}

	useEffect(() => {
		setSelectedFilters(allFilters)
	}, [open]);

	return (
		<Popover
			arrow={false}
			open={open}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filters-all'
			onOpenChange={handleOpenChange}
			content={
				<div className="filters-all__container">
					<div className="filters-all__header">
						{t('filter')}
					</div>
					<div className="filters-all__list">
						{filtersArray.map((filter) => (
							<div
								key={filter.id}
								className="filters-all__list--item">
								<CheckBox
									checked={selectedFilters.includes(filter.type)}
									onChange={() => {
										onSelectedFilter(filter.type)
									}}
									label={filter.label}
								/>
							</div>
						))}
					</div>
				</div>
			}
		>
			<div className={className}>
				{btnType === 'primary' ?
					<Button
						leftIcon={<FilterIcon/>}
						text={t('filter')}
						variant="secondary"
						size="xs"
					/>
					: <Button
						leftIcon={<PlusNewIcon/>}
						text={t('add_filter')}
						variant="ghost"
						size="xs"
					/>}
			</div>
		</Popover>
	);
};

export default FiltersAll;