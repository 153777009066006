import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import './registration.scss';

import {getPropsFromState} from "../../../redux/mapStateToProps";
import {LoginUser} from "../../../redux/actions";
import {history} from "../../../utils/history";
import {CheckIcon, EyeIcon} from "../../../image";
import {AddUser} from "../../../redux/actions";
import { useProductName } from "../../../shared/features/productName"
import {useTranslation} from "react-i18next";

function Registration(props) {
    const [data, setData] = useState({
        login: '',
        password: '',
        repeatPassword: '',
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState(false);
    const {t} = useTranslation();
    const samePassword = data.password && data.repeatPassword && (data.password === data.repeatPassword)

    useEffect(() => {
        if (props.token) {
            history.push('/dashboard')
        }
    }, [])

    const handelInputValue = (e) => {
        const {value, name} = e.target;
        setData({
            ...data,
            [name]: value
        })
        setRepeatPasswordError(false)

        if (name === 'password' && (!value || value.length < 3)) {
            setPasswordError(t('validate_password_text'));
        } else {
            setPasswordError('');
        }

        if (name === 'login' && (!value || value.length < 3)) {
            setLoginError(t('validate_login_text'));
        } else {
            setLoginError('');
        }
    };

    // Функция, которая запускает функцию отправки запроса, переносит пользователя на начальную страницу после регистрации
    const handleSubmit = () => {
        const reqData = {
            ...data,
            is_admin: true
        }
        delete reqData['repeatPassword'];

        if (samePassword) {
            props.AddUser(reqData)
        }else {
            setRepeatPasswordError(true)
        }

    };

    return (
        <div className="log-in">
            <div className="log-in__logo">
                {useProductName()}
            </div>
            <div className="log-in__content">

                <div className="log-in__content-title">{t('register')}</div>
                {/*<form onSubmit={handleSubmit}>*/}
                <div className="log-in__content-login">
                    <div className="input-label">{t('input_email')}</div>
                    <input
                        onInput={(e) => handelInputValue(e)}
                        value={data.login}
                        name="login"
                        placeholder="user@example.com"
                        type="login"
                        className="log-in__content__input"
                    />
                    {loginError && (
                        <div className="log-in__error">{loginError}</div>
                    )}
                </div>

                <div className="log-in__content-password">
                    <div className="input-label">{t('input_password')}</div>
                    <div className="input-wrapper">
                        <input
                            onInput={(e) => handelInputValue(e)}
                            value={data.password}
                            name="password"
                            placeholder={t('input_password')}
                            type={`${passwordVisible ? "text" : "password"}`}
                            className="log-in__content__input"
                        />
                        <EyeIcon className="input-icon"
                                 onClick={() => setPasswordVisible(!passwordVisible)}/>
                        {passwordError && (
                            <div className="log-in__error">{passwordError}</div>
                        )}
                    </div>
                </div>

                <div className="log-in__content-password">
                    <div className="input-label">{t('repeat_password')}</div>
                    <div className={`input-wrapper ${repeatPasswordError ? 'error' : ''}`}>
                        <input
                            onInput={(e) => handelInputValue(e)}
                            value={data.repeatPassword}
                            name="repeatPassword"
                            placeholder={t('repeat_password')}
                            type="password"
                            className="log-in__content__input"
                        />
                        {samePassword ? <CheckIcon className="input-icon"/>
                            : <EyeIcon className="input-icon"
                                       onClick={() => setRepeatPasswordVisible(!repeatPasswordVisible)}/>}
                    </div>
                </div>

                <button
                    className={`log-in__content-button ${!!data.password && !!data.login && !!data.repeatPassword ? 'active' : ''}`}
                    // disabled={!password && !login}
                    onClick={handleSubmit}>
                    Register
                </button>

                <div className="log-in__content-text">
                    {/*Already have an account?*/}
                    {t('you_already_account')}?
                    <Link to={'/'} className="link">Login</Link>
                </div>
                {/*</form>*/}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token'])
};

const mapDispatchToProps = {LoginUser, AddUser};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
