import {_urlNotes, request} from "../api";
import {NOTE_CONSTS} from "../constants/notesConst";
import {objectToQueryString} from "../../utils/api";

export const GetNotesService= (params, returnData = false) => {
	let requestData = {
		url: `${_urlNotes}${objectToQueryString(params)}`,
		method: "GET",
	};
	return dispatch => {
		return request(requestData)
			.then((res) => {
				if(returnData){
					return res?.data
				}else{
					dispatch({
						type: NOTE_CONSTS.GET_NOTES,
						payload: res?.data
					})
				}
			}).catch((err) => {
				console.log(err)
			});
	};
};

export const updateApiStatusNotesListService = (data) => {
	return dispatch => {
		dispatch({
			type: NOTE_CONSTS.CHANGE_API_STATUS_GET_NOTES,
			payload: data
		})
	}
};

export const updateFiltersNotesListService = (data) => {
	return dispatch => {
		dispatch({
			type: NOTE_CONSTS.UPDATE_FILTERS,
			payload: data
		})
	}
};

export const resetFiltersNotesListService = () => {
	return dispatch => {
		dispatch({
			type: NOTE_CONSTS.RESET_FILTERS,
			payload: {}
		})
	}
};