import {FILTERS_OR_SORT_CONSTS} from "../constants/filtersOrSort";

export const UpdateSelectedFiltersAction = (data) => {
	return dispatch => {
		dispatch({
			type: FILTERS_OR_SORT_CONSTS.UPDATE_SELECTED_FILTERS,
			payload: data
		})
	}
}

export const SetFilterAction = (data) => {
	return dispatch => {
		dispatch({
			type: FILTERS_OR_SORT_CONSTS.SET_FILTER,
			payload: data
		})
	}
}

export const ResetFiltersAction = (data) => {
	return dispatch => {
		dispatch({
			type: FILTERS_OR_SORT_CONSTS.RESET_FILTERS,
			payload: data
		})
	}
}

export const DeleteFilterAction = (data) => {
	return dispatch => {
		dispatch({
			type: FILTERS_OR_SORT_CONSTS.DELETE_FILTER,
			payload: data
		})
	}
}

export const UpdateSortedParamsAction = (data) => {
	return dispatch => {
		dispatch({
			type: FILTERS_OR_SORT_CONSTS.UPDATE_SORT_PARAMS,
			payload: data
		})
	}
}