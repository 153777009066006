// Import packages
import React from 'react';

// Import assets
import './tooltip.scss';
import {InfoIcon} from "../../../../../image";

// Import Components
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Tooltip} from "react-bootstrap";

// Import Utils

function InfoTooltip({title, description, cb, elem, className, placement = 'right', customContent}) {

    const renderTooltip = (props, text) => (
        <Tooltip id="menu-tooltip" {...props} className={className} arrowProps={{ style: { display: 'none' } }}>
            {
                customContent ? customContent
                    : <>
                        {text.title && <div className="title">{text.title}</div>}
                        {text.description && <div className="description">{text.description}</div>}
                    </>
            }
        </Tooltip>
    );

    const tooltipWrapper = ({element, text, placement}) => {
        return <OverlayTrigger
            flip={false}
            popperConfig={{}}
            onHide={undefined}
            onToggle={undefined}
            target={'auto-start'}
            defaultShow={false}
            show={undefined}
            trigger={undefined}
            placement={placement}
            delay={{show: 100, hide: 200}}
            overlay={(props) => renderTooltip(props, text)}
        >
            {element}
        </OverlayTrigger>
    }
    return tooltipWrapper({
        element: elem ? <span className={className ? className : ''}
                              onClick={cb}>
            {elem}
        </span> : <InfoIcon className={`tooltip-icon`}/>,
        text: {
            title,
            description
        },
        placement
    })
}

export default InfoTooltip;
