import React, {useEffect, useState} from 'react';
import './FilterTypes.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {CONTENT_TYPE, getContentTypeItems} from "../../helpers";
import {
	GetNotesService,
	updateApiStatusNotesListService,
	updateFiltersNotesListService
} from "../../../../redux/actions/notesActions";
import {ApiStatus} from "../../../../utils/constants";
import {CheckBox} from "../../../../modules/CheckBox";
import {Button} from "../../../../modules/Button";

const FilterTypes = () => {
	const {t, i18n} = useTranslation();
	const filters = useSelector(state => state.notes.notesList.filters);
	const contentTypes = getContentTypeItems(i18n.language);
	const [selectedItemsState, setSelectedItemsState] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState([...selectedItemsState]);
	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [isAll, setIsAll] = useState(true);
	const dispatch = useDispatch();


	useEffect(() => {
		const arr = [];
		Object.keys(CONTENT_TYPE).forEach((key) => {
				if(filters[CONTENT_TYPE[key]]){
					arr.push(CONTENT_TYPE[key])
				}
			})
		setSelectedItemsState(arr)
		setSelectedTypes(arr)
	}, [filters]);

	const getLabel = () => {
		let text = '';

		if(selectedItemsState.length === 1) {
			const findType = contentTypes.find((item) => item.type === selectedItemsState[0]);
			text = findType.label
		} else if(selectedItemsState.length > 1) {
			text = `+${selectedItemsState.length}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
		if(!newOpen) {
			setSelectedTypes(Object.values(CONTENT_TYPE).filter((item) => filters[item]))
		}
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const onSelectedFilter = (value) => {
		let arr = [...selectedTypes];
		if(selectedTypes.includes(value)) {
			arr = arr.filter((item) => item !== value);
		} else {
			arr.push(value)
		}
		setSelectedTypes(arr);
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		const types = Object.values(CONTENT_TYPE).map((item) => {return {[item]: false}})
		dispatch(updateFiltersNotesListService({
			...filters,
			...types,
		}))
	}

	const handleAllClick = (isCheck) => {
		console.log('isCheck',isCheck)
		if(isCheck) {
			setSelectedTypes(Object.values(CONTENT_TYPE))
		} else {
			setSelectedTypes([])
		}
	}

	const getNotes = async (data) => {
		try {
			const filtersObj = filters;
			Object.values(CONTENT_TYPE).forEach((item) => {
				if(!filtersObj?.item){
					delete filtersObj[item]
				}
			})
			const obj = data.reduce((result, obj) => {
				return { ...result, ...obj };
			}, {});

			await dispatch(updateApiStatusNotesListService(ApiStatus.LOADING));
			await dispatch(GetNotesService({
				limit: 20,
				offset: 0,
				...filtersObj,
				...obj

			}));
			await dispatch(updateApiStatusNotesListService(ApiStatus.SUCCESS));
		} catch (e) {
			await dispatch(updateApiStatusNotesListService(ApiStatus.ERROR));
		}
	}
	const deleteFilter = async () => {
		const types = Object.values(CONTENT_TYPE).map((item) => {return {[item]: false}})
		const obj = types.reduce((result, obj) => {
			return { ...result, ...obj };
		}, {});
		dispatch(updateFiltersNotesListService({
			...filters,
			...obj,
		}))
		await getNotes(types)
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			const filtersObj = filters;
			Object.values(CONTENT_TYPE).forEach((item) => {
				if(!filtersObj?.item){
					delete filtersObj[item]
				}
			})
			const types = selectedTypes.map((item) => {return {[item]: true}})
			const obj = types.reduce((result, obj) => {
				return { ...result, ...obj };
			}, {});
			dispatch(updateFiltersNotesListService({
				...filtersObj,
				...obj,
			}))
			await getNotes(types);
		} catch (error) {
			console.log(error)
		}
	}

	const RenderTooltip = () => {
		if(selectedItemsState.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={<RenderTooltipText/>}
				>
					<span className="filter-note-type__label--text">{`${t('content_type')}: ${t('favorites')} (${getLabel()})`}</span>
				</Tooltip>
			)
		}
		return <span className="filter-note-type__label--text">{`${t('content_type')}: ${t('favorites')} (${getLabel()})`}</span>
	}

	const RenderTooltipText = () => {
		const typesFiltered = contentTypes.filter((item) => selectedItemsState.includes(item.type));

		return (
			<div className="filter-note-type__tooltip">
				{typesFiltered.map((item) => (
					<div className="filter-note-type__tooltip--text">{item?.label}</div>
				))}
			</div>
		)
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleApplyClick()
		}
	};

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-note-type'
			onOpenChange={handleOpenChange}
			open={open}
			content={
				<div className="filter-note-type__container" onKeyDown={handleKeyDown}>
					<div className="filter-note-type__list">
						<div >
							<CheckBox
								checked={selectedTypes.length}
								onChange={(e) => {
									handleAllClick(e.target.checked);
								}}
								label={i18n.language === 'ru' ? 'Избранные' : 'Favorites'}
							/>
						</div>
						{contentTypes.map((type) => (
							<div
								key={type.id}
								className="filter-note-type__list--item">
								<CheckBox
									checked={selectedTypes.includes(type.type)}
									onChange={() => {
										onSelectedFilter(type.type)
									}}
									label={type.label}
								/>
							</div>
						))}
					</div>
					<div className="filter-note-type__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							disabled={!selectedTypes.length}
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-note-type__label ${(selectedItemsState.length) ? 'selected' : ''}`}>
				<div className={`filter-participant__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{(!selectedItemsState.length)?
						<ArrowLeftIcon className={`filter-note-type__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{(selectedItemsState.length) ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-note-type__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterTypes;