import {HIGHLIGHT_CONSTS} from "../constants";
import {_urlHighlights, request} from "../api";


export const GetHighlights = ({
                                  user_id = undefined,
                                  person_id = undefined,
                              } = {}) => {
    let requestData = {
        url: `${_urlHighlights}?`,
        method: "GET",
    };
    user_id && (requestData.url = requestData.url + `&user_id=${user_id}`);
    person_id && (requestData.url = requestData.url + `&person_id=${person_id}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: HIGHLIGHT_CONSTS.GET_HIGHLIGHTS,
                    payload: data
                })
            })
    };
};

export const DeleteHighlights = id => {
    const requestData = {
        url: `${_urlHighlights}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: HIGHLIGHT_CONSTS.DELETE_HIGHLIGHT,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const ClearHighlights = id => {
        return dispatch => {
            dispatch({
                type: HIGHLIGHT_CONSTS.CLEAR_HIGHLIGHT,
                payload: []
            })
        }
};
