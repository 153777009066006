export const getWeekDays = (lng) => {
	if(lng === 'ru') {
		return ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
	} else {
		return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
	}
}

export const getMonths = (lng) => {
	if(lng === 'ru') {
		return [
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		]
	} else {
		return [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		]
	}
}

export const getDateOptions = () => {
	const options = [];

	for(let i = 1; i <= 31; i++) {
		options.push({
			value: i,
			label: i,
		});
	}
	return options;
}
export const getMonthsOptions = (lng) => {
	const options = [];
	const months = getMonths(lng)

	months.forEach((item, index) => {
		options.push({
			value: index + 1,
			label: item,
		});
	})
	return options;
}

export const getYearOptions = () => {
	const options = [];

	for(let i = 2020; i <= 2024; i++) {
		options.push({
			value: i,
			label: i,
		});
	}
	return options;
}