import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './ProfileTags.scss';
import {CloseIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusGreenIcon, PlusNewIcon} from "../../../../image";
import {Form} from "react-bootstrap";
import {AddTag, DeleteTag, UpdatePersonTags, GetTags, GetPersonById, UpdateTag} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import {Popover} from "antd";
import {hasPermission} from "../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../utils/constants";


function ProfileTags(props) {
	const {user, personById, show, onChangeTags, isProfile,userInfo} = props;
	const [checkedTags, setCheckedTags] = useState(null);
	const [openCreateTag, setOpenCreateTag] = useState(false);
	const [tagName, setTagName] = useState('');
	const [isEditTag, setIsEditTag] = useState({});
	const [editingTag, setEditingTag] = useState('');
	const [open, setOpen] = useState(false);
	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']
	const {t} = useTranslation();
	const tagsList = useSelector(state => state.tags.tagsList);
	const dispatch = useDispatch();

	// const isCheckPermissionEditTags = (userInfo?.status === 'new' || !userInfo?.user_id)
	// 	? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT)
	// 	: hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT);
	const isCheckPermissionEditTags = isProfile
		? true
		: !Object.keys(userInfo || {}).length
			? true
			: (userInfo?.status === 'new' || !userInfo?.user_id)
				? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT)
				: hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT);
	useEffect(() => {
		let channels = {}
		!!tagsList?.length && tagsList.forEach(i => {
			channels = {
				...channels,
				[i.id]: {
					id: i.id,
					is_bound: !!personById?.tags?.length && !!personById?.tags.find(tag => tag.id === i.id)?.id
				}
			}
		})
		setCheckedTags(channels)
	}, [personById, user?.person_id, user?.id, show])

	useEffect(() => {
		dispatch(GetTags());
	}, [])

	function checkTags(e, id) {
		let channels = {
			...checkedTags,
			[id]: {
				id,
				is_bound: e?.target?.checked
			}
		}
		setCheckedTags(channels);
		if(onChangeTags) {
			onChangeTags(Object.values(channels || {}).filter((item) => !!item?.is_bound).map((item) => item?.id))
		}
	}

	function closeCreateTag(e) {
		e.stopPropagation()
		setOpenCreateTag(false)
		setTagName('')
	}

	function getTagValue(e) {
		try{
			const {value} = e.target;
			setTagName(value)
		}catch (e) {
			console.log(e)
		}
	}

	function getEditingTagValue(e) {
		const {value} = e.target
		setEditingTag(value)
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
	}

	async function createTag(e) {
		e?.stopPropagation()
		await dispatch(AddTag({name: tagName}));
		setOpenCreateTag(false)
		setTagName('')
	}

	async function updateTag(e, id) {
		await dispatch(UpdateTag(id, {name: editingTag}));
		setIsEditTag({
		    ...isEditTag,
		    [id]: false
		})
		setEditingTag('')
	}

	async function updatePersonTags() {
		tagName && createTag()

		if(user) {
			await dispatch(UpdatePersonTags(user?.attached_person?.id || user?.person_id || user?.id,
				{
					tags: Object.values(checkedTags)
				},
				tagsList
			))
			if(user?.attached_person?.id || user?.person_id || user?.id) {
				await dispatch(GetPersonById(user?.attached_person?.id || user?.person_id || user?.id))
			}
		} else if(onChangeTags) {
			onChangeTags(Object.values(checkedTags || {}).filter((item) => !!item?.is_bound).map((item) => item?.id))
		}
		handleOpenChange();
	}

	return <div className="profile-tags">
		<div className="profile-tags-title">{t('table_tags')}</div>
		{
			user ?
				<div className="profile-tags">
					{
						!!personById?.tags?.length && personById?.tags?.slice(0, 4)?.map((data, index) => {
							return <div className="profile-tags__item"
							            key={data?.id}
							            style={{
								            borderColor: tagColors[index],
								            color: tagColors[index]
							            }}>
								{data?.name}
							</div>
						})
					}
				</div>
				:
				<div className="profile-tags">
					{
						!!Object.values(checkedTags || {})?.length && Object.values(checkedTags || {}).filter((item) => item.is_bound)?.slice(0, 4)?.map((data, index) => {
							const findItem = tagsList.find((tag) => tag.id === data.id);

							if(findItem) {
								return <div className="profile-tags__item"
								            key={findItem?.id}
								            style={{
									            borderColor: tagColors[index],
									            color: tagColors[index]
								            }}>
									{findItem?.name}
								</div>
							}
						})
					}
				</div>
		}
		{isCheckPermissionEditTags || hasPermission(ROLE_ITEMS.TAGS_EDIT) || hasPermission(ROLE_ITEMS.TAGS_DELETE) ?
		<DropdownMenu
			placement="bottom"
			button={
				<div className="popover_tags__button">
				<PlusGreenIcon/>
				<span>{t('profile_add_tag')}</span>
			</div>
			}
		  className={'actions-menu'}
		  contentWidth={'336px'}
		>
				<>
					<div className="popover_tags--title">
						<span>{t('manage_tags')}</span>
						<CloseIcon onClick={() => setOpen(false)}/>
					</div>

					<div className="popover_tags__list">
							{
								openCreateTag ? <div className="create-tag">
										<input type="text"
										       id={'sadasd'}
										       value={tagName}
										       name={'tagName'}
										       onChange={getTagValue}
										       placeholder={t('profile_placeholder_tag_name')}

										/>
										<div className="buttons">
											<button className={`close-btn`}
											        onClick={closeCreateTag}>
												{t('button_cancel')}
											</button>

											<button
												className={`save-btn`}
												onClick={(e) => {
													createTag(e)
												}}
											>
												{t('button_save')}
											</button>
										</div>
									</div>
									:null
							}
						{!openCreateTag &&
							<button className="create-tag" onClick={() => setOpenCreateTag(true)}>
								<PlusNewIcon/>
								<span>{t('button_add')} ...</span>
							</button>
						}
						<div className={'tags-form'}>
							{!!tagsList?.length && tagsList?.map((data, index) => (
								<div
									key={`default-${data?.id}`}
									className="tag-item"
								>
									{isCheckPermissionEditTags ?
									<Form.Check
										type={'checkbox'}
										className={'check-wrapper'}
										// disabled={!user?.person_id}
										checked={checkedTags?.[data?.id]?.is_bound}
										onChange={(e) => {
											checkTags(e, data.id)
										}}
									/>
										: null}
									{isEditTag[data?.id] ?
										<div className="tag-item-edit">
											<input
												type="text"
												value={editingTag}
												name={'editingTag'}
												className="label editing-tag"
												onChange={getEditingTagValue}
												placeholder={data?.name}
												onKeyDown={(e) => {
													if(e.key === 'Enter') {
														updateTag(e, data?.id)
													}
												}}
												// onBlur={() => {
												// 	setIsEditTag({
												// 		...isEditTag,
												// 		[data?.id]: false
												// 	})
												// 	setEditingTag('')
												// }}
												autoFocus={true}
											/>
											<div className="buttons">
												<button
													className={`close-btn`}
													onClick={() => {
														setIsEditTag({})
														setEditingTag('')
													}}
												>
													{t('button_cancel')}
												</button>
												<div
													className={`save-btn ${data?.name === editingTag ? 'disabled' : ''}`}
													// disabled={data?.name === editingTag}
													onClick={(e) => {
														updateTag(e, data?.id)
													}}
												>
													{t('button_save')}
												</div>
											</div>
										</div>
										:
										<div
											className="label"
											style={{
												borderColor: tagColors[index],
												color: tagColors[index]
											}}
										>
											{data?.name}
										</div>}
									{(hasPermission(ROLE_ITEMS.TAGS_EDIT) || hasPermission(ROLE_ITEMS.TAGS_DELETE)) ?
										<>
											{!isEditTag[data?.id] &&
												<div className="tag-menu" onClick={(e) => e.stopPropagation()}>
													<DropdownMenu button={<EllipsisIcon className={'icon-menu'}/>}
													              closeAfterToggle={true}
													              className={'actions-menu'}
													              contentWidth={'136px'}>
														{hasPermission(ROLE_ITEMS.TAGS_EDIT) ?
															<div className={'menu-item'} onClick={() => {
																setIsEditTag({
																	...isEditTag,
																	[data?.id]: true
																})
																setEditingTag(data?.name)
															}}>
																<EditIcon className={''}/>
																{t('button_edit_short')}
															</div>
															: null}
														{hasPermission(ROLE_ITEMS.TAGS_DELETE) ?
															<div className={'menu-item'}
															     onClick={() => dispatch(DeleteTag(data?.id))}>
																<DeletingIcon className={''}/>
																{t('button_delete')}
															</div> :
															null}
													</DropdownMenu>
												</div>
											}
										</>
										: null}
								</div>
							))}
						</div>

					</div>
					{isCheckPermissionEditTags  ?
					<div className="popover_tags__list">
						<button className={'save-tag-btn'}
							// disabled={!user?.person_id}
							      onClick={updatePersonTags}>
							{t('profile_save_tag')}
						</button>
					</div>
						: null}
				</>
		</DropdownMenu>
			: null}
	</div>
}

export default ProfileTags;
