import {FILTERS_OR_SORT_CONSTS} from "../constants/filtersOrSort";
import {FILTER_SORT_PAGE_TYPES} from "../../modules/Filters/helpers";


export const initialState = {
	[FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS]: {
		filters: {
			selectedFilters: []
		},
		sort: 'creation_time_desc',
	},
	[FILTER_SORT_PAGE_TYPES.PERSONS_ALL]: {
		filters: {
			selectedFilters: []
		},
	},
	[FILTER_SORT_PAGE_TYPES.USER_VIDEOS_PAGE]: {
		filters: {
			selectedFilters: []
		},
		sort: {
			select: 'creation_time_desc',
		},
	},
	[FILTER_SORT_PAGE_TYPES.CHAIN_PAGE]: {
		filters: {
			selectedFilters: []
		},
		sort: {
			select: 'creation_time_desc',
		},
	},
	[FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE]: {
		filters: {
			selectedFilters: []
		},
		sort: {
			select: 'creation_time_desc',
		},
	},
	[FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA]: {
		filters: {
			selectedFilters: []
		},
		sort: {
			select: 'creation_time_desc',
		},
	},
};

export default (state = initialState, action) => {
	switch(action.type) {
		case FILTERS_OR_SORT_CONSTS.UPDATE_SELECTED_FILTERS:
			return {
				...state,
				[action.payload.pageType]: {
					...state[action.payload.pageType],
					filters: {
						...state[action.payload.pageType]?.filters,
						selectedFilters: action.payload.value
					}
				},
			};
		case FILTERS_OR_SORT_CONSTS.SET_FILTER:
			return {
				...state,
				[action.payload.pageType]: {
					...state[action.payload.pageType],
					filters: {
						...state[action.payload.pageType].filters,
						[action.payload.filterType]: action.payload.value
					}
				},
			};
		case FILTERS_OR_SORT_CONSTS.RESET_FILTERS:
			return {
				...state,
				[action.payload.pageType]: {
					...state[action.payload.pageType],
					filters: {
						selectedFilters: [],
					},
					sort: {
						select: 'creation_time_desc',
					},
				},
			};
		case FILTERS_OR_SORT_CONSTS.DELETE_FILTER:
			const sortFilters = state[action.payload.pageType].filters.selectedFilters.filter((item) => item !== action.payload.filterType)
			return {
				...state,
				[action.payload.pageType]: {
					...state[action.payload.pageType],
					filters: {
						...state[action.payload.pageType].filters,
						selectedFilters: sortFilters,
						[action.payload.filterType]: null
					}
				},
			};
		//
		// 	//SORTED
		case FILTERS_OR_SORT_CONSTS.UPDATE_SORT_PARAMS:
			return {
				...state,
				[action.payload.pageType]: {
					...state[action.payload.pageType],
					sort: {
						[action.payload.itemSortType]: action.payload.itemValue
					}
				},
			};
		default:
			return state;
	}
}
