import React, {useState} from 'react';
import {ToastContext} from "./ToastContext";
import {ErrorIcon, SuccessIcon} from "../../image";
import {Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ToastLayout = (props) => {
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const {t} = useTranslation();

    return (
        <ToastContext.Provider value={{setToastInfo}}>
            {props.children}
                <Toast
                    className='upload_video_answer'
                    onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
                    show={toastInfo.isShow}
                    delay={3000}
                    autohide
                >
                    <div className="toasblock">
                        {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                        <div className="toasblock__wrap">
						<span
                            className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                            <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                        </div>
                    </div>
                </Toast>
        </ToastContext.Provider>
    );
};

export default ToastLayout;