// Import packages
import React, {useEffect, useRef, useState} from 'react';

// Import assets
import './dropdown-menu.scss';
import {EllipsisIcon} from "../../../image";

function DropdownMenu(props) {
    const {
        children, button, contentWidth, closeAfterToggle, className, placement, fullWidth, disabled, closeDropdown,
        toggle,visible
    } = props;

    const [showMenu, setShowMenu] = useState(false);
    const [openTop, setOpenTop] = useState(false);

    const ref = useRef();
    const content = useRef();

    useEffect(() => {
        setShowMenu(false)
        setOpenTop(false)
    }, [closeDropdown]);

    useEffect(() => {
        toggle && toggle(showMenu)
    }, [showMenu]);



    useEffect(() => {
        if (showMenu) {
            if (ref.current && content.current && window.innerHeight) {
                const rect = ref.current.getBoundingClientRect();
                const rectContent = content.current.getBoundingClientRect();

                setOpenTop(window.innerHeight < (rect?.top + rect?.height + rectContent.height + 20))
            }
        } else {
            setOpenTop(false)
        }
        toggle && toggle(showMenu);
    }, [showMenu]);


    useEffect(() => {
        document.addEventListener('click', toggleMenu, {capture: true});
        return () => {
            document.removeEventListener('click', toggleMenu)
        }
    }, []);

    const toggleMenu = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowMenu(false)
            props?.onClose && props.onClose(!!ref?.current)
        }
    }
    let placementType = '';
    (placement === 'top' || openTop) && (placementType = 'open-top')
    placement === 'bottom' && (placementType = 'open-bottom')
    return <div className={`menu-wrapper ${className ? className : ''}`}
                ref={content}
                onClick={(e) => e.stopPropagation()}>
        <div className={`menu-button ${showMenu ? 'active' : ''}`} onClick={() => !disabled && setShowMenu(!showMenu)}>
            {button ? button : <EllipsisIcon/>}
        </div>

        {showMenu && <div className={`dropdown-content ${placementType? placementType : ''}`}
                          ref={ref}
                          style={{width: fullWidth ? '100%' : contentWidth}}
                          onClick={(e) => {
                              if (closeAfterToggle) {
                                  setTimeout(() => {
                                      setShowMenu(false)
                                  }, 200)
                              }
                          }}>
            {children}
        </div>}
    </div>;
}

export default DropdownMenu;
