import React from 'react';
import './Loader.scss'

const Loader = (props) => {
	const {size, className, color} = props;

	return (
		<div className={[className, 'cssload-loader'].join(' ')} style={{width: size, height: size, '--loaders-colors': color || '#22C55E'}}>
			<div className="cssload-inner cssload-one"></div>
			<div className="cssload-inner cssload-two"></div>
			<div className="cssload-inner cssload-three"></div>
		</div>
	);
};

export default Loader;