import React, {useState} from 'react';
import {ArrowDownIcon, InfoIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {Accordion, Form} from "react-bootstrap";
import './ProfileLanguage.scss'

const ProfileLanguage = () => {
	const [activeLanguage, setActiveLanguage] = useState('ru');
	const {i18n,t} = useTranslation();
	const languages = [{name: t('english'), key: 'en'}, {name: t('russian'), key: 'ru'}]

	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
		setActiveLanguage(language)
	}

	return (
		<div className="profile-language">
			<Accordion  className={'profile-language__accordion accordion-wrapper'}>
				<Accordion.Item eventKey="1">
					<Accordion.Header
						className={`contact-header `}>
						<ArrowDownIcon className={`arrow-icon`}/>
						{t('profile_language')}
					</Accordion.Header>
					<Accordion.Body>
						<Form className={`language`}>
							{
								languages?.map(item => {
									return <Form.Check
										name="type"
										key={item?.key}
										label={item?.name}
										type={'radio'}
										checked={activeLanguage === item?.key}
										onChange={() => changeLanguage(item?.key)}
									/>
								})
							}

							<div className="language-info">
								<InfoIcon/>
								<span>{t('language_info')}</span>
							</div>
						</Form>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</div>
	);
};

export default ProfileLanguage;