import React, {useEffect, useRef, useState} from 'react';
import './FilterStatusVideo.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {CheckBox} from "../../../CheckBox";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {DeleteFilterAction, SetFilterAction} from "../../../../redux/actions";
import {
	ALL_FILTERS,
	getFilterStatusesItems,
	getValueFilters
} from "../../helpers";


const FilterStatusVideo = ({pageType, onGetVideos}) => {
	const statuses = getFilterStatusesItems();
	const selectedStatusesFilterState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.STATUS] || []);
	const {t, i18n} = useTranslation();
	const [selectedStatuses, setSelectedStatuses] = useState([...selectedStatusesFilterState]);
	const [open, setOpen] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [isAll, setIsAll] = useState(false);
	const [searchText, setSearchText] = useState('');
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, pageType);
	const inputRef = useRef(null);
	const [sortStatuses, setSortStatuses] = useState([...statuses]);

	const dispatch = useDispatch();
	const getLabel = () => {
		let text = '';

		if(selectedStatusesFilterState.length === 1) {
			const findStatus = statuses.find((status) => status.type === selectedStatusesFilterState[0]);
			text = findStatus.label
		} else if(selectedStatusesFilterState.length > 1) {
			text = `+${selectedStatusesFilterState.length}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
	}


	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = async (data) => {
		await dispatch(SetFilterAction({
			pageType,
			filterType: ALL_FILTERS.STATUS,
			value: data
		}))
	}

	const onSelectedFilter = (value) => {
		let arr = [...selectedStatuses];
		if(selectedStatuses.includes(value)) {
			arr = arr.filter((item) => item !== value);
		} else {
			arr.push(value)
		}
		setSelectedStatuses(arr);
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		setValueFilter(selectedStatusesFilterState);
	}

	const handleAllClick = (isCheck) => {
		if(isCheck) {
			setSelectedStatuses(statuses.map((item) => item.type))
		} else {
			setSelectedStatuses([])
		}
	}


	const getVideos = async (type) => {
		let params = {
			...allFiltersValue,
			statuses: selectedStatuses,
		};
		if(!selectedStatuses?.length || type === 'delete') delete params?.statuses;
		await onGetVideos(params);
	}

	const deleteFilter = async () => {
		await dispatch(DeleteFilterAction({
			pageType,
			filterType: ALL_FILTERS.STATUS
		}));
		await getVideos('delete')
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			await setValueFilter(selectedStatuses);
			await getVideos();
		} catch (error) {
			console.log(error)
		}
	}

	const RenderTooltip = () => {
		if(selectedStatusesFilterState.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={<RenderTooltipText/>}
				>
					<span className="filter-status__label--text">{`${t('status')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return <span className="filter-status__label--text">{`${t('status')}: ${getLabel()}`}</span>
	}

	const RenderTooltipText = () => {
		const statusesFiltered = statuses.filter((status) => selectedStatusesFilterState.includes(status.type));

		return (
			<div className="filter-status__tooltip">
				{statusesFiltered.map((status) => (
					<div className="filter-status__tooltip--text">{status?.label}</div>
				))}
			</div>
		)
	}

	const getFilteredStatuses = () => {
		if(searchText) {
			return sortStatuses.filter((status) => (status?.label || '').toLowerCase().includes(searchText.toLowerCase()))
		}
		return sortStatuses
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && selectedStatuses.length) {
			handleApplyClick()
		}
	};

	useEffect(() => {
		setSelectedStatuses(selectedStatusesFilterState)
		setSearchText('');
		if (inputRef.current) {
			inputRef.current.focus();
		}
		setSortStatuses(sortStatuses.sort((a) => selectedStatuses.includes(a.type) ? -1 : 1))

	}, [open]);

	useEffect(() => {
		setIsAll(selectedStatuses.length === statuses.length)
	}, [selectedStatuses]);

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-status'
			onOpenChange={handleOpenChange}
			destroyTooltipOnHide={true}
			open={open}
			content={
				<div className="filter-status__container" onKeyDown={handleKeyDown}>
					{statuses?.length >= 7 ?
						<div className="filter-status__search" tabIndex={0}>
							<div className="filter-status__search--input-wrap">
								<SearchIcon/>
								<input
									value={searchText}
									onChange={(e) => setSearchText(e?.target?.value || '')}
									type="text" placeholder={t('input_search')}
									ref={inputRef}
								/>
							</div>
						</div>
						: null}
					{getFilteredStatuses().length ?
						<div style={{paddingRight: 16}}>
							<div className="filter-status__list  custom-scroll">
								{searchText ? null :
									<div className="filter-status__list--item">
										<CheckBox
											checked={selectedStatuses.length === statuses.length}
											onChange={() => {
												handleAllClick(!isAll);
											}}
											label={i18n.language === 'ru' ? 'Все' : 'All'}
										/>
									</div>
								}
								{getFilteredStatuses().map((status) => (
									<div
										key={status.id}
										className="filter-status__list--item">
										<CheckBox
											checked={selectedStatuses.includes(status.type)}
											onChange={() => {
												onSelectedFilter(status.type)
											}}
											label={status.label}
										/>
									</div>
								))}
							</div>
						</div>
						:
						<>
							{searchText ?
								<div className="filter-status__empty">
									<span>{t('no_found_search_item')}</span>
								</div>
								:
								null
							}
						</>
					}
					<div className="filter-status__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							disabled={!selectedStatuses?.length}
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-status__label ${selectedStatusesFilterState.length ? 'selected' : ''}`}>
				<div className={`filter-status__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedStatusesFilterState.length ?
						<ArrowLeftIcon className={`filter-status__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedStatusesFilterState.length ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-status__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterStatusVideo;