import React from 'react';
import {CloseStrokeIcon, DeleteIcon} from "../../../../image";
import './UserDeleteModal.scss';
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import {Button} from "../../../Button";

const UserDeleteModal = (props) => {
	const {show, onClose, isDeleteAccount, onConfirm, isDeleteUserSettings} = props;
	const {t, i18n} = useTranslation();
	return (
		<Modal
			open={show}
			onCancel={onClose}
			centered
			classNames={{
				mask: 'delete-user-modal__mask',
				content: 'delete-user-modal__content',
				wrapper: 'delete-user-modal__wrapper',
			}}
			className={'delete-user-modal'}
			onWheel={(e) => e.stopPropagation()}
		>
			<div>
				<div className="delete-user-modal__header">
					<span className="delete-user-modal__header--title">
						{isDeleteAccount ?
							<>
								{i18n.language === 'ru' ? 'Удаление профиля' : 'Delete Profile'}
							</>
							:
							<>
								{i18n.language === 'ru' ? 'Удаление информации' : 'Delete Information'}
							</>
						}
					</span>
					<CloseStrokeIcon onClick={onClose}/>
				</div>
				<div className="delete-user-modal__body">
					<span className="delete-user-modal__body--text">
						{isDeleteAccount ?
							<>
							{ t('delete_account_information')}
							</>
							:
							<>
							{isDeleteUserSettings ? t('remove_user_settings_description_modal') : t('remove_video_participation_description_modal')}
							</>
						}
						</span>
				</div>
				<div className="delete-user-modal__footer">
					<Button
						text={t('button_cancel')}
						onClick={onClose}
						variant={'secondary'}
						size={'m'}
					/>
					<Button
						text={isDeleteAccount ? t('profile_delete_button') : t('button_delete')}
						onClick={onConfirm}
						variant={'secondary'}
						leftIcon={<DeleteIcon/>}
						color={"red"}
						size={'m'}
					/>
				</div>
			</div>
			
		</Modal>
	);
};

export default UserDeleteModal;