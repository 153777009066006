export const ROUTERS_MAP = {
    CHANNEL: '/dashboard/videos/channel',
    KNOWLEDGE_DATA: '/dashboard/videos/review',
    MANAGE_DATA: '/manage-data',
    ARTIFACTS: '/manage-data/artifacts',
    CHANNELS: '/manage-data/channels',
    PROFILE: '/dashboard/profile',
    NOTES: '/profile/notes',
    VIDEO: '/dashboard/videos/video',
    INTEGRATIONS: '/integrations',
}