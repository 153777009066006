import React, {useEffect, useState} from 'react';
import './FilterLinks.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {CheckBox} from "../../../CheckBox";
import {Button} from "../../../Button";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {DeleteFilterAction, SetFilterAction} from "../../../../redux/actions";
import {ALL_FILTERS, getFilterLinksItems, getValueFilters} from "../../helpers";


const FilterLinks = ({onGetVideos, pageType}) => {
	const {t, i18n} = useTranslation();
	const links = getFilterLinksItems();
	const selectedLinksFilterState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.LINK] || []);
	const [selectedLinks, setSelectedLinks] = useState([...selectedLinksFilterState]);
	const [open, setOpen] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [isAll, setIsAll] = useState(false);
	const dispatch = useDispatch();
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, pageType);
	const [sortLinks, setSortLinks] = useState([...links]);

	const getLabel = () => {
		let text = '';

		if(selectedLinksFilterState.length === 1) {
			const findLink = links.find((link) => link.type === selectedLinksFilterState[0]);
			text = findLink.label
		} else if(selectedLinksFilterState.length > 1) {
			text = `+${selectedLinksFilterState.length}`
		} else {
			text = 'All'
		}
		return text
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const setValueFilter = (data) => {
		dispatch(SetFilterAction({
			pageType,
			filterType: ALL_FILTERS.LINK,
			value: data
		}))
	}

	const onSelectedFilter = (value) => {
		let arr = [...selectedLinks];
		if(selectedLinks.includes(value)) {
			arr = arr.filter((item) => item !== value);
		} else {
			arr.push(value)
		}
		setSelectedLinks(arr);
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		setValueFilter(selectedLinksFilterState);
	}

	const handleAllClick = (isCheck) => {
		if(isCheck) {
			setSelectedLinks(links.map((item) => item.type))
		} else {
			setSelectedLinks([])
		}
	}

	const getVideos = async (type) => {
		let params = {
			...allFiltersValue,
			share_link_types: selectedLinks,
		};

		if(!selectedLinks?.length || type === 'delete') delete params?.share_link_types;
		await onGetVideos(params)
		try {
		} catch (error) {
			console.log(error)
		}
	}
	const deleteFilter = async () => {
		dispatch(DeleteFilterAction({
			pageType,
			filterType: ALL_FILTERS.LINK
		}));
		await getVideos('delete')
	}

	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			setValueFilter(selectedLinks);
			await getVideos();
		} catch (error) {
			console.log(error)
		}
	}

	const RenderTooltip = () => {
		if(selectedLinksFilterState.length > 1) {
			return (
				<Tooltip
					open={open ? false : openTooltip}
					onOpenChange={handleOpenTooltipChange}
					placement="top"
					title={<RenderTooltipText/>}
				>
					<span className="filter-links__label--text">{`${t('link')}: ${getLabel()}`}</span>
				</Tooltip>
			)
		}
		return <span className="filter-links__label--text">{`${t('link')}: ${getLabel()}`}</span>
	}

	const RenderTooltipText = () => {
		const linksFiltered = links.filter((link) => selectedLinksFilterState.includes(link.type));

		return (
			<div className="filter-link__tooltip">
				{linksFiltered.map((link) => (
					<div className="filter-link__tooltip--text">{link?.label}</div>
				))}
			</div>
		)
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleApplyClick()
		}
	};

	useEffect(() => {
		setSelectedLinks(selectedLinksFilterState);
		setSortLinks(sortLinks.sort((a) => selectedLinks.includes(a.type) ? -1 : 1))
	}, [open]);

	useEffect(() => {
		setIsAll(selectedLinks.length === links.length)
	}, [selectedLinks]);

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-links'
			onOpenChange={handleOpenChange}
			open={open}
			content={
				<div className="filter-links__container" onKeyDown={handleKeyDown}>
					<div className="filter-links__list">
						<div className="filter-links__list--item">
							<CheckBox
								checked={selectedLinks.length === links.length}
								onChange={() => {
									handleAllClick(!isAll);
								}}
								label={i18n.language === 'ru' ? 'Все' : 'All'}
							/>
						</div>
						{sortLinks.map((link) => (
							<div
								key={link.id}
								className="filter-links__list--item">
								<CheckBox
									checked={selectedLinks.includes(link.type)}
									onChange={() => {
										onSelectedFilter(link.type)
									}}
									label={link.label}
								/>
							</div>
						))}
					</div>
					<div className="filter-links__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-links__label ${selectedLinksFilterState.length ? 'selected' : ''}`}>
				<div className={`filter-participant__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedLinksFilterState.length ?
						<ArrowLeftIcon className={`filter-links__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedLinksFilterState.length ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-links__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterLinks;