import React from 'react';
import './FiltersList.scss';
import {useDispatch, useSelector} from "react-redux";
import FilterChannels from "../FilterChannels/FilterChannels";
import {CloseStrokeIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {ApiStatus} from "../../../../utils/constants";
import {useParams} from "react-router-dom";
import {Button} from "../../../../modules/Button";
import {ALL_FILTERS} from "../../../../modules/Filters/helpers";
import FilterTypes from "../FilterLinks/FilterTypes";
import FilterParticipants from "../FilterParticipants/FilterParticipants";
import {
	GetNotesService,
	resetFiltersNotesListService,
	updateApiStatusNotesListService
} from "../../../../redux/actions/notesActions";

const FiltersList = (props) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const filters = useSelector(state => state.notes.notesList.filters);

	const resetFilters = async () => {
		dispatch(resetFiltersNotesListService());
		try {
			await dispatch(updateApiStatusNotesListService(ApiStatus.LOADING));
			await dispatch(GetNotesService({
				limit: 20,
				offset: 0,
			}));
			await dispatch(updateApiStatusNotesListService(ApiStatus.SUCCESS));
		}catch (e) {
			await dispatch(updateApiStatusNotesListService(ApiStatus.ERROR));
		}
	}

	const FILTERS = [
		{
			id: 0,
			component: <FilterParticipants/>
		},
		{
			id: 1,
			component: <FilterChannels/>
		},
		{
			id: 2,
			component: <FilterTypes/>
		}
	]

	return (
		<div className="filters-list">
			<div className="filters-list__items">
				{FILTERS.map((filter) => {
					return <div key={filter?.id}>{filter.component}</div>
				})}
			</div>
			{Object.keys(filters).length > 0 ?
			<Button
				onClick={resetFilters}
				leftIcon={<CloseStrokeIcon/>}
				text={t('reset_all')}
				variant="ghost"
				size="xs"
			/>
				: null}
		</div>
	);
};

export default FiltersList;