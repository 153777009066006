import {FILTER_SORT_PAGE_TYPES} from "../Filters/helpers";


export const SORTED_ITEM_TYPES = {
	DESCRIPTION_VIDEO: 'DESCRIPTION_VIDEO',
	DATE_VIDEO: 'DATE_VIDEO',
	DURATION_VIDEO: 'DURATION_VIDEO'
}

export const SORT_ITEMS_DESCRIPTION_MANAGE_VIDEOS = ['description', 'description_desc'];
export const SORT_ITEMS_DATE_MANAGE_VIDEOS = ['creation_time', 'creation_time_desc'];
export const SORT_ITEMS_DURATION_MANAGE_VIDEOS = ['duration', 'duration_desc'];


export const getSortedItems = (pageType, sortItemType) => {
	if(FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS === pageType) {
		if(SORTED_ITEM_TYPES.DESCRIPTION_VIDEO === sortItemType) {
			return SORT_ITEMS_DESCRIPTION_MANAGE_VIDEOS
		}
		if(SORTED_ITEM_TYPES.DATE_VIDEO === sortItemType) {
			return SORT_ITEMS_DATE_MANAGE_VIDEOS
		}
		if(SORTED_ITEM_TYPES.DURATION_VIDEO === sortItemType) {
			return SORT_ITEMS_DURATION_MANAGE_VIDEOS
		}
	}
}

export const getOptionsSortedSelect = (lng) => {
	return [
		{
			id: 0,
			value: 'creation_time_desc',
			label: lng === 'ru' ? 'Дата (по убыванию)' : 'Date (descending)'
		},
		{
			id: 1,
			value: 'creation_time',
			label: lng === 'ru' ? 'Дата (по возрастанию)' : 'Date (ascending)'
		},
		{
			id: 2,
			value: 'description',
			label: lng === 'ru' ? 'Имя (по возрастанию от А до Я)' : 'Name (ascending A-Z)'
		},
		{
			id: 3,
			value: 'description_desc',
			label: lng === 'ru' ? 'Имя (по убыванию от А до Я)' : 'Name (descending Z-A)'
		},
		{
			id: 4,
			value: 'duration_desc',
			label: lng === 'ru' ? 'Продолжительность (по убыванию)' : 'Duration (ascending)'
		},
		{
			id: 5,
			value: 'duration',
			label: lng === 'ru' ? 'Продолжительность (по возрастанию)' : 'Duration (descending) '
		}
	]
}