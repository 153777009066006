import {Modal} from "antd";
import label from "../../../../image/images/calendar-connect.png";
import {Button} from "../../../../modules/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import './SuccessModal.scss'
import {useDispatch} from "react-redux";
import {SuccessModalContext} from '../../helpers';
import {USER_CALENDAR_CONSTS} from "../../../../redux/constants";
import {GetCalendarSynchronize, GetTelegramLinkService, GetUserCalendars, GoogleAuth} from "../../../../redux/actions";
import {useHistory, useLocation} from "react-router-dom";

const getDescription = (i18n, signature) => {
	if (i18n.language === 'ru') {
		return `Ваше имя для участия в видеоконференции "${signature}" было добавлено в ваш профиль.
                        Мы будем использовать его для фильтрации информации о вашем участии в видеороликах.`
	} else {
		return `Your name in video conference "${signature}" has been added to your profile.
                        We will use it to filter information about your participation in videos.`
	}
}

function SuccessModalProvider(props) {
	const {children} = props;
	const {i18n, t} = useTranslation();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [signature, setSignature] = useState('');
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const isCalendarAddedStorage = localStorage.getItem('close-connect-calendar-modal');
	const isCalendarAdded = searchParams.get('isCalendarAdded');
	const newVCSName = searchParams.get('newVCSName');
	const [telegramLink, setTelegramLink] = useState('');

	async function handleOnSuccess(response) {
		dispatch({
			type: USER_CALENDAR_CONSTS.GET_GOOGLE_AUTH,
			payload: response?.hd
		})
		const res = await dispatch(GoogleAuth({
			code: response?.code,
			scope: response?.scope
		}));
		setSignature(res?.added_flag ? res?.signature : '')
		await dispatch(GetCalendarSynchronize());
		await dispatch(GetUserCalendars());
		setShowSuccessModal(true);
	}

	function closeSuccessModal() {
		if(isCalendarAdded === 'true'){
			localStorage.setItem('close-connect-calendar-modal', 'true')
			setShowSuccessModal(false);
			searchParams.delete('isCalendarAdded');
			searchParams.delete('newVCSName');
			history.push({ search: searchParams.toString() });
		}else{
			setShowSuccessModal(false)
			setSignature('')
		}
	}


	useEffect(() => {
		if (isCalendarAdded === 'true' && !isCalendarAddedStorage) {
			setSignature(newVCSName)
			setShowSuccessModal(true);
		}
		getTelegramLink();
	}, [])

	const getTelegramLink = async () =>{
		try{
			const response = await GetTelegramLinkService();
			setTelegramLink(response?.link)
		}catch (e) {
			setTelegramLink('');
			console.error(e)
		}
	}

	return (
		<SuccessModalContext.Provider value={{handleOnSuccess, getTelegramLink, telegramLink}}>
			{children}
		<Modal
			classNames={{
				wrapper: 'success_modal__wrapper',
			}}
			open={showSuccessModal}
			onCancel={closeSuccessModal}
			centered
		>
			<div className="success_modal__container">

				<img src={label} className="success_modal__container--img"/>
				<div className="success_modal__container--title">
					{t('calendar_connected_title')}
				</div>
				{signature ?
					<div className="success_modal__container--description">
						{getDescription(i18n, signature)}
					</div>
					: null}
				<Button
					variant="primary"
					size="l"
					text={t('calendar_connected_btn')}
					onClick={closeSuccessModal}
				/>
			</div>
		</Modal>
			</SuccessModalContext.Provider>
	);
}

export default SuccessModalProvider;