import React from "react";

export const AutoCainsSettingsContext = React.createContext();

export const getChainsFromChannel = (chains, channel) => {
	return chains.filter(chain => chain.channel?.id === channel?.id)
}

export const isCheckSelectChainsFromChannel = (chains, channel, selectedChains) => {
	const chainsIdToChannel = chains.filter(chain => chain.channel?.id === channel?.id).map(chain => chain.id);
	return (selectedChains || []).some(chainId => chainsIdToChannel.includes(chainId));
}

export const getSelectedChainsFromChannel = (chains, channel, selectedChains) => {
	return chains.filter(chain => chain.channel?.id === channel?.id && selectedChains.includes(chain.id))
}

export function hasSettingsChanged(settings, data) {
	// Проверяем каждое поле в settings и сравниваем с соответствующим полем в data
	return (
		settings.share_link_is_public !== (data?.share_link_is_public || false) ||
		JSON.stringify(settings.channels_ids) !== JSON.stringify(data?.channels_ids || []) ||
		JSON.stringify(settings.chains_ids) !== JSON.stringify(data?.chains_ids || []) ||
		settings.auto_chain_name !== (data?.auto_chain_name || undefined) ||
		settings.need_auto_chain !== (data?.need_auto_chain || false) ||
		settings.auto_chain_channel_id !== (data?.auto_chain_channel_id || undefined) ||
		settings.share_link_expiration_time !== (data?.share_link_expiration_time || undefined) ||
		settings.is_global !== (data?.is_global || null) ||
		settings.is_auto !== !!data?.is_auto
	);
}
