import {VIDEO_STATUSES} from "../../../../modules/Filters/helpers";


export const VIDEO_PANEL_TABS = {
	AI: '0',
	INSIGHTS: '1',
	SUMMERY: '2',
	TEXTS: '3',
	TASKS: '4'
}

export const checkVideoProcessingStatus = (status) => {
	return [VIDEO_STATUSES.BOT_STARTED, VIDEO_STATUSES.UPLOADING, VIDEO_STATUSES.CONVERTING, VIDEO_STATUSES.PROCESSING].includes(status);
}
export const checkVideoSuccessStatus = (status) => {
	return [VIDEO_STATUSES.CONVERSION_FAILED, VIDEO_STATUSES.SENDING_TO_STORAGE_FAILED, VIDEO_STATUSES.PROCESSING_REJECTED, VIDEO_STATUSES.PROCESSING_FAILED, VIDEO_STATUSES.PROCESSED].includes(status);
}