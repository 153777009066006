// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './combine-persons.scss';
import {AddUserSearchIcon, CheckIcon, ErrorIcon, SuccessIcon} from "../../../image";

// Import Components
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {
    CombinePersons,
    GetVideosSearch,
    GetPersonById,
    GetHighlights,
    GetVideos,
    UserAttach,
    GetUserProfile, GetUsersPersons, GetVideoById, GetProtocol
} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {getAuthorName} from "../../../utils/helperFunctions";
import {Form, Toast} from "react-bootstrap";
import debounce from "lodash.debounce";
import {Loader} from "../Loader";
import {useParams} from "react-router-dom";

function CombinePersonsDropdown(props) {
    const {user, personsList, setVideoList} = props;
    const [closeCombineMenu, setCloseCombineMenu] = useState(false);
    const [text, setText] = useState('');
    const [selectedPerson, setSelectedPerson] = useState(undefined);
    const [persons, setPersons] = useState(undefined);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const controllerRef = useRef(null);
    const {t, i18n} = useTranslation();
    const [usersPersonsList, setUsersPersonsList] = useState([])
    const params = useParams();

    const getPersons = async () => {
        try {
            const response = await props.GetUsersPersons({filter: 'all', limit: 1000}, true);
            setUsersPersonsList(response?.results || [])
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setPersons(personsList?.results)
        return () => {
            resetInfo()
        }
    }, [])

    function resetInfo() {
        setText('')
        setSelectedPerson(undefined)
        setPersons(personsList?.results)
    }

    const createSignal = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        controllerRef.current = controller;
        return signal;
    }

    const searchUser = React.useCallback(debounce(async (value) => {
        try {
            setIsLoader(true);
            const response = await props.GetVideosSearch({
                search_query: value,
                exclude_videos: true,
                exclude_transcriptions: true,
                exclude_persons: false
            }, true, createSignal())
            setIsLoader(false);

            const findUsers = response?.persons || [];
            const filteredVideoPersons = persons
              ?.filter(item => item.first_name !== 'unrecognized')
              .filter((item) => `${item.first_name} ${item.last_name}`?.toLowerCase()?.includes(value?.toLowerCase()))

            setPersons(findUsers.length ? findUsers : filteredVideoPersons)

        } catch (error) {
            if(error?.code !== "ERR_CANCELED"){
                setIsLoader(false);
            }
            console.warn(error)
        }
    }, 500), [])

    const handleChangeInput = (e) => {
        const value = e.target?.value || '';
            setText(value);
            if(!Boolean(value)){
                setPersons(persons || []);
            }else{
                searchUser(value);
            }
    }

    function checkPerson(person) {
        setSelectedPerson(person)
    }

    async function combinePerson() {
        const userID =  user?.user_id || (user?.attached_person?.id ? user?.id : user?.attached_person?.id);
        const userPersonId =  user?.person_id || (user?.attached_person?.id ?  user?.attached_person?.id : user?.id);

        try {
            const findUser = usersPersonsList.find((item) => item.person_id === selectedPerson?.id);
            if(findUser) {
                // Два пользователя ошибка
                if(userID && findUser?.user_id) {
                    throw new Error();
                }
                // Пользователь и участника
                if((userID && userPersonId) && (!findUser?.user_id && findUser?.person_id)) {
                    await props.CombinePersons(userPersonId, {
                        "removed_person_id": findUser.person_id
                    });
                    toggleSuccessToast();
                }
                //Участника и участника (удаляем того, кто в модалке)
                if((!userID && userPersonId) && (findUser?.person_id)) {
                    await props.CombinePersons(findUser?.person_id, {
                        "removed_person_id": userPersonId
                    });
                    toggleSuccessToast();
                }
                // Пользователя без участника - с участником
                if((userID && !userPersonId) && (findUser.person_id)) {
                    await props.UserAttach(findUser?.person_id, {
                        user_id: userID
                    });
                    toggleSuccessToast();
                }
                //  участника без пользовталея - с Пользователем
                if((!userID && userPersonId) && (findUser?.user_id && !findUser?.person_id)) {
                    await props.UserAttach(userPersonId, {
                        user_id: findUser?.user_id
                    });
                    toggleSuccessToast();
                }

                 if(userID) await props.GetUserProfile();
                 await props.GetPersonById(selectedPerson?.id);
                 const response = await props.GetVideos({
                                    limit: 1000,
                                    returnList: true,
                                    filters: [{
                                        firstColumnVal: 'and',
                                        secondColumnVal: 'staff',
                                        thirdColumnVal: 'present',
                                        fourthColumnVal: selectedPerson?.id
                                    }]
                                })
                 if(setVideoList) setVideoList(response)

                await props.GetHighlights({person_id: selectedPerson?.id});
                if(params.videoId) {
                    await props.GetVideoById(Number(params.videoId));
                    await props.GetProtocol(Number(params.videoId));
                }
                cancelCombine();
            }else{
                toggleErrorToast()
            }
        }catch (e) {
            toggleErrorToast()
        }
    }

    function cancelCombine() {
        setCloseCombineMenu(!closeCombineMenu)
        setSelectedPerson(undefined)
        setPersons(undefined)
        setText('')
    }

    function handleCloseMenu() {
        resetInfo()
    }

    const toggleSuccessToast = () => setShowSuccessToast(!showSuccessToast);
    const toggleErrorToast = () => setShowErrorToast(!showErrorToast);

    function SuccessToast() {
        return <Toast show={showSuccessToast}
                      onClose={toggleSuccessToast}
                      className={'upload_video_answer'}
                      delay={3000}>
            <SuccessIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_success_title')}</strong>
                <p className={'description'}>{t('toast_success_description_combine')}</p>
            </div>
        </Toast>
    }

    function ErrorToast() {
        return <Toast show={showErrorToast}
                      onClose={toggleErrorToast}
                      className={'upload_video_answer'}
                      autohide={true}
                      delay={3000}>
            <ErrorIcon/>
            <div className="toast-content">
                <strong className="title">{t('toast_error_title')}</strong>
                <p className={'description'}>{t('toast_error_description_combine')}</p>
            </div>
        </Toast>
    }

    return <>
        <DropdownMenu
            // disabled={(!user?.person_id && !user?.id)}
            button={<button onClick={getPersons} className="person-btn">
                <AddUserSearchIcon/>
                {t('button_combine')}
            </button>}
            onClose={handleCloseMenu}
            className={'persons-dropdown-wrapper'}
            closeAfterToggle={false}
            closeDropdown={closeCombineMenu}
            contentWidth={'400px'}
            placement={'top'}
            fullWidth={false}>
            <div className="persons-header">
                <input type="text"
                       value={text}
                       name={'text'}
                       onChange={handleChangeInput}
                       placeholder={t('input_search')}
                />
                {isLoader && <Loader size={22} className="loader-input"/>}
            </div>

            <div className="persons-list">
                {
                    !!persons?.length && persons?.map(item => {
                        return <div className="person-item"
                                    onClick={() => checkPerson(item)}
                                    key={item?.id}>
                            <Form.Check
                              className="person-item--radio"
                              checked={item?.id === selectedPerson?.id}
                              type={'radio'}
                            />
                            <img
                                src={generateMediaUrl(item?.avatar)}
                                key={item.id}
                                alt=""
                            />
                            <div className="person_info">
                                <div className="name">
                                    {getAuthorName(item, null, i18n.language)}
                                </div>
                                <div className="email">
                                    {item.email || 'user@gmail.com'}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="buttons-wrapper">
                <button className={'cancel-btn'}
                        onClick={cancelCombine}>{t('button_cancel')}
                </button>
                <button className={'concat-btn'}
                        onClick={combinePerson}>{t('combine')}
                </button>
            </div>
        </DropdownMenu>
        {ErrorToast()}
        {SuccessToast()}
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personsList'
    ])
};

const mapDispatchToProps = {
    CombinePersons,
    GetVideosSearch,
    GetHighlights,
    GetPersonById,
    GetVideos,
    UserAttach,
    GetUserProfile,
    GetUsersPersons,
    GetVideoById,
    GetProtocol
};

export default connect(mapStateToProps, mapDispatchToProps)(CombinePersonsDropdown);
