// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './persons.scss';

//Import components
import ListItem from "./listItem/ListItem";
import UserInfoModal from "../modals/userInfoModal/UserInfoModal";
import AddUserModal from "../modals/addUserModal/AddUserModal";
import CustomPagination from "../../../../../shared/features/Pagination";

//Import utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    ClearPersons,
    GetPersons,
    ChangeHeaderTitle,
    GetUsersPersons,
    GetUsers,
    ClearUsers, DeleteUserService, DeletePersonService
} from "../../../../../redux/actions";
import {useLocation, useParams} from "react-router-dom";
import {ErrorIcon, PersonNewIcon, PlusNewIcon, SuccessIcon} from "../../../../../image";
import {useTranslation} from "react-i18next";
import {hasPermission, scrollTop} from "../../../../../utils/helperFunctions";
import {Toast} from "react-bootstrap";
import FiltersAll from "../../../../../modules/Filters/components/FiltersAll/FiltersAll";
import {FILTER_SORT_PAGE_TYPES} from "../../../../../modules/Filters/helpers";
import {Button} from "../../../../../modules/Button";
import {history} from "../../../../../utils/history";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {ROLE_ITEMS} from "../../../../../utils/constants";

const listLength = 20

function Persons(props) {
    const [showPersonsModal, setShowPersonsModal] = useState(false)
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [activePage, setActivePage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const dispatch = useDispatch();
    const usersPersonsList = useSelector(state => state.users.usersPersonsList);
    const userProfile = useSelector(state => state.profile.userProfile);
    const sortPersons =  (usersPersonsList?.results || []).sort((item) => item?.user_id === userProfile?.id ? -1 : 1);
    const params = useParams()
    const location = useLocation()
    const {t} = useTranslation();

    const filterList = [
        {name: t('users_title'), id: 'users', key: 'GetUsers'},
        {name: t('participant_title'), id: 'persons', key: 'GetPersons'},
        {name: t('all_users_title'), id: 'all', key: 'GetUsersPersons'},
    ]

    useEffect(() => {
        props.ChangeHeaderTitle(t('persons_title'))
        props.GetUsersPersons({
            role_id: location?.state?.role_id,
            limit: 20
        })
        setSelectedFilter('users')

        if (params?.personId || params?.userId) {
            let user = {}
            params?.personId && (user.person_id = params?.personId)
            params?.userId && (user.user_id = params?.userId)

            setCurrentUser(user)
            setShowPersonsModal(true)
        }

        return () => {
            props.ClearPersons()
            props.ClearUsers()
        }
    }, [])

    function openModal() {
        setShowPersonsModal(true)
    }
    function updateModal(id) {
        const findUser = (props.personsList?.results || []).find((item) => item.id === id);

        if(userProfile?.attached_person?.id === findUser.id){
            history.push(ROUTERS_MAP.PROFILE);
        }else{
            setShowPersonsModal(false);
            setCurrentUser(findUser)
            setShowPersonsModal(true)
        }

    }

    function closeModal() {
        setShowPersonsModal(false)
        setCurrentUser(null)
        props.GetUsersPersons({
            filter: selectedFilter,
            person_status: selectedFilter === 'persons' ? 'known' : undefined,
            limit: listLength,
            offset: listLength * (activePage - 1),
            role_id: location?.state?.role_id
        })
    }

    function openAddUserModal() {
        setShowAddUserModal(true)
    }

    function closeAddUserModal() {
        setShowAddUserModal(false)
    }

    const changePage = (number) => {
        scrollTop();
        setActivePage(number)
        props.GetUsersPersons({
            filter: selectedFilter,
            person_status: selectedFilter === 'persons' ? 'known' : undefined,
            limit: listLength,
            offset: listLength * (number - 1),
            role_id: location?.state?.role_id
        })
    };

    const toggleFilter = (id) => {
        setSelectedFilter(id)
        props.GetUsersPersons({
            filter: id,
            person_status: id === 'persons' ? 'known' : undefined,
            role_id: location?.state?.role_id,
        })
    };

    async function deletePerson(data) {
        try{
            if(selectedFilter === 'users'){
                await DeleteUserService(data?.user_id);
            }else if(selectedFilter === 'persons') {
                await DeletePersonService(data?.person_id);
            }else{
                if(data?.user_id) await DeleteUserService(data?.user_id);
                if(data?.person_id) await DeletePersonService(data?.person_id);
            }

            setToastInfo({
                isShow: true,
                type: 'success',
                text: selectedFilter === 'persons' ? t("delete_participant_success_text") : t("delete_person_success_text")
            });
            await dispatch(GetUsersPersons({
                filter: selectedFilter,
                limit: 20,
                offset: usersPersonsList?.offset,
                person_status: selectedFilter === 'persons' ? 'known' : undefined,
            }));
        }catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text:  t("delete_person_error_text")
            });
        }
    }

    return (
        <div className="persons">
            <div className="persons__wrap">
            <div className="persons__header">
                <div className="persons__header__left">
                    <div className="persons__title">{t('persons_title')}</div>

                    <div className="persons__filters">
                        {
                          filterList?.length && filterList?.map(item => {
                              return <div key={item.id}
                                          onClick={() => toggleFilter(item.id)}
                                          className={`filter_item ${selectedFilter === item.id ? 'active' : ''}`}>
                                  {item?.name}</div>
                          })
                        }
                    </div>
                </div>
                <div className="persons__header__right">
                    <FiltersAll
                      pageType={FILTER_SORT_PAGE_TYPES.PERSONS_ALL}
                    />
                    {hasPermission(ROLE_ITEMS.FOREIGN_USERS_CREATE) &&
                    <Button
                      leftIcon={<PlusNewIcon/>}
                      rightIcon={<PersonNewIcon/>}
                      text={t('button_add')}
                      variant="primary"
                      size="xs"
                      onClick={openAddUserModal}
                    />}

                </div>
            </div>

            <div className="persons__list-header">
                <div className="persons__list-header__content">
                    <div className="user_info item_col">{t('table_name')}</div>
                    <div className="item_col">{t('table_tags')}</div>
                    <div className="item_col">{t('table_role')}</div>
                    <div className="item_col">{t('table_company')}</div>
                    <div className="item_col">{t('table_position')}</div>
                </div>
                <div className="persons__list-header__actions">
                    <div className="video_count item_col">{t('table_video')}</div>
                </div>
            </div>
            {
               sortPersons.map((item, index) => {
                    return <ListItem key={`${item.person_id}_${item.user_id}`}
                                     data={item}
                                     index={index}
                                     openModal={openModal}
                                     rolesList={props.rolesList}
                                     badge={filterList?.find(i => i.id === selectedFilter)?.name}
                                     setCurrentUser={setCurrentUser}
                                     onDeleteUser={deletePerson}
                                     selectedFilter={selectedFilter}
                    />
                })
            }
            {props.usersPersonsList?.count > 10 && <CustomPagination count={props.usersPersonsList?.count}
                                                                     activePage={activePage}
                                                                     listLength={listLength}
                                                                     changePage={changePage}/>}
            {showPersonsModal && <UserInfoModal
              show={showPersonsModal}
              closeModal={closeModal}
              user={currentUser}
              updateModal={updateModal}
            />
            }
            {showAddUserModal && <AddUserModal show={showAddUserModal}
                                               closeModal={closeAddUserModal}/>
            }
                <Toast
                    className='upload_video_answer'
                    onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
                    show={toastInfo.isShow}
                    delay={3000}
                    autohide
                >
                    <div className="toasblock">
                        {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                        <div className="toasblock__wrap">
						                <span
                            className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                            <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                        </div>
                    </div>
                </Toast>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'personsList',
        'usersList',
        'usersPersonsList',
        'rolesList',
        'userRoles',
    ])
};

const mapDispatchToProps = {
    GetPersons, ClearUsers, GetUsers, GetUsersPersons, ClearPersons, ChangeHeaderTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(Persons);
